import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from 'store/authenticationActions'; // Adjust import paths
import { Grid, TextField, Button, Typography, Paper, InputAdornment, IconButton, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import backgroundImage from 'assets/images/login_rectangle.png';
import backgroundImageLogo from 'assets/images/login_background.png';
import logo from 'assets/images/sarpi-logo.jpeg';
import Avatar from 'ui-component/extended/Avatar';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { requestPasswordReset, resetPassword, verifyResetCode } from 'store/resetPasswordActions';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
            textAlign: 'center'
        }
    },
    right: {
        backgroundColor: '#0000FF', // Blue background color
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none' // Hide on mobile
        }
    },
    navbar: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            backgroundColor: '#0000FF',
            height: '60px',
            width: '100%',
            position: 'absolute'
        }
    },
    imageWrapper: {
        position: 'relative',
        right: '100px',
        width: 'calc(100% - 10px)',
        height: '90%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImageLogo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    image: {
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 1,
        borderRadius: '12px'
    },
    form: {
        width: '100%',
        maxWidth: 450,
        padding: theme.spacing(2)
    },
    input: {
        marginBottom: theme.spacing(2),
        '& .MuiInputLabel-root': {
            color: theme.palette.text.primary
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ccc'
            },
            '&:hover fieldset': {
                borderColor: '#1F41BB'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1F41BB'
            }
        }
    },
    inputFocused: {
        borderBottom: '1px solid #1F41BB',
        borderBottomRightRadius: '13px',
        borderBottomLeftRadius: '13px'
    },
    button: {
        marginTop: theme.spacing(2),
        backgroundColor: '#1F41BB',
        height: '65px'
    },
    error: {
        color: 'red'
    },
    welcome: {
        marginTop: theme.spacing(2)
    },
    label: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    codeInput: {
        width: 'calc(20% - 10px)', // Adjust width to fit 5 inputs
        marginRight: '10px',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    codeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    }
}));

const PasswordReset = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error } = useSelector((state) => state.passwordResetReducer);

    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState(Array(5).fill(''));
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1); // Step 1: Email Input, Step 2: Verification Code Input, Step 3: New Password

    const validateEmail = () => {
        const newErrors = {};
        if (!email) newErrors.email = "L'e-mail est requis";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateCode = () => {
        const newErrors = {};
        if (verificationCode.some((code) => !code)) newErrors.code = 'Le code de vérification est requis';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validatePassword = () => {
        const newErrors = {};
        if (!newPassword) newErrors.password = 'Le nouveau mot de passe est requis';
        // Optionally: Add more password validations (e.g., min length, match confirmation)
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if (!validateEmail()) return;
        dispatch(requestPasswordReset(email))
            .then(() => setStep(2)) // Move to the next step after successful email submission
            .catch((err) => console.log('Error:', err));
    };

    const handleCodeChange = (index, value) => {
        const updatedCode = [...verificationCode];
        updatedCode[index] = value.slice(0, 1); // Ensure only one character is entered
        setVerificationCode(updatedCode);

        // Move to the next input field
        if (value && index < 4) {
            document.getElementById(`code-input-${index + 1}`).focus();
        }
    };

    const handleVerificationSubmit = (e) => {
        e.preventDefault();
        if (!validateCode()) return;
        // Combine verification code parts
        const code = verificationCode.join('');
        dispatch(verifyResetCode(email, code))
            .then(() => setStep(3)) // Move to the next step after successful code verification
            .catch((err) => console.log('Error:', err));
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (!validatePassword()) return;
        dispatch(resetPassword(email, verificationCode.join(''), newPassword))
            .then(() => navigate('/login')) // Redirect to login after successful password reset
            .catch((err) => console.log('Error:', err));
    };

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const theme = useTheme();

    return (
        <Grid container className={classes.container}>
            <Grid item className={classes.navbar} sx={{ px: 2 }}>
                <Avatar src={logo} alt="Berry" size="sm" outline />
                <Typography variant="h4" color="white" mx={1}>
                    SARPI
                </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.left}>
                <Paper elevation={3} className={classes.form}>
                    <Grid item xs={12} my={2}>
                        <Typography variant="h2" my={1}>
                            {step === 1 && 'Mot de passe Oublie'}
                            {step === 2 && 'Mot de passe Oublie'}
                            {step === 3 && 'Nouveau mot de passe'}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ opacity: 0.7 }}>
                            {step === 1 && 'Nous vous enverrons par e-mail un lien pour réinitialiser votre mot de passe.'}
                            {step === 2 && "Nous venons d'envoyer un code à 5 chiffres à sarpi@gmail.com, saisissez-le ci-dessous :"}
                            {step === 3 &&
                                'Définissez le nouveau mot de passe de votre compte afin que vous puissiez vous connecter et accéder à toutes les fonctionnalités.'}
                        </Typography>
                    </Grid>
                    {step === 1 && (
                        <form onSubmit={handleEmailSubmit}>
                            <Typography variant="body1" className={classes.label}>
                                E-mail
                            </Typography>
                            <TextField
                                label=""
                                placeholder="admin@sarpi.dz"
                                variant="outlined"
                                fullWidth
                                className={classes.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                fullWidth
                                disabled={loading}
                            >
                                {loading ? 'Envoi en cours...' : 'Suivant'}
                            </Button>
                        </form>
                    )}
                    {step === 2 && (
                        <form onSubmit={handleVerificationSubmit}>
                            <div className={classes.codeContainer}>
                                {verificationCode.map((value, index) => (
                                    <TextField
                                        key={index}
                                        id={`code-input-${index}`}
                                        className={classes.codeInput}
                                        value={value}
                                        onChange={(e) => handleCodeChange(index, e.target.value)}
                                        variant="outlined"
                                        placeholder=""
                                        inputProps={{ maxLength: 1 }}
                                        error={Boolean(errors.code)}
                                        helperText={errors.code}
                                    />
                                ))}
                            </div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                fullWidth
                                disabled={loading}
                            >
                                {loading ? 'Validation en cours...' : 'Vérifier'}
                            </Button>
                        </form>
                    )}
                    {step === 3 && (
                        <form onSubmit={handlePasswordSubmit}>
                            <Typography variant="body1" className={classes.label}>
                                Nouveau mot de passe
                            </Typography>
                            <TextField
                                label=""
                                placeholder="Entrez votre nouveau mot de passe"
                                type="password"
                                variant="outlined"
                                fullWidth
                                className={classes.input}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" edge="end">
                                                <VisibilityOff /> {/* Change as needed */}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(errors.password)}
                                helperText={errors.password}
                            />
                            <Typography variant="body1" className={classes.label}>
                                Confirmer nouveau mot de passe
                            </Typography>
                            <TextField
                                label=""
                                placeholder="Confirmez votre nouveau mot de passe"
                                type="password"
                                variant="outlined"
                                fullWidth
                                className={classes.input}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" edge="end">
                                                <VisibilityOff /> {/* Change as needed */}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(errors.password)}
                                helperText={errors.password}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                fullWidth
                                disabled={loading}
                            >
                                {loading ? 'Réinitialisation en cours...' : 'Mettre à jour le mot de passe'}
                            </Button>
                        </form>
                    )}
                    {/* {error && <Typography className={classes.error}>{error}</Typography>} */}
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} className={classes.right}>
                <div className={classes.imageWrapper}>
                    <img src={backgroundImage} alt="Login" className={classes.image} />
                </div>
            </Grid>
        </Grid>
    );
};

export default PasswordReset;
