import fetchApi from './api';

export const FETCH_SITES_STATS_REQUEST = 'FETCH_SITES_STATS_REQUEST';
export const FETCH_SITES_STATS_SUCCESS = 'FETCH_SITES_STATS_SUCCESS';
export const FETCH_SITES_STATS_FAILURE = 'FETCH_SITES_STATS_FAILURE';

export const FETCH_REGIONS_STATS_REQUEST = 'FETCH_REGIONS_STATS_REQUEST';
export const FETCH_REGIONS_STATS_SUCCESS = 'FETCH_REGIONS_STATS_SUCCESS';
export const FETCH_REGIONS_STATS_FAILURE = 'FETCH_REGIONS_STATS_FAILURE';

export const FETCH_HEADQUARTER_STATS_REQUEST = 'FETCH_HEADQUARTER_STATS_REQUEST';
export const FETCH_HEADQUARTER_STATS_SUCCESS = 'FETCH_HEADQUARTER_STATS_SUCCESS';
export const FETCH_HEADQUARTER_STATS_FAILURE = 'FETCH_HEADQUARTER_STATS_FAILURE';

export const FETCH_ANALYSES_STATS_REQUEST = 'FETCH_ANALYSES_STATS_REQUEST';
export const FETCH_ANALYSES_STATS_SUCCESS = 'FETCH_ANALYSES_STATS_SUCCESS';
export const FETCH_ANALYSES_STATS_FAILURE = 'FETCH_ANALYSES_STATS_FAILURE';

export const FETCH_PARTICIPATIONS_STATS_REQUEST = 'FETCH_PARTICIPATIONS_STATS_REQUEST';
export const FETCH_PARTICIPATIONS_STATS_SUCCESS = 'FETCH_PARTICIPATIONS_STATS_SUCCESS';
export const FETCH_PARTICIPATIONS_STATS_FAILURE = 'FETCH_PARTICIPATIONS_STATS_FAILURE';

export const FETCH_SITE_STAFF_STATS_REQUEST = 'FETCH_SITE_STAFF_STATS_REQUEST';
export const FETCH_SITE_STAFF_STATS_SUCCESS = 'FETCH_SITE_STAFF_STATS_SUCCESS';
export const FETCH_SITE_STAFF_STATS_FAILURE = 'FETCH_SITE_STAFF_STATS_FAILURE';

export const fetchSitesStats = () => async (dispatch) => {
    dispatch({ type: FETCH_SITES_STATS_REQUEST });
    try {
        const response = await fetchApi('/stats/sites');
        dispatch({
            type: FETCH_SITES_STATS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_SITES_STATS_FAILURE,
            payload: error.message
        });
    }
};

export const fetchRegionsStats = () => async (dispatch) => {
    dispatch({ type: FETCH_REGIONS_STATS_REQUEST });
    try {
        const response = await fetchApi('/stats/regions');
        dispatch({
            type: FETCH_REGIONS_STATS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_REGIONS_STATS_FAILURE,
            payload: error.message
        });
    }
};

export const fetchHeadquarterStats = () => async (dispatch) => {
    dispatch({ type: FETCH_HEADQUARTER_STATS_REQUEST });
    try {
        const response = await fetchApi('/stats/headquarter');
        dispatch({
            type: FETCH_HEADQUARTER_STATS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_HEADQUARTER_STATS_FAILURE,
            payload: error.message
        });
    }
};

export const fetchAnalysesStats = () => async (dispatch) => {
    dispatch({ type: FETCH_ANALYSES_STATS_REQUEST });
    try {
        const response = await fetchApi('/stats/analyses');
        dispatch({
            type: FETCH_ANALYSES_STATS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_ANALYSES_STATS_FAILURE,
            payload: error.message
        });
    }
};

export const fetchParticipationsStats = () => async (dispatch) => {
    dispatch({ type: FETCH_PARTICIPATIONS_STATS_REQUEST });
    try {
        const response = await fetchApi('/stats/participations');
        dispatch({
            type: FETCH_PARTICIPATIONS_STATS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_PARTICIPATIONS_STATS_FAILURE,
            payload: error.message
        });
    }
};

export const fetchSiteStaffStats = () => async (dispatch) => {
    dispatch({ type: FETCH_SITE_STAFF_STATS_REQUEST });
    try {
        const response = await fetchApi('/stats/site-staff');
        dispatch({
            type: FETCH_SITE_STAFF_STATS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_SITE_STAFF_STATS_FAILURE,
            payload: error.message
        });
    }
};
