import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
    Box,
    Grid,
    Typography,
    Avatar,
    TextField,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    Button,
    CardContent,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    InputAdornment,
    IconButton
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { createAdmin } from 'store/adminActions'; // Adjust import according to your project structure
import { fetchSites } from 'store/sitesActions';
import { fetchRegions } from 'store/regionsActions';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const AdminCreate = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: 'region-manager',
        site: '',
        region: '',
        avatar: {
            base64: ''
        },
        password: '',
        confirmPassword: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const { sites } = useSelector((state) => state.sites);
    const regionsList = useSelector((state) => state.regions);
    const { regionList } = regionsList;

    const [filteredSites, setFilteredSites] = useState([]);

    useEffect(() => {
        dispatch(fetchRegions());
    }, [dispatch]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRadioChange = (e) => {
        setFormData({ ...formData, role: e.target.value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, avatar: { base64: reader.result.split(',')[1] } });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSiteChange = (event) => {
        const { value } = event.target;
        setFormData({ ...formData, site: typeof value === 'string' ? value.split(',') : value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'Nom est requis';
        if (!formData.lastName) newErrors.lastName = 'Prénom est requis';
        if (!formData.email) newErrors.email = 'Email est requis';
        if (!formData.phone) newErrors.phone = 'N° Téléphone est requis';
        if (!formData.avatar.base64) newErrors.avatar = "Image requise pour l'avatar";
        if (formData.site.length === 0) newErrors.site = 'Site est requis';
        if (!formData.region) newErrors.region = 'Région est requise';
        if (!formData.password) newErrors.password = 'Mot de passe est requis';
        if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Les mots de passe ne correspondent pas';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [searchQuery, setSearchQuery] = useState('');

    const handleRegionChange = (event) => {
        const { value } = event.target;
        setFormData({ ...formData, region: value, site: '' });

        dispatch(
            fetchSites({
                page: 1,
                perPage: 10,
                search: searchQuery,
                region: formData.region || ''
            })
        );
        setFilteredSites(sites.list);
        console.log(filteredSites);
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        try {
            await dispatch(createAdmin(formData));
            // Handle success message here
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainCard
            title="Create Admin"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1,
                padding: 2
            }}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={4} md={3}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <FormControl>
                            <Avatar
                                src={
                                    formData.avatar.base64
                                        ? `data:image/png;base64,${formData.avatar.base64}`
                                        : 'https://via.placeholder.com/150'
                                }
                                alt={formData.firstName || 'Avatar'}
                                sx={{ width: 130, height: 130, mb: 2, cursor: 'pointer' }}
                                onClick={() => document.getElementById('avatar-upload').click()}
                            />
                        </FormControl>
                        <input type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} id="avatar-upload" />
                        <Typography variant="h4" sx={{ fontWeight: '600', mt: 2 }}>
                            {formData.firstName} {formData.lastName}
                        </Typography>
                        <Typography variant="body1">
                            {formData.role === 'site-manager' ? 'Responsable de site' : 'Responsable Régional'}
                        </Typography>
                    </CardContent>
                </Grid>

                <Grid item xs={12} sm={8} md={9}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Name Fields */}
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Nom"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Prénom"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Contact Fields */}
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="N° Téléphone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Region and Site Selects */}
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth error={!!errors.region}>
                                    <InputLabel>Région</InputLabel>
                                    <Select name="region" value={formData.region} onChange={handleRegionChange}>
                                        {regionList?.regions?.map((region) => (
                                            <MenuItem key={region.id} value={region.id}>
                                                {region.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="body2" color="error">
                                        {errors.region}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth error={!!errors.site} disabled={!formData.region}>
                                    <InputLabel>Sites</InputLabel>
                                    <Select
                                        name="site"
                                        value={formData.site}
                                        onChange={handleSiteChange}
                                        renderValue={(selected) =>
                                            selected ? filteredSites.find((site) => site.id === selected)?.name : ''
                                        }
                                    >
                                        <MenuItem disabled value="">
                                            <em>{!formData.region ? 'Please select a region first' : 'Select a site'}</em>
                                        </MenuItem>
                                        <MenuItem>
                                            <TextField
                                                variant="standard"
                                                placeholder="Search site..."
                                                fullWidth
                                                onChange={handleSearchChange}
                                                value={searchQuery}
                                            />
                                        </MenuItem>
                                        {filteredSites?.map((site) => (
                                            <MenuItem key={site.id} value={site.id}>
                                                <ListItemText primary={site.name} secondary={site.designation} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="body2" color="error">
                                        {errors.site}
                                    </Typography>
                                </FormControl>
                            </Grid>

                            {/* Role Radio */}
                            <Grid item xs={12} md={12}>
                                <FormControl>
                                    <FormLabel>Rôle</FormLabel>
                                    <RadioGroup row name="role" value={formData.role} onChange={handleRadioChange}>
                                        <FormControlLabel value="site-manager" control={<Radio />} label="Responsable de site" />
                                        <FormControlLabel value="region-manager" control={<Radio />} label="Responsable Régional" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {/* Password Fields */}
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Mot de passe"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        error={!!errors.password}
                                        helperText={errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} onMouseDown={(e) => e.preventDefault()}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Confirmer Mot de passe"
                                        name="confirmPassword"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formData.confirmPassword}
                                        onChange={handleInputChange}
                                        error={!!errors.confirmPassword}
                                        helperText={errors.confirmPassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} onMouseDown={(e) => e.preventDefault()}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginTop: theme.spacing(2), backgroundColor: '#1F41BB', height: '50px' }}
                                    fullWidth
                                    disabled={loading}
                                    startIcon={loading ? <CircularProgress size={20} /> : null}
                                >
                                    {loading ? 'En Cours...' : 'Créer Admin'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AdminCreate;
