import {
    CardContent,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { fetchHeadquarterStats } from 'store/statsActions';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DynamicLineChart from 'ui-component/charts/DynamicLikeChart';
import TotalHazardsCard from '../DynamicCard';
import DynamicPieChart from 'ui-component/charts/DynamicPieChart';
import StatistiquesGlobalesSocietySkeleton from 'ui-component/cards/Skeleton/SocietyRapportsSkeleton';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

const StatistiquesGlobalesSociety = () => {
    const dispatch = useDispatch();
    const { headquarter: headquarterData } = useSelector((state) => state.AnalyticsStats);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const chartsContainerRef = useRef(null);

    useEffect(() => {
        dispatch(fetchHeadquarterStats());
    }, [dispatch]);

    const transformBarChartData = (data) => {
        const categories = data?.series.map((project) => project?.name) || [];
        const statusNames = data?.categories || [];
        const series = statusNames?.map((status, index) => ({
            name: status,
            data: data?.series.map((project) => project?.data[index])
        }));
        return { categories, series, title: data?.title };
    };

    const headQuarterLineChart = useMemo(() => headquarterData?.data?.lineChart || { categories: [], series: [] }, [headquarterData]);
    const headQuarterBarChart = useMemo(() => transformBarChartData(headquarterData?.data?.barChart?.dailyChart), [headquarterData]);
    const statusHeadQuarterPieChart = useMemo(
        () => headquarterData?.data?.statusPieChart || { categories: [], series: [] },
        [headquarterData]
    );
    const performanceHeadQuarterPieChart = useMemo(
        () => headquarterData?.data?.performancePieChart || { categories: [], series: [] },
        [headquarterData]
    );

    if (headquarterData.loading) {
        return <StatistiquesGlobalesSocietySkeleton />;
    }

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }} sm={12} md={12} lg={12}>
                    {headquarterData?.data?.stats?.map((stat) => (
                        <Grid
                            lg={12}
                            sm={12}
                            md={12}
                            container
                            spacing={gridSpacing}
                            sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}
                        >
                            <Grid item sm={12} md={6} lg={3} xs={12}>
                                <TotalHazardsCard isLoading={false} count={stat.total} name="Total hazards signalés" type="light" />
                            </Grid>
                            <Grid item sm={12} md={6} lg={3} xs={12}>
                                <TotalHazardsCard isLoading={false} count={stat.open} name="Hazards ouverts" type="blue" />
                            </Grid>
                            <Grid item sm={12} md={6} lg={3} xs={12}>
                                <TotalHazardsCard isLoading={false} count={stat.resolved} name="Hazards résolus" type="light" />
                            </Grid>
                            <Grid item sm={12} md={6} lg={3} xs={12}>
                                <TotalHazardsCard isLoading={false} count={stat.validated} name="Hazards validée" type="light" />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <SubCard>
                        <DynamicPieChart data={statusHeadQuarterPieChart} labels={['Ouvert', 'En cours', 'Fermé']} />
                    </SubCard>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <SubCard>
                        <DynamicPieChart data={performanceHeadQuarterPieChart} labels={['Rapide', 'Normal', 'Lent']} />
                    </SubCard>
                </Grid>

                <Grid item xs={12}>
                    <SubCard>
                        <DynamicLineChart data={headQuarterLineChart} xAxisTitle="Par période" yAxisTitle="Hazards" type="line" />
                    </SubCard>
                </Grid>
                <Grid item xs={12}>
                    <SubCard>
                        <DynamicLineChart data={headQuarterBarChart} xAxisTitle="Par période" yAxisTitle="Hazards" type="line" />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default StatistiquesGlobalesSociety;
