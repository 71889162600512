import {
    FETCH_HAZARDS_REQUEST,
    FETCH_HAZARDS_SUCCESS,
    FETCH_HAZARDS_FAILURE,
    FETCH_HAZARD_REQUEST,
    FETCH_HAZARD_SUCCESS,
    FETCH_HAZARD_FAILURE,
    PATCH_HAZARD_STATUS_REQUEST,
    PATCH_HAZARD_STATUS_SUCCESS,
    PATCH_HAZARD_STATUS_FAILURE,
    POST_COMMENT_REQUEST,
    POST_COMMENT_SUCCESS,
    POST_COMMENT_FAILURE,
    POST_MESSAGE_REQUEST,
    POST_MESSAGE_SUCCESS,
    POST_MESSAGE_FAILURE,
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    UPDATE_HAZARD_REQUEST,
    UPDATE_HAZARD_SUCCESS,
    UPDATE_HAZARD_FAILURE,
    RESTORE_CATEGORY_FAILURE,
    ARCHIVE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_FAILURE,
    FETCH_CATEGORY_FAILURE,
    CREATE_CATEGORY_FAILURE,
    FETCH_CATEGORY_SUCCESS,
    RESTORE_CATEGORY_SUCCESS,
    ARCHIVE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_SUCCESS,
    RESTORE_CATEGORY_REQUEST,
    ARCHIVE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_REQUEST,
    FETCH_CATEGORY_REQUEST,
    CREATE_CATEGORY_REQUEST,
    EXPORT_HAZARDS_FAILURE,
    EXPORT_HAZARDS_SUCCESS,
    EXPORT_HAZARDS_REQUEST
} from './hazardsActions';

const initialState = {
    loading: false,
    hazards: {
        list: [], // To store the list of hazards
        pagination: {
            currentPage: 1,
            totalPages: 1,
            totalItems: 0,
            perPage: 15
        }, // To store pagination data
        filters: {
            search: '', // Initial search term
            category: '', // Initial category
            status: '', // Initial status
            severity: '', // Initial severity
            site: '', // Initial site
            type: '', // Initial type
            anonymous: false, // Initial anonymous filter
            newHazard: false, // Initial new hazard filter
            minDate: '', // Initial minimum date
            maxDate: '' // Initial maximum date
        },
        hasMore: true
    },
    export: {
        loading: false,
        error: null
    },
    error: null,
    currentHazard: null,
    categories: [],
    category: null,
    updateHazardLoading: false,
    statusUpdateLoading: false,
    commentPostLoading: false,
    messagePostLoading: false,
    categoriesLoading: false
};

const hazardReducer = (state = initialState, action) => {
    switch (action.type) {
        // Start fetching hazards
        case FETCH_HAZARDS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        // Successfully fetched hazards
        case FETCH_HAZARDS_SUCCESS: {
            const newPagination = action.payload?.pagination;
            const newHazards = action.payload?.list;

            return {
                ...state,
                loading: false,
                hazards: {
                    ...state.hazards,
                    list:
                        newPagination.currentPage === 1
                            ? newHazards // If first page, replace the list
                            : [...state.hazards.list, ...newHazards], // Otherwise, append the new hazards to the list
                    pagination: newPagination // Update pagination details
                },
                hasMore: newPagination.currentPage < newPagination.lastPage // Check if more data is available
            };
        }

        // Failed to fetch hazards
        case FETCH_HAZARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        // Hazard detail actions
        case FETCH_HAZARD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_HAZARD_SUCCESS:
            return {
                ...state,
                loading: false,
                currentHazard: action.payload,
                error: null
            };
        case FETCH_HAZARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        // Updating hazard
        case UPDATE_HAZARD_REQUEST:
            return {
                ...state,
                updateHazardLoading: true,
                error: null
            };
        case UPDATE_HAZARD_SUCCESS:
            return {
                ...state,
                updateHazardLoading: false,
                currentHazard: action.payload,
                error: null
            };
        case UPDATE_HAZARD_FAILURE:
            return {
                ...state,
                updateHazardLoading: false,
                error: action.payload
            };

        // Status patching
        case PATCH_HAZARD_STATUS_REQUEST:
            return {
                ...state,
                statusUpdateLoading: true,
                error: null
            };
        case PATCH_HAZARD_STATUS_SUCCESS:
            return {
                ...state,
                statusUpdateLoading: false,
                currentHazard: {
                    ...state.currentHazard,
                    status: action.payload.status
                },
                error: null
            };
        case PATCH_HAZARD_STATUS_FAILURE:
            return {
                ...state,
                statusUpdateLoading: false,
                error: action.payload
            };

        // Posting comment
        case POST_COMMENT_REQUEST:
            return {
                ...state,
                commentPostLoading: true,
                error: null
            };
        case POST_COMMENT_SUCCESS:
            return {
                ...state,
                commentPostLoading: false,
                error: null
            };
        case POST_COMMENT_FAILURE:
            return {
                ...state,
                commentPostLoading: false,
                error: action.payload
            };

        // Posting message
        case POST_MESSAGE_REQUEST:
            return {
                ...state,
                messagePostLoading: true,
                error: null
            };
        case POST_MESSAGE_SUCCESS:
            return {
                ...state,
                messagePostLoading: false,
                currentHazard: {
                    ...state.currentHazard,
                    conversation: [
                        ...(state.currentHazard.conversation || []),
                        action.payload // Add the new message to the conversation
                    ]
                },
                error: null
            };
        case POST_MESSAGE_FAILURE:
            return {
                ...state,
                messagePostLoading: false,
                error: action.payload
            };

        // Categories actions
        case FETCH_CATEGORIES_REQUEST:
            return {
                ...state,
                categoriesLoading: true,
                error: null
            };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categoriesLoading: false,
                categories: action.payload,
                error: null
            };
        case FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                categoriesLoading: false,
                error: action.payload
            };

        case CREATE_CATEGORY_REQUEST:
        case FETCH_CATEGORY_REQUEST:
        case UPDATE_CATEGORY_REQUEST:
        case ARCHIVE_CATEGORY_REQUEST:
        case RESTORE_CATEGORY_REQUEST:
            return {
                ...state,
                categoriesLoading: true,
                error: null
            };

        case CREATE_CATEGORY_SUCCESS:
        case UPDATE_CATEGORY_SUCCESS:
        case ARCHIVE_CATEGORY_SUCCESS:
        case RESTORE_CATEGORY_SUCCESS:
        case FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                categoriesLoading: false,
                category: action.payload,
                error: null
            };

        case CREATE_CATEGORY_FAILURE:
        case FETCH_CATEGORY_FAILURE:
        case UPDATE_CATEGORY_FAILURE:
        case ARCHIVE_CATEGORY_FAILURE:
        case RESTORE_CATEGORY_FAILURE:
            return {
                ...state,
                categoriesLoading: false,
                error: action.payload
            };
        case EXPORT_HAZARDS_REQUEST:
            return {
                ...state,
                export: {
                    ...state.export,
                    loading: true,
                    error: null
                }
            };
        case EXPORT_HAZARDS_SUCCESS:
            return {
                ...state,
                export: {
                    ...state.export,
                    loading: false,
                    error: null,
                    data: action.payload
                }
            };
        case EXPORT_HAZARDS_FAILURE:
            return {
                ...state,
                export: {
                    ...state.export,
                    loading: false,
                    error: action.payload
                }
            };
        default:
            return state;
    }
};

export default hazardReducer;
