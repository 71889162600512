import {
    CardContent,
    Grid,
    LinearProgress,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

// eslint-disable-next-line arrow-body-style
const RegionsRepportsSkeleton = () => {
    return (
        <MainCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubCard>
                        <CardContent>
                            <Typography variant="h3">
                                <Skeleton width={300} />
                            </Typography>
                            <StyledTableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[...Array(5)].map((_, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </StyledTableContainer>
                        </CardContent>
                    </SubCard>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default RegionsRepportsSkeleton;
