import { CardContent, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

const StatistiquesGlobalesSocietySkeleton = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <MainCard>
            <Grid container spacing={2}>
                {/* Stats Cards Skeleton */}
                <Grid container spacing={2} sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                    {[...Array(4)].map((_, index) => (
                        <Grid item sm={12} md={6} lg={3} xs={12} key={index}>
                            <Skeleton variant="rectangular" height={140} />
                        </Grid>
                    ))}
                </Grid>

                {/* Pie Charts Skeleton */}
                <Grid item lg={6} md={6} xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>

                {/* Line Charts Skeleton */}
                <Grid item xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>
                <Grid item xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default StatistiquesGlobalesSocietySkeleton;
