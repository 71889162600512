import { Grid, Skeleton } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';

// eslint-disable-next-line arrow-body-style
const StatistiquesParSiteEtEffectifSkeleton = () => {
    return (
        <MainCard>
            {/* Chart Skeleton */}
            <Grid item lg={12} md={12} xs={12}>
                <SubCard>
                    <Skeleton variant="rectangular" height={300} />
                </SubCard>
            </Grid>
        </MainCard>
    );
};

export default StatistiquesParSiteEtEffectifSkeleton;
