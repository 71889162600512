import React, { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Typography, Card, CardContent } from '@mui/material';
import { useLocation } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as PhoneIcon } from 'assets/images/icons/phone.svg';
import { ReactComponent as LocalizationIcon } from 'assets/images/icons/localization.svg';
import logo from 'assets/images/sarpi-logo.jpeg';
import { Box } from '@mui/system';

const HazardMap = () => {
    const location = useLocation();
    const hazardData = location.state?.hazardData;
    const { site, geolocation } = hazardData;
    const [map, setMap] = useState(null);

    useEffect(() => {
        // Initialize map
        const mapInstance = L.map('map', { zoomControl: false }).setView([geolocation?.latitude, geolocation?.longitude], 15);

        // Add tile layer
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
            maxZoom: 19
        }).addTo(mapInstance);

        // Add marker
        const svgIcon = L.divIcon({
            className: 'custom-svg-icon',
            html: `<svg width="134" height="68" viewBox="0 0 134 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.3599C0 1.50428 1.50427 0 3.35989 0H130.64C132.496 0 134 1.50428 134 3.3599V54.6401C134 56.4957 132.496 58 130.64 58H77.2363L67.1979 68L57.1595 58H3.3599C1.50428 58 0 56.4957 0 54.6401V3.3599Z" fill="#4880FF"/>
                    </svg>`
        });

        L.marker([geolocation?.latitude, geolocation?.longitude], { icon: svgIcon })
            .addTo(mapInstance)
            .bindPopup(`${site?.name}<br/>${site?.address}`)
            .openPopup();

        // Save map instance to state
        setMap(mapInstance);

        // Cleanup on component unmount
        return () => {
            mapInstance.remove();
        };
    }, [geolocation?.latitude, geolocation?.longitude, site?.name, site?.address]);

    return (
        <MainCard
            title="Hazard Map"
            buttonLink="/some-path"
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {/* Map container */}
            <div style={{ height: '90vh', display: 'flex', borderRadius: '8px' }}>
                <div id="map" style={{ width: '100%', flexGrow: 1, borderRadius: '8px' }} />
            </div>

            {/* Card displaying site information */}
            <Card
                style={{
                    position: 'absolute',
                    top: '100px',
                    left: '40px',
                    zIndex: 1000,
                    maxWidth: '300px',
                    //     height: '150px',
                    background: 'white',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    alignItems: 'start',
                    padding: 2
                }}
            >
                <img
                    src={logo} // Your logo source
                    alt="Site Logo"
                    style={{ width: '50px', height: '50px' }}
                />
                <CardContent style={{ flex: 1, width: '100%', padding: 2, alignItems: 'start', justifyContent: 'center' }}>
                    <Typography variant="h4">{site.name}</Typography>
                    <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                        <LocalizationIcon />
                        <Typography variant="body2" sx={{ paddingInline: 1 }}>
                            {site.address}
                        </Typography>
                    </Box>
                    <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                        <PhoneIcon />
                        <Typography variant="body2" sx={{ paddingInline: 1 }}>
                            {site.phone}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </MainCard>
    );
};

export default HazardMap;
