import React from 'react';
import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Paper,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Skeleton
} from '@mui/material';

export default function HazardsTableSkeleton() {
    return (
        <Table>
            <TableBody>
                {Array.from(new Array(3)).map((_, index) => (
                    <TableRow key={index}>
                        {Array.from(new Array(9)).map((_, colIndex) => (
                            <TableCell key={colIndex}>
                                <Skeleton variant="text" width="100%" />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
