/**
 * Color intention that you want to use in your theme
 * @param {JsonObject} theme Theme customization object
 */
export default function themePalette(theme) {
    // Default to 'light' mode if theme.mode is undefined
    const isDarkMode = theme?.customization?.themeMode === 'dark';
    console.log('theme : ', theme);

    return {
        mode: isDarkMode ? 'dark' : 'light',
        common: {
            black: isDarkMode ? theme.colors?.darkPaper : theme.colors?.grey900
        },
        primary: {
            light: isDarkMode ? theme.colors?.darkPrimaryLight : theme.colors?.primaryLight,
            main: isDarkMode ? theme.colors?.darkPrimaryMain : theme.colors?.primaryMain,
            dark: isDarkMode ? theme.colors?.darkPrimaryDark : theme.colors?.primaryDark,
            200: isDarkMode ? theme.colors?.darkPrimary200 : theme.colors?.primary200,
            800: isDarkMode ? theme.colors?.darkPrimary800 : theme.colors?.primary800
        },
        secondary: {
            light: isDarkMode ? theme.colors?.darkSecondaryLight : theme.colors?.secondaryLight,
            main: isDarkMode ? theme.colors?.darkSecondaryMain : theme.colors?.secondaryMain,
            dark: isDarkMode ? theme.colors?.darkSecondaryDark : theme.colors?.secondaryDark,
            200: isDarkMode ? theme.colors?.darkSecondary200 : theme.colors?.secondary200,
            800: isDarkMode ? theme.colors?.darkSecondary800 : theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            300: isDarkMode ? theme.colors?.grey700 : theme.colors?.grey300,
            500: isDarkMode ? theme.colors?.grey500 : theme.colors?.grey500,
            600: isDarkMode ? theme.colors?.grey400 : theme.colors?.grey600,
            700: isDarkMode ? theme.colors?.grey300 : theme.colors?.grey700,
            900: isDarkMode ? theme.colors?.grey100 : theme.colors?.grey900
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: isDarkMode ? theme.colors?.darkTextPrimary : theme.colors?.grey700,
            secondary: isDarkMode ? theme.colors?.darkTextSecondary : theme.colors?.grey500,
            dark: isDarkMode ? theme.colors?.darkTextPrimary : theme.colors?.grey900,
            hint: theme.colors?.grey100
        },
        background: {
            paper: isDarkMode ? theme.colors?.darkPaper : theme.colors?.paper,
            default: isDarkMode ? theme.colors?.darkBackground : theme.colors?.paper
        }
    };
}
