/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Paper,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Skeleton,
    Box,
    Typography,
    Button,
    IconButton,
    Menu,
    Checkbox,
    ListItemText,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import EmployeeListSkeleton from 'ui-component/cards/Skeleton/EmployeeList';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees } from 'store/employeeActions';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { fetchProvinces } from 'store/provincesActions';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import { makeStyles } from '@mui/styles';
import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';
import ImportXlsxModal from 'ui-component/extended/ImportXSLX';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fetchSites } from 'store/sitesActions';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';

const EmployeeList = () => {
    const [filter, setFilter] = useState({
        search: '',
        site: [],
        gender: [],
        bloodType: [],
        status: []
    });

    const [page, setPage] = useState(1);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { employees, hasMore, loading, error } = useSelector((state) => state.employee.employeeList);
    const { sites } = useSelector((state) => state.sites);
    // const { provinces, loading: loadingProvinces } = useSelector((state) => state.province);
    const [anchorEls, setAnchorEls] = useState({});
    const [openArchiveModal, setOpenArchiveModal] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

    useEffect(() => {
        console.clear();
        const fetchInitialData = async () => {
            const formattedFilter = {
                search: filter.search || '',
                site: filter.site.length ? filter.site.join(',') : '', // Convert site array to string
                gender: filter.gender.length ? filter.gender.join(',') : '', // Convert gender array to string
                bloodType: filter.bloodType.length ? filter.bloodType.join(',') : '',
                status: filter.status.length ? filter.status.join(',') : '' // Convert status array to string
            };

            try {
                dispatch(
                    fetchEmployees(
                        page,
                        15,
                        'desc',
                        'id',
                        formattedFilter.search,
                        formattedFilter.site,
                        formattedFilter.gender,
                        formattedFilter.status,
                        formattedFilter.bloodType
                    )
                );
                dispatch(fetchSites());
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchInitialData();
    }, [filter, dispatch]);

    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
        const formattedFilter = {
            search: filter.search || '',
            site: filter.site.length ? filter.site.join(',') : '', // Convert site array to string
            gender: filter.gender.length ? filter.gender.join(',') : '', // Convert gender array to string
            bloodType: filter.bloodType.length ? filter.bloodType.join(',') : '',
            status: filter.status.length ? filter.status.join(',') : '' // Convert status array to string
        };
        dispatch(
            fetchEmployees(
                page,
                15,
                'desc',
                'id',
                formattedFilter.search,
                formattedFilter.site,
                formattedFilter.gender,
                formattedFilter.status,
                formattedFilter.bloodType
            )
        );
    };

    // Filter change handler
    const handleFilterChange = (e) => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            [e.target.name]: e.target.value
        }));
        setPage(1); // Reset page when filters are changed
    };

    const clearFilters = () => {
        setPage(1); // Reset page when filters are cleared
        setFilter({
            search: '',
            site: [],
            gender: [],
            status: [],
            bloodType: []
        });
    };

    const useStyles = makeStyles((theme) => ({
        textField: {
            maxWidth: '250px',
            borderBottom: 'none',
            '& .MuiInputBase-input': {
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: theme.palette.grey[700],
                opacity: 1
            },
            '& .MuiOutlinedInput-input': {
                borderBottom: 'none'
            }
        },
        submitButton: {
            marginTop: theme.spacing(2),
            backgroundColor: '#1F41BB',
            height: '50px'
        }
    }));

    const classes = useStyles();

    const headers = [
        { id: 'registration', label: 'Immatriculation' },
        { id: 'name', label: 'Nom' },
        { id: 'email', label: 'Email' },
        { id: 'phone', label: 'Telephone' },
        { id: 'post', label: 'Poste' },
        { id: 'department', label: 'Département' },
        { id: 'nss', label: 'NSS' },
        { id: 'bloodType', label: 'Type de Sang' },
        { id: 'action', label: 'Action' }
    ];

    const renderTableCell = (content, key) => (
        <TableCell
            key={key}
            style={{
                whiteSpace: 'nowrap',
                fontWeight: '500',
                color: '#202224',
                lineHeight: '11px'
            }}
        >
            {content}
        </TableCell>
    );

    const handleRowClick = (id) => {
        navigate(`/gestion-des-employes/employes/${id}`, { state: { edit: false }, id });
    };

    // Menu actions for specific rows
    const handleOpenAction = (event, id) => {
        setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
    };

    const handleCloseAction = (id) => {
        setAnchorEls((prev) => ({ ...prev, [id]: null }));
    };

    const handleEditEmployee = async (id, event) => {
        await event?.stopPropagation();
        navigate(`/gestion-des-employes/employes/${id}`, { state: { edit: true }, id });
    };

    const handleArchiveEmployee = (id, event) => {
        event.stopPropagation();
        setSelectedEmployeeId(id);
        setOpenArchiveModal(true);
    };

    const handleCloseModal = () => {
        setOpenArchiveModal(false);
    };

    const handleConfirmArchive = async () => {
        try {
            console.log('Archiving employee with ID:', selectedEmployeeId);
            // await archiveEmployee(selectedEmployeeId);
            dispatch(fetchEmployees());
        } catch (error) {
            console.error('Error archiving employee:', error);
        } finally {
            setOpenArchiveModal(false);
        }
    };

    const renderMenuItems = (rowId) => {
        return [
            <>
                <MenuItem onClick={() => handleEditEmployee(rowId)}>Modifier l&apos;employé</MenuItem>
                <MenuItem onClick={(event) => handleArchiveEmployee(rowId, event)}>Archiver</MenuItem>
            </>
        ];
    };

    return (
        <MainCard
            title="Liste des Employés"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mb: 3, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', mx: 0, px: 1 }}
                    >
                        <Grid item lg={2.5} md={2.5} xs={2.5}>
                            <TextField
                                name="search"
                                variant="outlined"
                                fullWidth
                                placeholder="Rechercher ..."
                                onChange={handleFilterChange}
                                value={filter.search}
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item lg={1.6} md={1.6} xs={4}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Site</InputLabel>
                                <Select
                                    multiple
                                    name="site"
                                    variant="outlined"
                                    value={filter.site}
                                    onChange={handleFilterChange}
                                    className={classes.select}
                                    renderValue={(selected) =>
                                        selected
                                            .map((siteId) => {
                                                const site = sites?.list?.find((site) => site.id === siteId);
                                                return site ? `${site.name}` : '';
                                            })
                                            .join(', ')
                                    }
                                >
                                    {sites?.list?.map((site) => (
                                        <MenuItem key={site.id} value={site.id}>
                                            <Checkbox checked={filter.site.indexOf(site.id) > -1} />
                                            <ListItemText primary={`${site?.name}`} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Genre</InputLabel>
                                <Select
                                    multiple
                                    name="gender"
                                    variant="outlined"
                                    value={filter.gender}
                                    onChange={handleFilterChange}
                                    renderValue={(selected) => selected.map((val) => (val === 'man' ? 'Homme' : 'Femme')).join(', ')}
                                >
                                    <MenuItem value="man">
                                        <Checkbox checked={filter.gender.indexOf('man') > -1} />
                                        <ListItemText primary="Homme" />
                                    </MenuItem>
                                    <MenuItem value="woman">
                                        <Checkbox checked={filter.gender.indexOf('woman') > -1} />
                                        <ListItemText primary="Femme" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Type de Sang</InputLabel>
                                <Select
                                    multiple
                                    name="bloodType"
                                    variant="outlined"
                                    value={filter.bloodType}
                                    onChange={handleFilterChange}
                                    renderValue={(selected) => selected.join(', ')} // Display the selected blood types
                                >
                                    {['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'].map((type) => (
                                        <MenuItem key={type} value={type}>
                                            <Checkbox checked={filter.bloodType.indexOf(type) > -1} />
                                            <ListItemText primary={type} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Status</InputLabel>
                                <Select
                                    multiple
                                    name="status"
                                    variant="outlined"
                                    value={filter.status}
                                    onChange={handleFilterChange}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) return 'Tous';
                                        const uniqueValues = [...new Set(selected)];

                                        const isActiveSelected = uniqueValues.includes('active');
                                        const isInactiveSelected = uniqueValues.includes('inactive');
                                        const isTousSelected = uniqueValues.includes('');

                                        if (isActiveSelected && !isInactiveSelected && !isTousSelected) {
                                            return 'Actif';
                                        }
                                        if (isInactiveSelected && !isActiveSelected && !isTousSelected) {
                                            return 'Inactif';
                                        }
                                        return 'Tous';
                                    }}
                                >
                                    <MenuItem value="">
                                        <Checkbox checked={filter.status.indexOf('') > -1} />
                                        <ListItemText primary="Tous" />
                                    </MenuItem>
                                    <MenuItem value="active">
                                        <Checkbox checked={filter.status.indexOf('active') > -1} />
                                        <ListItemText primary="Actif" />
                                    </MenuItem>
                                    <MenuItem value="inactive">
                                        <Checkbox checked={filter.status.indexOf('inactive') > -1} />
                                        <ListItemText primary="Inactif" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={1.5} md={1.5} xs={2.5}>
                            <TrashIcon onClick={clearFilters} style={{ cursor: 'pointer' }} />
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            mb: 2,
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '150px' }}>
                            <Typography variant="subtitle2">Résultat</Typography>
                            <Typography variant="subtitle1">{employees.pagination.total} Employee</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/gestion-des-employes/employes/create')}
                                sx={{
                                    borderRadius: '20px',
                                    mx: 1,
                                    padding: '6px 16px',
                                    backgroundColor: '#2962FF'
                                }}
                            >
                                <FileIcon style={{ marginRight: '5px' }} />
                                Crée un employé
                            </Button>
                            <ImportXlsxModal />
                        </Box>
                    </Grid>
                    <InfiniteScroll
                        dataLength={employees?.list?.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<EmployeeListSkeleton />}
                        scrollableTarget="scrollableTable"
                        endMessage={
                            <Typography variant="body2" align="center" color="textSecondary">
                                Vous avez vu toutes les notifications.
                            </Typography>
                        }
                    >
                        <Box>
                            <TableContainer component={Paper} sx={{ top: 0, left: 0, right: 0, bottom: 50 }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(({ id, label }) => (
                                                <TableCell
                                                    key={id}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        fontWeight: '900',
                                                        color: '#202224',
                                                        lineHeight: '11px'
                                                    }}
                                                >
                                                    {label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody sx={{ top: 0, left: 0, right: 0, bottom: 50, height: '500px' }}>
                                        {employees.list.length > 0 ? (
                                            employees.list.map((row) => (
                                                <TableRow
                                                    key={row?.id}
                                                    hover
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleRowClick(row?.id)}
                                                >
                                                    {renderTableCell(row?.immatriculation, 'immatriculation')}
                                                    {renderTableCell(`${row?.firstName} ${row?.lastName}`, 'name')}
                                                    {renderTableCell(row?.email, 'email')}
                                                    {renderTableCell(row?.phone, 'phone')}
                                                    {renderTableCell(row?.job, 'post')}
                                                    {renderTableCell(row?.department, 'department')}
                                                    {renderTableCell(row?.socialSecurityNumber, 'nss')}
                                                    {renderTableCell(row?.bloodType, 'bloodType')}

                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <IconButton
                                                            sx={{ padding: '4px', textAlign: 'center', borderRadius: '50%' }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleOpenAction(event, row.id);
                                                            }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>

                                                        <Menu
                                                            anchorEl={anchorEls[row.id]}
                                                            open={Boolean(anchorEls[row.id])}
                                                            onClose={() => handleCloseAction(row.id)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left'
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left'
                                                            }}
                                                        >
                                                            {renderMenuItems(row.id).map((menuItem, index) => (
                                                                <React.Fragment key={index}>{menuItem}</React.Fragment>
                                                            ))}
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={8} align="center">
                                                    <Typography variant="body2">Aucun employé trouvé.</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {error && (
                                <Box sx={{ color: 'red', textAlign: 'center', padding: '16px' }}>
                                    Une erreur s&apos;est produite : {error}
                                </Box>
                            )}
                        </Box>
                    </InfiniteScroll>
                </Grid>
            </Grid>
            <Dialog open={openArchiveModal} onClose={handleCloseModal}>
                <DialogTitle>Confirmer l&apos;archivage</DialogTitle>
                <DialogContent>
                    <DialogContentText>Êtes-vous sûr de vouloir archiver cet employé ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmArchive} color="secondary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default EmployeeList;
