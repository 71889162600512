import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authenticationReducer';
import hazardReducer from './hazardsReducer';
import userReducer from './currentUserReducer';
import adminReducer from './adminReducer';
import passwordResetReducer from './resetPasswordReducer';
import regionReducer from './regionsReducer';
import employeeReducer from './employeeReducer';
import statsReducer from './dashboardStatsReducer';
import sitesReducer from './sitesReducer';
import provinceReducer from './provincesReducer';
import actionsReducer from './acpReducer';
import statsAnalyticsReducer from './statsReducer';
import notificationReducer from './notificationsReducer';
import auditsReducer from './auditsReducer';
import rolesPermissionsReducer from './rolesReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    authentication: authReducer,
    stats: statsReducer,
    AnalyticsStats: statsAnalyticsReducer,
    province: provinceReducer,
    admin: adminReducer,
    roles: rolesPermissionsReducer,
    sites: sitesReducer,
    currentUser: userReducer,
    employee: employeeReducer,
    passwordResetReducer,
    hazards: hazardReducer,
    acp: actionsReducer,
    regions: regionReducer,
    notification: notificationReducer,
    auditsReducer,
    customization: customizationReducer
});

export default reducer;
