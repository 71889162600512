/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
    Drawer,
    Button,
    Box,
    FormControl,
    TextField,
    Typography,
    Divider,
    CircularProgress,
    Grid,
    Checkbox,
    FormControlLabel,
    Snackbar,
    Alert, // New imports for Snackbar and Alert
    IconButton
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import SubCard from 'ui-component/cards/SubCard';
import { createRole, fetchRolesPermissions, updateRole } from 'store/roleActions';

const AddRole = ({ isOpen, onClose, roleData = null, isEdit = false }) => {
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.roles);
    const permissions = roles.permissions; // Permissions grouped by category

    const [formData, setFormData] = useState({
        roleName: roleData?.name || '',
        permissions: roleData?.permissions || [] // Initialize with roleData.permissions if editing
    });

    const [errors, setErrors] = useState({});
    const [successOpen, setSuccessOpen] = useState(false); // State for success modal
    const [errorOpen, setErrorOpen] = useState(false); // State for error modal

    useEffect(() => {
        if (roleData) {
            setFormData({
                roleName: roleData?.name || '',
                permissions: roleData?.permissions?.map((perm) => perm?.id) || [] // Ensure we have an array of permission IDs
            });
        }
    }, [roleData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePermissionChange = (permissionId) => {
        setFormData((prevData) => {
            const isSelected = prevData.permissions.includes(permissionId);
            const newPermissions = isSelected
                ? prevData.permissions.filter((id) => id !== permissionId) // Uncheck
                : [...prevData.permissions, permissionId]; // Check

            return { ...prevData, permissions: newPermissions };
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.roleName) newErrors.roleName = 'Nom du rôle est requis';
        if (!formData.permissions) newErrors.permissions = 'Veuillez sélectionner des permissions valides';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const mappedPermissions = formData?.permissions;

            if (isEdit && formData.roleName && mappedPermissions) {
                // Dispatch update role action
                await dispatch(
                    updateRole(roleData.id, {
                        name: formData?.roleName,
                        permissions: mappedPermissions
                    })
                ).then(console.log('new'));
            } else if (isEdit && formData.roleName && mappedPermissions) {
                // Dispatch create role action
                await dispatch(
                    createRole({
                        name: formData?.roleName,
                        permissions: mappedPermissions
                    })
                );
            }
            setSuccessOpen(true); // Open success modal on successful submission
            await dispatch(fetchRolesPermissions());
            // onClose();
        } catch (error) {
            setErrorOpen(true); // Open error modal if submission fails
            setErrors(error.response?.errors || {});
        }
    };

    const useStyles = makeStyles((theme) => ({
        drawerContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: theme.spacing(2)
        },
        form: {
            flex: '1 1 auto',
            overflowY: 'auto',
            marginBottom: theme.spacing(2)
        },
        submitButton: {
            backgroundColor: '#1F41BB',
            height: '50px',
            width: '100%',
            maxWidth: '300px'
        },
        permissionsGrid: {
            marginTop: theme.spacing(2),
            maxHeight: '55vh',
            overflow: 'auto'
        },
        categoryTitle: {
            fontWeight: 'bold',
            marginTop: theme.spacing(2)
        }
    }));

    const classes = useStyles();

    // Helper function to categorize permissions and mark checked permissions
    const categorizePermissions = (permissions, selectedPermissions) => {
        const categorizedPermissions = {};
        Object.keys(permissions).forEach((category) => {
            categorizedPermissions[category] = permissions[category].map((permission) => ({
                ...permission,
                checked: selectedPermissions?.includes(permission?.id) // Compare by permission ID
            }));
        });
        return categorizedPermissions;
    };

    // Map permissions and mark them as checked if they're in formData.permissions
    const categorizedPermissions = categorizePermissions(permissions, formData?.permissions);

    // Helper function to render permissions
    const renderPermissions = (category) => (
        <Grid container spacing={1} className={classes.permissionsGrid}>
            {categorizedPermissions[category]?.map((permission) => (
                <Grid item key={permission.id} xs={12} sm={6} md={4}>
                    <FormControlLabel
                        control={<Checkbox checked={permission.checked} onChange={() => handlePermissionChange(permission.id)} />}
                        label={permission.label}
                    />
                </Grid>
            ))}
        </Grid>
    );

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Box sx={{ width: { xs: '100%', sm: '400px', md: 600, lg: 800 }, padding: 2 }} className={classes.drawerContainer}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" component="h2">
                        {isEdit ? 'Modifier le rôle' : 'Création des rôles'}
                    </Typography>
                    <IconButton onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box component="form" onSubmit={handleSubmit} className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <FormControl>
                                <TextField
                                    label="Nom du rôle"
                                    name="roleName"
                                    value={formData?.roleName}
                                    onChange={handleInputChange}
                                    error={!!errors?.roleName}
                                    helperText={errors?.roleName}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h3" gutterBottom>
                                Permissions Associées
                            </Typography>

                            {Object.keys(categorizedPermissions || {}).map((category) => (
                                <SubCard key={category} sx={{ m: 1 }}>
                                    <Box sx={{ p: 1 }}>
                                        <Typography variant="h5" className={classes.categoryTitle}>
                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </Typography>

                                        {renderPermissions(category)}
                                    </Box>
                                </SubCard>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
                <FormControl>
                    <Grid item xs={12} lg={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="button" variant="outlined" onClick={onClose} color="primary" sx={{ mx: 1 }} disabled={roles.loading}>
                            Annuler
                        </Button>
                        <Button
                            className={classes.submitButton}
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit}
                            color="primary"
                            disabled={roles.loading}
                        >
                            {roles.loading ? <CircularProgress size={24} /> : isEdit ? 'Modifier le rôle' : 'Créer le rôle'}
                        </Button>
                    </Grid>
                </FormControl>
            </Box>

            {/* Success Snackbar */}
            <Snackbar
                open={successOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                autoHideDuration={4000}
                onClose={() => setSuccessOpen(false)}
            >
                <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {isEdit ? 'Rôle modifié avec succès!' : 'Rôle créé avec succès!'}
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
                <Alert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {isEdit ? 'Échec de la modification du rôle!' : 'Échec de la création du rôle!'}
                </Alert>
            </Snackbar>
        </Drawer>
    );
};

export default AddRole;
