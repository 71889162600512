import { Grid, Skeleton } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled('div')(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

const ParticipationRepartitionReportingsSkeleton = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <MainCard>
            <Grid container spacing={2}>
                {/* Cards Skeleton */}
                <Grid sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }} lg={12}>
                    <Grid lg={12} container spacing={2} sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                        {[...Array(3)].map((_, index) => (
                            <Grid item sm={12} md={12} lg={4} key={index}>
                                <Skeleton variant="rectangular" height={140} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Chart Skeleton */}
                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={300} />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ParticipationRepartitionReportingsSkeleton;
