// AuditLogSkeleton.js
import React from 'react';
import { Box, Card, Avatar, Skeleton } from '@mui/material';

// eslint-disable-next-line arrow-body-style
const AuditLogSkeleton = () => {
    return (
        <Box sx={{ mb: 2 }}>
            <Card sx={{ display: 'flex', alignItems: 'flex-start', p: 2 }}>
                <Skeleton variant="circular" width={56} height={56} sx={{ mr: 2 }} />
                <Box sx={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="30%" height={30} />
                    <Skeleton variant="text" width="50%" height={20} />
                    <Skeleton variant="text" width="40%" height={20} />
                    <Skeleton variant="rectangular" width="100%" height={30} />
                </Box>
            </Card>
        </Box>
    );
};

export default AuditLogSkeleton;
