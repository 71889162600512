import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    // Determine if the current item is active
    const isActive = customization.isOpen.includes(item.id);

    const Icon = item.icon;
    const IconInactive = item.iconInactive;

    // Use theme colors for active and inactive icons
    const itemIcon = item?.icon ? (
        <Icon stroke={1} size="1.3rem" color={isActive ? theme.palette.primary.main : theme.palette.text.primary} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 0 : 0,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 0 : 0
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const itemIconInactive = item?.iconInactive ? (
        <IconInactive stroke={1} size="1.3rem" color={isActive ? theme.palette.primary.main : theme.palette.text.secondary} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 0 : 0,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 0 : 0
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 && isActive ? theme.palette.primary.dark : 'inherit',
                py: level > 1 ? 1 : 1.2,
                ml: `${isActive} ? ${level * 24}px`,
                position: 'relative',
                // Add margin to the left of the border
                '&:before': {
                    content: isActive ? '""' : 'none',
                    position: 'absolute',
                    left: isActive ? -15 : 'auto',
                    top: 0,
                    bottom: 0,
                    borderTopRightRadius: `${customization.borderRadius}px`,
                    borderBottomRightRadius: `${customization.borderRadius}px`,
                    width: 5,
                    color: theme.palette.primary.dark,
                    backgroundColor: 'inherit'
                },
                '&:hover': {
                    color: 'red',
                    backgroundColor: theme.palette.primary.dark
                }
            }}
            selected={isActive}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{isActive ? itemIcon : itemIconInactive}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={isActive ? 'h5' : 'body1'}
                        fontSize={12}
                        fontWeight={isActive ? 900 : 500}
                        color={isActive ? theme.palette.text.hint : theme.palette.text.secondary}
                        sx={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
