import { useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Box,
    Tabs,
    Tab,
    TextField,
    Button,
    Modal,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Avatar
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as CheckMarkIcon } from 'assets/images/icons/check-mark-done.svg';
// import { fetchACPDetails, fetchRelatedHazards, postAssignPerson } from 'store/acpActions';
import { Send } from '@mui/icons-material';

const ACPDetail = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [assignedPeople, setAssignedPeople] = useState([]);
    const [acpDetails, setAcpDetails] = useState({
        reference: '',
        type: '',
        initialStatus: '',
        dueDate: '',
        description: ''
    });
    const [newPerson, setNewPerson] = useState('');
    const dispatch = useDispatch();
    const theme = useTheme();
    const relatedHazards = useSelector((state) => state?.acp?.relatedHazards);
    const acp = useSelector((state) => state?.acp?.currentAcp);

    // useEffect(() => {
    //     dispatch(fetchACPDetails()); // Load ACP details
    //     dispatch(fetchRelatedHazards()); // Load related hazards
    // }, [dispatch]);

    useEffect(() => {
        if (acp) {
            setAcpDetails({
                reference: acp.reference || '',
                type: acp.type || '',
                initialStatus: acp.initialStatus || '',
                dueDate: acp.dueDate || '',
                description: acp.description || ''
            });
            setAssignedPeople(acp.assignedPeople || []);
        }
    }, [acp]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setAcpDetails({
            ...acpDetails,
            [name]: value
        });
    };

    const handleAddPerson = () => {
        if (newPerson.trim()) {
            // dispatch(postAssignPerson(newPerson));
            setNewPerson('');
            setOpenModal(false);
        }
    };

    return (
        <MainCard
            title="Detail de ACP"
            // buttonLink="/some-path"
            // buttonTitle="Click Me"
            // content={true}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ width: '30%' }}>
                            <Tabs
                                orientation="vertical"
                                value={tabIndex}
                                onChange={handleTabChange}
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Related Hazards" />
                                {/* Add more tabs if needed */}
                            </Tabs>
                        </Box>
                        <Box sx={{ width: '70%', p: 2 }}>
                            <Typography variant="h4" gutterBottom>
                                Action détails
                            </Typography>
                            <Divider sx={{ my: 2 }} />

                            <TextField
                                label="Référence du Hazard"
                                name="reference"
                                value={acpDetails.reference}
                                onChange={handleFieldChange}
                                fullWidth
                                margin="normal"
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Type d&apos;ACP</InputLabel>
                                <Select name="type" value={acpDetails.type} onChange={handleFieldChange} label="Type d'ACP">
                                    <MenuItem value="Type1">Type 1</MenuItem>
                                    <MenuItem value="Type2">Type 2</MenuItem>
                                    {/* Add more types as needed */}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Statut initial"
                                name="initialStatus"
                                value={acpDetails.initialStatus}
                                onChange={handleFieldChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Date Limite"
                                type="date"
                                name="dueDate"
                                value={acpDetails.dueDate}
                                onChange={handleFieldChange}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Description"
                                name="description"
                                value={acpDetails.description}
                                onChange={handleFieldChange}
                                multiline
                                rows={4}
                                fullWidth
                                margin="normal"
                            />
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Personnes Assignées
                                </Typography>
                                {assignedPeople.map((person, index) => (
                                    <Box key={index} sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ mr: 2 }} src={person.profilePictureUrl} />
                                        <Typography variant="body1">
                                            {person.firstName} {person.lastName}
                                        </Typography>
                                    </Box>
                                ))}
                                <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                                    Ajouter des personnes
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    <Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 3,
                                borderRadius: 2,
                                width: 400,
                                textAlign: 'center'
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Ajouter des personnes
                            </Typography>
                            <TextField
                                fullWidth
                                value={newPerson}
                                onChange={(e) => setNewPerson(e.target.value)}
                                placeholder="Nom de la personne"
                                variant="filled"
                                margin="normal"
                            />
                            <Button variant="contained" color="primary" onClick={handleAddPerson}>
                                Ajouter
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={() => setOpenModal(false)}>
                                Annuler
                            </Button>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ACPDetail;
