import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux'; // Assuming you're using Redux
import { useHasPermission } from 'utils/hasPermission';

// eslint-disable-next-line arrow-body-style
const filterItemsByPermission = (items, permissions) => {
    return items.filter((item) => {
        // Check if the current item has no specific permissions, or the user has the required permissions
        const hasItemPermission = !item.permissions || item.permissions.some((permission) => useHasPermission(permission));

        // Recursively check child items if present
        if (item.children) {
            item.children = filterItemsByPermission(item.children, permissions);
        }

        // If the current item has permission, or any of its children are allowed, include it
        return hasItemPermission || (item.children && item.children.length > 0);
    });
};

const MenuList = () => {
    const { user } = useSelector((state) => state.currentUser);
    const permissions = user?.role?.permissions;

    // Filter the menu items based on permissions, including children
    const filteredMenuItems = filterItemsByPermission(menuItem?.items, permissions);

    const navItems = filteredMenuItems?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
