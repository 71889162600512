import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing

// Material-UI components
import { useTheme } from '@mui/material/styles';
import { Box, Card, CardContent, CardHeader, Divider, Typography, Button } from '@mui/material';
import navigation from 'menu-items';
import { IconChevronRight } from '@tabler/icons';
// Components
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

// Constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            buttonLink,
            buttonTitle,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        // Handle button click
        const handleButtonClick = (e) => {
            if (!buttonLink) {
                e.preventDefault(); // Prevent the default behavior if no link
                console.warn('No valid link provided');
            }
        };

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? 'none' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                    ...sx
                }}
            >
                {content && (
                    <CardHeader
                        sx={headerSX}
                        action={
                            buttonLink && buttonTitle ? (
                                <Button
                                    component={Link}
                                    to={buttonLink}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleButtonClick}
                                    sx={{
                                        borderRadius: '20px',
                                        padding: '6px 16px',
                                        textTransform: 'capitalize',
                                        backgroundColor: '#2962FF'
                                    }}
                                >
                                    {buttonTitle}
                                </Button>
                            ) : (
                                secondary
                            )
                        }
                        title={
                            (
                                <Breadcrumbs
                                    separator={IconChevronRight}
                                    navigation={navigation}
                                    icon
                                    title
                                    detailsTitle={title}
                                    rightAlign
                                />
                            ) || (
                                <Typography
                                    variant="subtitle1"
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        textDecoration: 'none',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        color: 'grey.500'
                                    }}
                                >
                                    {title}
                                </Typography>
                            )
                        }
                    />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.string,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    buttonLink: PropTypes.string,
    buttonTitle: PropTypes.string
};

export default MainCard;
