import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Tabs,
    Tab,
    Typography,
    Box,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
    InputAdornment,
    OutlinedInput,
    tableCellClasses,
    Grid,
    Button,
    Skeleton
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ReactComponent as LocalizationIcon } from 'assets/images/icons/localization.svg';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@emotion/react';
import SearchSection from 'layout/MainLayout/Header/SearchSection';
import { IconSearch } from '@tabler/icons';
import styled from '@emotion/styled';
import { shouldForwardProp } from '@mui/system';
import { useNavigate } from 'react-router';
import logo from 'assets/images/sarpi-logo.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegions } from 'store/regionsActions';

// TabPanel Component
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sm={{ p: 0 }} md={{ p: 1 }} lg={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

// Accessibility Props for Tabs
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    // width: 4,
    marginInline: 2,
    '& input': {
        background: 'transparent !important'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        background: '#fff'
    }
}));

export default function VerticalTabsWithSearch() {
    const [value, setValue] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const regionsList = useSelector((state) => state.regions);
    const { regionList, loading, error } = regionsList;
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchRegions(1, 15)).then(console.log('regionList : ', regionList));
        console.log(regionList);
    }, [dispatch]);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };
    const navigate = useNavigate();

    const handleCreateRegion = () => {
        navigate('/gestion-des-regions/create');
    };

    const filteredRegions = regionList?.regions?.filter((region) => region?.name?.toLowerCase().includes(searchTerm)) || [];

    // Get the count of sites in each province
    const getProvinceSiteCounts = (provinces, sites) => {
        const siteCounts = provinces?.reduce((acc, province) => {
            acc[province.id] = 0;
            return acc;
        }, {});

        sites.forEach((site) => {
            if (siteCounts[site.province.id] !== undefined) {
                siteCounts[site.province.id] += 1;
            }
        });

        return provinces.map((province) => ({
            ...province,
            siteCount: siteCounts[province.id] || 0
        }));
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgba(252, 253, 253, 1)'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        boxShadow: '0px 0px 6.61px 0px rgba(156, 169, 200, 0.24)',
        border: '0.5px shadow rgba(213, 213, 213, 1)',
        borderRadius: '8px',
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }));

    return (
        <MainCard
            title="Région"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1
            }}
        >
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: isSmallScreen ? 'column' : 'flex', height: 600 }}>
                <Box
                    sx={{
                        maxWidth: isSmallScreen ? '100%' : '350px',
                        borderColor: 'divider',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: '0px 0px 6.61px 0px rgba(156, 169, 200, 0.24)',
                        padding: '20px',
                        borderRadius: '6.61px'
                    }}
                >
                    <OutlineInputStyle
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        placeholder="Région"
                        onChange={handleSearchChange}
                        sx={{ width: '100%' }}
                    />
                    <Tabs
                        orientation={isSmallScreen ? 'horizontal' : 'vertical'}
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Regions tabs"
                        mt={1}
                        sx={{
                            maxWidth: isSmallScreen ? '100%' : '350px',
                            overflowX: isSmallScreen ? 'auto' : 'hidden',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            '& .MuiTabs-indicator': {
                                display: 'none'
                            }
                        }}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        {loading ? (
                            <Tab
                                sx={{
                                    '&.Mui-selected': {
                                        border: '1.56px solid rgba(41, 98, 255, 1)',
                                        borderRadius: '6.74px'
                                    },
                                    '&.Mui-focusVisible': {
                                        backgroundColor: 'rgba(100, 95, 228, 0.32)'
                                    },
                                    '& .MuiTabs-indicatorSpan': {
                                        maxHeight: 4,
                                        backgroundColor: 'transparent'
                                    },
                                    '& .MuiTabs-indicator': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: 0,
                                        backgroundColor: 'transparent !important'
                                    }
                                }}
                                label={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}
                                    >
                                        <Box>
                                            <Skeleton variant="rectangular" width={40} height={50} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                                width: '100%',
                                                ml: 1
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}
                                            >
                                                <Skeleton variant="text" width="30%" height={20} />
                                                <Skeleton variant="circular" width={20} height={20} />
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton variant="circular" width={20} height={20} />
                                                <Skeleton variant="text" width="20%" height={20} sx={{ ml: 1 }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            />
                        ) : (
                            filteredRegions.map((region, index) => (
                                <Tab
                                    key={region.id}
                                    sx={{
                                        '&.Mui-selected': {
                                            border: '1.56px solid rgba(41, 98, 255, 1)',
                                            borderRadius: '6.74px'
                                        },
                                        '&.Mui-focusVisible': {
                                            backgroundColor: 'rgba(100, 95, 228, 0.32)'
                                        },
                                        '& .MuiTabs-indicatorSpan': {
                                            maxHeight: 4,
                                            backgroundColor: 'transparent'
                                        },
                                        '& .MuiTabs-indicator': {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: 0,
                                            backgroundColor: 'transparent !important'
                                        }
                                    }}
                                    label={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}
                                        >
                                            <Box>
                                                <img src={logo} alt="Sarpi" width={40} height={50} />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'start',
                                                    width: '100%',
                                                    ml: 1
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        justifyContent: 'space-between',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Typography variant="subtitle1" sx={{ textAlign: 'start' }}>
                                                        {region.name}
                                                    </Typography>
                                                    <ArrowForwardIcon sx={{ fontSize: 'small' }} />
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <LocalizationIcon sx={{ fontSize: 'small' }} />
                                                    <Typography sx={{ ml: 1, color: 'text.secondary' }}>
                                                        {region.sites.length} Sites
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                    {...a11yProps(index)}
                                />
                            ))
                        )}
                    </Tabs>
                </Box>
                <Box sx={{ width: '100%', p: isSmallScreen ? 0 : 2 }}>
                    {filteredRegions.map((region, index) => (
                        <TabPanel value={value} index={index} key={region.id}>
                            <Grid md={12} sm={12} lg={12} sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '150px' }}>
                                    <Typography variant="subtitle2">Résultat</Typography>
                                    <Typography variant="subtitle1">{region.provinces.length} Région</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCreateRegion}
                                        sx={{
                                            borderRadius: '50px',
                                            padding: '4px, 7px, 4px, 7px',
                                            backgroundColor: theme.palette.primary.dark,
                                            '&:hover': {
                                                backgroundColor: '#0033A0'
                                            },
                                            fontSize: '14px',
                                            gap: '10px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        Ajouter une région
                                    </Button>
                                </Box>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>Region</StyledTableCell>
                                            {/* <StyledTableCell>Responsable</StyledTableCell> */}
                                            <StyledTableCell>Wilayas</StyledTableCell>
                                            <StyledTableCell align="right">Sites</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow key={region.id}>
                                            <StyledTableCell sx={{ padding: '16px' }}>{region.name}</StyledTableCell>

                                            {/* <StyledTableCell sx={{ padding: '16px' }}>
                                                {region?.manager?.firstName && region?.manager?.lastName
                                                    ? `${region.manager.firstName} ${region.manager.lastName}`
                                                    : 'Inconnu'}
                                            </StyledTableCell> */}

                                            <StyledTableCell sx={{ padding: '16px' }}>
                                                {region.provinces.map((province) => province.name).join('/')}
                                            </StyledTableCell>

                                            <StyledTableCell align="right" sx={{ padding: '16px' }}>
                                                {region.sites.length}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    ))}
                </Box>
            </Box>
        </MainCard>
    );
}
