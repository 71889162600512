import fetchApi from './api';

// Action Types
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export const PASSWORD_RESET_VERIFY_CODE_REQUEST = 'PASSWORD_RESET_VERIFY_CODE_REQUEST';
export const PASSWORD_RESET_VERIFY_CODE_SUCCESS = 'PASSWORD_RESET_VERIFY_CODE_SUCCESS';
export const PASSWORD_RESET_VERIFY_CODE_FAILURE = 'PASSWORD_RESET_VERIFY_CODE_FAILURE';

export const PASSWORD_RESET_RESET_REQUEST = 'PASSWORD_RESET_RESET_REQUEST';
export const PASSWORD_RESET_RESET_SUCCESS = 'PASSWORD_RESET_RESET_SUCCESS';
export const PASSWORD_RESET_RESET_FAILURE = 'PASSWORD_RESET_RESET_FAILURE';

// Action Creator for Requesting Password Reset
export const requestPasswordReset = (email) => async (dispatch) => {
    dispatch({ type: PASSWORD_RESET_REQUEST });
    try {
        const data = await fetchApi('/password-reset/web/request', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        });

        // Assuming data has a property indicating success/failure
        if (data.errors) {
            throw new Error(data.errors.email[0] || 'Une erreur est survenue');
        }

        dispatch({ type: PASSWORD_RESET_SUCCESS, payload: data });
        return data; // Return data for handling in the component
    } catch (error) {
        dispatch({ type: PASSWORD_RESET_FAILURE, payload: error.message });
        throw error; // Rethrow error for handling in the component
    }
};

// Action Creator for Verifying Reset Code
export const verifyResetCode = (email, code) => async (dispatch) => {
    dispatch({ type: PASSWORD_RESET_VERIFY_CODE_REQUEST });
    try {
        const data = await fetchApi('/password-reset/web/verify-code', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, code })
        });

        if (data.errors) {
            throw new Error(data.errors.code[0] || 'Une erreur est survenue');
        }

        dispatch({ type: PASSWORD_RESET_VERIFY_CODE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PASSWORD_RESET_VERIFY_CODE_FAILURE, payload: error.message });
    }
};

// Action Creator for Resetting Password
export const resetPassword = (email, code, password) => async (dispatch) => {
    dispatch({ type: PASSWORD_RESET_RESET_REQUEST });
    try {
        const data = await fetchApi('/password-reset/web/reset', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, code, password })
        });

        if (data.errors) {
            throw new Error(data.errors.password[0] || 'Une erreur est survenue');
        }

        dispatch({ type: PASSWORD_RESET_RESET_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PASSWORD_RESET_RESET_FAILURE, payload: error.message });
    }
};
