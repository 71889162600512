import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Card, CardContent } from '@mui/material';
import { Map } from 'algeria-map-ts';
import wilaya from 'utils/wilaya';

const AlgeriaMapWithModal = ({ selectedWilayas, onWilayaClick }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedWilaya, setSelectedWilaya] = useState('');
    const [projects, setProjects] = useState([]);
    const [wilayaColors, setWilayaColors] = useState({});

    useEffect(() => {
        const updatedWilayaColors = {};
        Object.keys(wilaya).forEach((code) => {
            updatedWilayaColors[code] = selectedWilayas?.some((w) => w.code === code) ? '#e14d5e' : '#243f9b';
        });
        setWilayaColors(updatedWilayaColors);
    }, [selectedWilayas]);

    const handleCloseModal = () => {
        setSelectedWilaya('');
        setProjects([]);
        // setModalOpen(false);
    };

    const handleWilayaClickInternal = (wilayaCode, event) => {
        setSelectedWilaya(wilayaCode);
        onWilayaClick(wilayaCode, event);
        console.log(event, wilayaCode);
        // setModalOpen(true);
    };

    const getWilayaName = (code) => wilaya[code]?.name || code;

    return (
        <div className="col d-block justify-content-center align-content-center text-center">
            <Map
                color="#55E6C1"
                HoverColor="#58B19F"
                stroke="#fff"
                hoverStroke="#218c74"
                height="400px"
                width="400px"
                hoverContentStyle={{ backgroundColor: 'transparent' }}
                data={Object.keys(wilaya).reduce(
                    (acc, code) => ({
                        ...acc,
                        [code]: { ...wilaya[code], color: wilayaColors[code] }
                    }),
                    {}
                )}
                onWilayaClick={handleWilayaClickInternal}
            />
            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                maxWidth="md"
                fullWidth
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <DialogTitle id="modal-title">{`Sites dans ${getWilayaName(selectedWilaya)}`}</DialogTitle>
                <DialogContent>
                    {projects.length > 0 ? (
                        projects.map((project) => (
                            <Card key={project.id} style={{ marginBottom: 20, boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: 8 }}>
                                <CardContent>
                                    <Typography variant="h6">{project.title}</Typography>
                                    <Typography variant="body1" paragraph>
                                        {project.description}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {project.images?.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`Projet ${index + 1}`}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: 200,
                                                    objectFit: 'contain',
                                                    margin: '0 auto',
                                                    marginBottom: 10
                                                }}
                                            />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))
                    ) : (
                        <Typography variant="body1">Aucun site trouvé pour {getWilayaName(selectedWilaya)}</Typography>
                    )}
                    <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: 10 }}>
                        Fermer
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AlgeriaMapWithModal;
