// routes/MainRoutes.js
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import DeclarationsList from 'views/gestion-hazards/list-declarations';
import DeclarationDetail from 'views/gestion-hazards/detail-declaration';
import ProtectedRoute from './ProtectedRoute';
import AdminsList from 'views/gestion-admins/list-admins';
import ACPList from 'views/gestion-acp/list-acp';
import AdminCreate from 'views/gestion-admins/create-admin';
import RegionsList from 'views/gestion-regions/list-regions';
import CreateRegion from 'views/gestion-regions/create-region';
import EmployeeList from 'views/gestion-employes/list-employes';
import EmployeeDetail from 'views/gestion-employes/detail-employee';
import HazardMap from 'views/gestion-hazards/map-declaration';
import AdminDetail from 'views/gestion-admins/detail-admin';
import EmployeeCreate from 'views/gestion-employes/create-employee';
import SiteList from 'views/gestion-regions/list-sites';
import SiteCreate from 'views/gestion-regions/create-site';
import ACPDetail from 'views/gestion-acp/detail-acp';
import CreateAcp from 'views/gestion-acp/create-acp';
import EditAdmin from 'views/gestion-admins/edit-admin';
import RolesPermissions from 'views/gestion-admins/attribution-roles';
import ProjectRepports from 'views/analytics/projects-rapports';
import RegionsRepports from 'views/analytics/regions-rapports';
import StatistiquesGlobalesSociety from 'views/analytics/society-rapports';
import ParticipationRepartitionReportings from 'views/analytics/participations-rapports';
import StatistiquesParSiteEtEffectif from 'views/analytics/sites-rapports';
import AuditLogs from 'views/audit-logs';
import RolesPermissionsEdit from 'views/gestion-admins/attribution-roles/edit-roles';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/*',
    element: <MainLayout />,
    children: [
        {
            path: '/*',
            element: <ProtectedRoute />, // Protect this route
            children: [
                {
                    path: '/',
                    element: <DashboardDefault />
                },
                {
                    path: '/dashboard/default',
                    element: <DashboardDefault />
                },
                {
                    path: '/sample-page',
                    element: <SamplePage />
                },
                {
                    path: '/gestion-des-admins/admins',
                    element: <AdminsList />
                },
                {
                    path: '/gestion-des-admins/roles',
                    element: <RolesPermissions />
                },
                {
                    path: '/gestion-des-admins/roles/edit',
                    title: 'Gérer les rôles et permissions',
                    breadcrumbs: true,
                    element: <RolesPermissionsEdit />
                },
                {
                    path: '/gestion-des-admins/create',
                    element: <AdminCreate />,
                    title: 'Liste des Admins',
                    breadcrumbs: true
                },
                {
                    path: '/gestion-des-admins/admins/:id',
                    element: <AdminDetail />,
                    title: "Détails d'un Admin",
                    breadcrumbs: true
                },
                {
                    path: '/gestion-des-admins/admins/edit/:id',
                    element: <EditAdmin />
                },
                {
                    path: '/gestion-des-employes/employes',
                    element: <EmployeeList />,
                    title: 'Liste des Employés',
                    breadcrumbs: true
                },
                {
                    path: '/gestion-des-employes/employes/:id',
                    element: <EmployeeDetail />,
                    title: 'Détails des Employés',
                    breadcrumbs: true
                },
                {
                    path: '/gestion-des-employes/employes/create',
                    element: <EmployeeCreate />
                },
                {
                    path: '/gestion-des-hazards/declarations',
                    element: <DeclarationsList />,
                    title: 'Liste des Déclarations'
                },
                {
                    path: '/gestion-des-hazards/historiques',
                    element: <DeclarationsList />,
                    title: 'Historique des hazards'
                },
                {
                    path: '/gestion-des-regions/regions',
                    element: <RegionsList />,
                    title: 'Liste des Regions'
                },
                {
                    path: '/gestion-des-regions/create',
                    element: <CreateRegion />,
                    title: 'Creation des regions'
                },
                {
                    path: '/gestion-des-sites/sites',
                    element: <SiteList />,
                    title: 'Liste des Sites'
                },
                {
                    path: '/gestion-des-sites/sites/create',
                    element: <SiteCreate />,
                    title: 'Creation des Sites'
                },
                {
                    path: '/gestion-des-hazards/declarations/:id',
                    element: <DeclarationDetail />,
                    title: 'Détails des Déclarations',
                    breadcrumbs: true
                },
                {
                    path: '/gestion-des-hazards/declarations/map',
                    element: <HazardMap />,
                    title: 'Map'
                },
                {
                    path: '/gestion-des-acp',
                    element: <ACPList />
                },
                {
                    path: '/gestion-des-acp/details/:id',
                    element: <ACPDetail />,
                    title: "Détails de l'ACP",
                    breadcrumbs: true
                },
                {
                    path: '/gestion-des-acp/create',
                    element: <CreateAcp />,
                    title: "Creation de l'ACP",
                    breadcrumbs: true
                },
                {
                    path: '/analytiques/projets',
                    element: <ProjectRepports />
                },
                {
                    path: '/analytiques/regions',
                    element: <RegionsRepports />
                },
                {
                    path: '/analytiques/society',
                    element: <StatistiquesGlobalesSociety />
                },
                // {
                //     path: '/analytiques/hazards',
                //     element: <AnalyseDesHazards />
                // },
                {
                    path: '/analytiques/participations',
                    element: <ParticipationRepartitionReportings />
                },
                {
                    path: '/analytiques/sites',
                    element: <StatistiquesParSiteEtEffectif />
                },
                {
                    path: '/audits',
                    element: <AuditLogs />
                }
            ]
        }
    ]
};

export default MainRoutes;
