/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';
import {
    FETCH_EMPLOYEES_REQUEST,
    FETCH_EMPLOYEES_SUCCESS,
    FETCH_EMPLOYEES_FAILURE,
    CREATE_EMPLOYEE_REQUEST,
    CREATE_EMPLOYEE_SUCCESS,
    CREATE_EMPLOYEE_FAILURE,
    FETCH_EMPLOYEE_REQUEST,
    FETCH_EMPLOYEE_SUCCESS,
    FETCH_EMPLOYEE_FAILURE,
    UPDATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAILURE,
    ARCHIVE_EMPLOYEE_REQUEST,
    ARCHIVE_EMPLOYEE_SUCCESS,
    ARCHIVE_EMPLOYEE_FAILURE,
    RESTORE_EMPLOYEE_REQUEST,
    RESTORE_EMPLOYEE_SUCCESS,
    RESTORE_EMPLOYEE_FAILURE,
    FETCH_EMPLOYEE_HAZARDS_REQUEST,
    FETCH_EMPLOYEE_HAZARDS_SUCCESS,
    FETCH_EMPLOYEE_HAZARDS_FAILURE
} from './employeeActions';

// Reducer for Employee List
const initialEmployeeListState = {
    loading: true,
    employees: {
        pagination: {
            currentPage: 1,
            perPage: 15,
            total: 0,
            lastPage: 0
        },
        list: []
    },
    error: null,
    hasMore: true
};

const employeeListReducer = (state = initialEmployeeListState, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEES_REQUEST:
            return { ...state, loading: true, error: null };

        case FETCH_EMPLOYEES_SUCCESS:
            const newPagination = action.payload?.data?.pagination;
            const newEmployees = action.payload?.data?.list;
            console.log(newPagination.currentPage < newPagination.lastPage);
            return {
                ...state,
                loading: false,
                employees: {
                    ...state.employees,
                    list:
                        newPagination.currentPage === 1
                            ? newEmployees // If it's the first page, replace the list
                            : [...state.employees.list, ...newEmployees], // Append new employees for subsequent pages
                    pagination: newPagination // Update the pagination info
                },
                hasMore: newPagination.currentPage < newPagination.lastPage // Determine if more data is available
            };

        case FETCH_EMPLOYEES_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

// Reducer for Employee Creation
const initialEmployeeCreateState = {
    loading: false,
    success: false,
    error: null
};

const employeeCreateReducer = (state = initialEmployeeCreateState, action) => {
    switch (action.type) {
        case CREATE_EMPLOYEE_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case CREATE_EMPLOYEE_SUCCESS:
            return { ...state, loading: false, success: true };
        case CREATE_EMPLOYEE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Single Employee Details
const initialEmployeeDetailsState = {
    loading: false,
    employee: null,
    error: null
};

const employeeDetailsReducer = (state = initialEmployeeDetailsState, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEE_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_EMPLOYEE_SUCCESS:
            return { ...state, loading: false, employee: action.payload.data };
        case FETCH_EMPLOYEE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Single Employee Hazards
const initialEmployeeHazardsState = {
    loading: false,
    hazards: [],
    error: null
};

const employeeHazardsReducer = (state = initialEmployeeHazardsState, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEE_HAZARDS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_EMPLOYEE_HAZARDS_SUCCESS:
            return { ...state, loading: false, hazards: action.payload };
        case FETCH_EMPLOYEE_HAZARDS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Employee Update
const initialEmployeeUpdateState = {
    loading: false,
    success: false,
    error: null
};

const employeeUpdateReducer = (state = initialEmployeeUpdateState, action) => {
    switch (action.type) {
        case UPDATE_EMPLOYEE_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case UPDATE_EMPLOYEE_SUCCESS:
            return { ...state, loading: false, success: true };
        case UPDATE_EMPLOYEE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Employee Archiving
const initialEmployeeArchiveState = {
    loading: false,
    success: false,
    error: null
};

const employeeArchiveReducer = (state = initialEmployeeArchiveState, action) => {
    switch (action.type) {
        case ARCHIVE_EMPLOYEE_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case ARCHIVE_EMPLOYEE_SUCCESS:
            return { ...state, loading: false, success: true };
        case ARCHIVE_EMPLOYEE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Employee Restoration
const initialEmployeeRestoreState = {
    loading: false,
    success: false,
    error: null
};

const employeeRestoreReducer = (state = initialEmployeeRestoreState, action) => {
    switch (action.type) {
        case RESTORE_EMPLOYEE_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case RESTORE_EMPLOYEE_SUCCESS:
            return { ...state, loading: false, success: true };
        case RESTORE_EMPLOYEE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Combine all reducers
const employeeReducer = combineReducers({
    employeeList: employeeListReducer,
    employeeCreate: employeeCreateReducer,
    employeeDetails: employeeDetailsReducer,
    employeeHazards: employeeHazardsReducer,
    employeeUpdate: employeeUpdateReducer,
    employeeArchive: employeeArchiveReducer,
    employeeRestore: employeeRestoreReducer
});

export default employeeReducer;
