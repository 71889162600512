import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: customization.themeMode === 'dark' ? color.darkBackground : color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
        shadows: [
            'none', // 0
            '0px 1px 2px rgba(0, 0, 0, 0.05)', // 1
            '0px 1px 3px rgba(0, 0, 0, 0.1)', // 2
            '0px 2px 4px rgba(0, 0, 0, 0.12)', // 3
            '0px 3px 6px rgba(0, 0, 0, 0.16)', // 4
            '0px 4px 8px rgba(0, 0, 0, 0.18)', // 5
            '0px 6px 12px rgba(0, 0, 0, 0.22)', // 6
            '0px 8px 16px rgba(0, 0, 0, 0.19)', // 7
            '0px 12px 24px rgba(0, 0, 0, 0.26)', // 8
            '0px 16px 32px rgba(0, 0, 0, 0.28)', // 9
            '0px 24px 48px rgba(0, 0, 0, 0.30)', // 10
            '0px 32px 64px rgba(0, 0, 0, 0.32)', // 11
            '0px 40px 80px rgba(0, 0, 0, 0.34)', // 12
            '0px 48px 96px rgba(0, 0, 0, 0.36)', // 13
            '0px 56px 112px rgba(0, 0, 0, 0.38)', // 14
            '0px 64px 128px rgba(0, 0, 0, 0.40)', // 15
            '0px 72px 144px rgba(0, 0, 0, 0.42)', // 16
            '0px 80px 160px rgba(0, 0, 0, 0.44)', // 17
            '0px 88px 176px rgba(0, 0, 0, 0.46)', // 18
            '0px 96px 192px rgba(0, 0, 0, 0.48)', // 19
            '0px 104px 208px rgba(0, 0, 0, 0.50)', // 20
            '0px 112px 224px rgba(0, 0, 0, 0.52)', // 21
            '0px 120px 240px rgba(0, 0, 0, 0.54)', // 22
            '0px 128px 256px rgba(0, 0, 0, 0.56)', // 23
            '0px 136px 272px rgba(0, 0, 0, 0.58)' // 24
        ],
        customization
    };

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption),
        shadows: themeOption.shadows
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
