import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import Slider from 'react-slick';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Grid, Skeleton, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth, gridSpacing } from 'store/constant';
import { SET_MENU } from 'store/actions';
import StatsCard from 'ui-component/stats-card-grid/StatsCard';

import totalHazardsIcon from 'assets/images/icons/total_hazards.svg';
import hazardsEnCoursIcon from 'assets/images/icons/hazards_en_cours.png';
import hazardsCritiquesIcon from 'assets/images/icons/hazards_critiques.svg';
import hazardsResolusIcon from 'assets/images/icons/hazards_resolus.svg';
import dashboard from 'menu-items/dashboard';
import { fetchStats } from 'store/dashboardStatsActions';
import CustomSlider from 'ui-component/extended/CustomCarousel';
import Customization from 'layout/Customization';
import { motion } from 'framer-motion';
import PermissionDeniedModal from 'ui-component/extended/PermissionDeniedModal';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        width: `calc(100% - ${drawerWidth}px)`
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //
const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.stats);

    const location = useLocation();

    const getCurrentStatsVisibility = () => {
        const currentPath = location.pathname;
        const currentItem = dashboard?.children.find((item) => item.url === currentPath);
        if (currentItem) return currentItem?.statsVisible;
        const childItem = dashboard?.children.flatMap((item) => item?.children || []).find((child) => child?.url === currentPath);
        return childItem ? childItem?.statsVisible : false;
    };
    const statsVisible = getCurrentStatsVisibility();

    useEffect(() => {
        if (statsVisible) {
            dispatch(fetchStats());
        }
    }, [dispatch]);

    const cardsData = data?.cards || {
        reported: { value: 0, growth: { up: false, value: 0 }, iconUrl: totalHazardsIcon },
        resolved: { value: 0, growth: { up: false, value: 0 }, iconUrl: hazardsResolusIcon },
        validated: { value: 0, growth: { up: false, value: 0 }, iconUrl: hazardsEnCoursIcon },
        resolutionRate: { value: 0, growth: { up: false, value: 0 }, iconUrl: hazardsCritiquesIcon },
        recurrentHazards: { value: 0, growth: { up: false, value: 0 }, iconUrl: hazardsResolusIcon },
        activeReporting: { value: 0, growth: { up: false, value: 0 }, iconUrl: totalHazardsIcon }
    };

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const [permissionDeniedModal, setPermissionDeniedModal] = useState(false);
    const handleClosePermissionDeniedModal = () => {
        setPermissionDeniedModal(false);
    };

    return (
        <Box sx={{ display: 'flex', backgroundColor: theme.palette.background.paper }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            <Main theme={theme} open={leftDrawerOpened}>
                {statsVisible && <CustomSlider loading={loading} cardsData={cardsData} />}
                <Outlet />
            </Main>
            <PermissionDeniedModal open={permissionDeniedModal} onClose={handleClosePermissionDeniedModal} />
            {/* <Customization /> */}
        </Box>
    );
};

export default MainLayout;
