import { combineReducers } from 'redux';

const {
    FETCH_REGIONS_REQUEST,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
    CREATE_REGION_REQUEST,
    CREATE_REGION_SUCCESS,
    CREATE_REGION_FAILURE,
    FETCH_REGION_REQUEST,
    FETCH_REGION_SUCCESS,
    FETCH_REGION_FAILURE,
    UPDATE_REGION_REQUEST,
    UPDATE_REGION_SUCCESS,
    UPDATE_REGION_FAILURE,
    DELETE_REGION_REQUEST,
    DELETE_REGION_SUCCESS,
    DELETE_REGION_FAILURE,
    RESTORE_REGION_REQUEST,
    RESTORE_REGION_SUCCESS,
    RESTORE_REGION_FAILURE
} = require('./regionsActions');

const initialRegionListState = {
    loading: false,
    regions: [],
    error: null
};

const regionListReducer = (state = initialRegionListState, action) => {
    switch (action.type) {
        case FETCH_REGIONS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_REGIONS_SUCCESS:
            return { ...state, loading: false, regions: action.payload.data.list };
        case FETCH_REGIONS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const initialRegionCreateState = {
    loading: false,
    success: false,
    error: null
};

const regionCreateReducer = (state = initialRegionCreateState, action) => {
    switch (action.type) {
        case CREATE_REGION_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case CREATE_REGION_SUCCESS:
            return { ...state, loading: false, success: true };
        case CREATE_REGION_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const initialRegionDetailsState = {
    loading: false,
    region: null,
    error: null
};

const regionDetailsReducer = (state = initialRegionDetailsState, action) => {
    switch (action.type) {
        case FETCH_REGION_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_REGION_SUCCESS:
            return { ...state, loading: false, region: action.payload };
        case FETCH_REGION_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const initialRegionUpdateState = {
    loading: false,
    success: false,
    error: null
};

const regionUpdateReducer = (state = initialRegionUpdateState, action) => {
    switch (action.type) {
        case UPDATE_REGION_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case UPDATE_REGION_SUCCESS:
            return { ...state, loading: false, success: true };
        case UPDATE_REGION_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const initialRegionDeleteState = {
    loading: false,
    success: false,
    error: null
};

const regionDeleteReducer = (state = initialRegionDeleteState, action) => {
    switch (action.type) {
        case DELETE_REGION_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case DELETE_REGION_SUCCESS:
            return { ...state, loading: false, success: true };
        case DELETE_REGION_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const initialRegionRestoreState = {
    loading: false,
    success: false,
    error: null
};

const regionRestoreReducer = (state = initialRegionRestoreState, action) => {
    switch (action.type) {
        case RESTORE_REGION_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case RESTORE_REGION_SUCCESS:
            return { ...state, loading: false, success: true };
        case RESTORE_REGION_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const regionReducer = combineReducers({
    regionList: regionListReducer,
    regionCreate: regionCreateReducer,
    regionDetails: regionDetailsReducer,
    regionUpdate: regionUpdateReducer,
    regionDelete: regionDeleteReducer,
    regionRestore: regionRestoreReducer
});

export default regionReducer;
