import fetchApi from './api';

export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';

export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';

export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';

export const FETCH_EMPLOYEE_REQUEST = 'FETCH_EMPLOYEE_REQUEST';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';

export const FETCH_EMPLOYEE_HAZARDS_REQUEST = 'FETCH_EMPLOYEE_HAZARDS_REQUEST';
export const FETCH_EMPLOYEE_HAZARDS_SUCCESS = 'FETCH_EMPLOYEE_HAZARDS_SUCCESS';
export const FETCH_EMPLOYEE_HAZARDS_FAILURE = 'FETCH_EMPLOYEE_HAZARDS_FAILURE';

export const ARCHIVE_EMPLOYEE_REQUEST = 'ARCHIVE_EMPLOYEE_REQUEST';
export const ARCHIVE_EMPLOYEE_SUCCESS = 'ARCHIVE_EMPLOYEE_SUCCESS';
export const ARCHIVE_EMPLOYEE_FAILURE = 'ARCHIVE_EMPLOYEE_FAILURE';

export const RESTORE_EMPLOYEE_REQUEST = 'RESTORE_EMPLOYEE_REQUEST';
export const RESTORE_EMPLOYEE_SUCCESS = 'RESTORE_EMPLOYEE_SUCCESS';
export const RESTORE_EMPLOYEE_FAILURE = 'RESTORE_EMPLOYEE_FAILURE';
export const fetchEmployees =
    (page = 1, perPage = 15, sortDir = 'desc', sortBy = 'id', search = '', site = '', gender = '', status = '', bloodType = '') =>
    async (dispatch) => {
        dispatch({ type: FETCH_EMPLOYEES_REQUEST });

        try {
            const queryParams = new URLSearchParams({
                page,
                perPage,
                sortDir,
                sortBy
            });

            // Add filters only if they are provided
            if (search) queryParams.append('search', search);
            if (site) queryParams.append('site', site);
            if (gender) queryParams.append('gender', gender);
            if (bloodType) queryParams.append('bloodType', bloodType);
            if (status) queryParams.append('status', status);

            const response = await fetchApi(`/employees?${queryParams.toString()}`);

            dispatch({ type: FETCH_EMPLOYEES_SUCCESS, payload: response });
        } catch (error) {
            dispatch({ type: FETCH_EMPLOYEES_FAILURE, payload: error.message });
        }
    };

// Create Employee
export const createEmployee = (employeeData) => async (dispatch) => {
    dispatch({ type: CREATE_EMPLOYEE_REQUEST });
    try {
        const data = await fetchApi('/employees', {
            method: 'POST',
            body: JSON.stringify(employeeData)
        });
        dispatch({ type: CREATE_EMPLOYEE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CREATE_EMPLOYEE_FAILURE, payload: error.message });
    }
};

// Fetch Employee by ID
export const fetchEmployeeById = (employeeId) => async (dispatch) => {
    dispatch({ type: FETCH_EMPLOYEE_REQUEST });
    try {
        const data = await fetchApi(`/employees/${employeeId}`);
        dispatch({ type: FETCH_EMPLOYEE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_EMPLOYEE_FAILURE, payload: error.message });
    }
};

// Fetch Hazards by Employee ID
export const fetchHazardsByEmployeeId = (employeeId) => async (dispatch) => {
    dispatch({ type: FETCH_EMPLOYEE_HAZARDS_REQUEST });
    try {
        const data = await fetchApi(`/employees/${employeeId}/hazards?page=1&perPage=3&sortDir=desc&sortBy=id`);
        console.log('hazards : ', data);
        dispatch({ type: FETCH_EMPLOYEE_HAZARDS_SUCCESS, payload: data.data.list });
    } catch (error) {
        dispatch({ type: FETCH_EMPLOYEE_HAZARDS_FAILURE, payload: error.message });
    }
};

// Update Employee
export const updateEmployee = (employeeId, employeeData) => async (dispatch) => {
    dispatch({ type: UPDATE_EMPLOYEE_REQUEST });
    try {
        const data = await fetchApi(`/employees/${employeeId}`, {
            method: 'PUT',
            body: JSON.stringify(employeeData)
        });
        dispatch({ type: UPDATE_EMPLOYEE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_EMPLOYEE_FAILURE, payload: error.message });
    }
};

// Archive Employee
export const archiveEmployee = (employeeId) => async (dispatch) => {
    dispatch({ type: ARCHIVE_EMPLOYEE_REQUEST });
    try {
        await fetchApi(`/employees/${employeeId}`, {
            method: 'DELETE'
        });
        dispatch({ type: ARCHIVE_EMPLOYEE_SUCCESS, payload: employeeId });
    } catch (error) {
        dispatch({ type: ARCHIVE_EMPLOYEE_FAILURE, payload: error.message });
    }
};

// Restore Employee
export const restoreEmployee = (employeeId) => async (dispatch) => {
    dispatch({ type: RESTORE_EMPLOYEE_REQUEST });
    try {
        const data = await fetchApi(`/employees/${employeeId}/restore`, {
            method: 'POST'
        });
        dispatch({ type: RESTORE_EMPLOYEE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: RESTORE_EMPLOYEE_FAILURE, payload: error.message });
    }
};
