import React, { useState, useEffect, useCallback } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

const DynamicLineChart = ({ data, type, xAxisTitle, yAxisTitle, isHorizontal = false, isStacked = false }) => {
    const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.8);
    const theme = useTheme();

    const updateChartWidth = useCallback(() => {
        setChartWidth(document.querySelector('.charts-container')?.offsetWidth || window.innerWidth * 0.8);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateChartWidth);
        updateChartWidth();

        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, [updateChartWidth]);

    const { categories = [], series = [] } = data || {};

    // Map through the series to get the data for the chart
    const seriesData = series.map((label) => ({
        name: label?.name || '',
        data: label?.data || ''
    }));

    // Find the largest data point in all series to annotate, but only for line charts
    let largestDataPoint = { x: null, y: -Infinity, seriesName: null };
    if (type === 'line') {
        seriesData.forEach((serie) => {
            serie.data.forEach((value, index) => {
                if (value > largestDataPoint.y) {
                    largestDataPoint = { x: categories[index], y: value, seriesName: serie?.name };
                }
            });
        });
    }

    const categoryColors = [theme.palette.secondary[900]];

    const chartOptions = {
        chart: {
            type,
            width: '100%',
            stacked: (isHorizontal && isStacked) || false,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: false,
                    customIcons: []
                }
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 2,
                borderRadiusApplication: 'end',
                horizontal: isHorizontal || false
            }
        },
        dataLabels: {
            enabled: isHorizontal || false
        },
        annotations: {
            points:
                type === 'line' && largestDataPoint.x !== null
                    ? [
                          {
                              x: largestDataPoint.x,
                              y: largestDataPoint.y,
                              marker: {
                                  size: 8,
                                  fillColor: '#fff',
                                  strokeColor: 'red',
                                  radius: 2,
                                  cssClass: 'apexcharts-custom-class'
                              },
                              label: {
                                  borderColor: '#FF4560',
                                  offsetY: 0,
                                  style: {
                                      color: '#fff',
                                      background: '#FF4560'
                                  },
                                  text: `Max: ${largestDataPoint.y}`
                              }
                          }
                      ]
                    : []
        },
        title: {
            text: data?.title || 'Diagramme',
            align: 'left',
            style: {
                fontSize: '18px',
                fontWeight: '600',
                color: theme.palette.text.primary,
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '150px'
            }
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            categories,
            axisTicks: { show: true },
            axisBorder: { show: true, color: 'black' },
            labels: {
                style: {
                    colors: categories.map((_, index) => categoryColors[index % categoryColors.length]),
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            },
            title: {
                // text: xAxisTitle,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        yaxis: {
            axisTicks: { show: false },
            axisBorder: { show: true, color: 'black' },
            labels: {
                style: {
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    transform: 'rotate(-90deg)',
                    textAlign: 'left'
                }
            },
            title: {
                // text: yAxisTitle,
                style: {
                    fontSize: '14px',
                    fontWeight: '600'
                }
            }
        },
        grid: {
            borderColor: theme.palette.grey[400],
            padding: { top: 10, right: 0, bottom: 0, left: 10 }
        },
        legend: {
            show: true,
            position: categories.length <= 5 ? 'right' : 'bottom',
            fontSize: '14px',
            fontWeight: 400
        }
    };

    if (!data) {
        return <div className="charts-container">Loading chart data...</div>;
    }

    return (
        <div className="charts-container">
            <Chart options={chartOptions} series={seriesData} type={type} height={500} />
        </div>
    );
};

DynamicLineChart.propTypes = {
    data: PropTypes.shape({
        categories: PropTypes.array.isRequired,
        series: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                data: PropTypes.array.isRequired
            })
        ).isRequired
    }).isRequired,
    type: PropTypes.string.isRequired,
    xAxisTitle: PropTypes.string.isRequired,
    yAxisTitle: PropTypes.string.isRequired
};

export default DynamicLineChart;
