import React from 'react';
import Modal from '@mui/material/Modal'; // or any other modal library you prefer
import { Button } from '@mui/material';

// eslint-disable-next-line arrow-body-style
const PermissionDeniedModal = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <h2>Accès Refusé</h2>
                <p>Vous n&apos;avez pas la permission d&apos;accéder à cette fonctionnalité.</p>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Fermer
                </Button>
            </div>
        </Modal>
    );
};

export default PermissionDeniedModal;
