import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project imports
import config from 'config';
import { gridSpacing } from 'store/constant';

// assets
import { IconTallymark1 } from '@tabler/icons';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignItems: 'center'
};

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({
    card = true,
    divider = true,
    icon = false,
    icons = false,
    maxItems = 8,
    navigation = {},
    rightAlign = false,
    separator = IconTallymark1,
    title = false,
    titleBottom = false,
    detailsTitle = '',
    ...others
}) => {
    const theme = useTheme();
    const location = useLocation();

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '1rem',
        height: '1rem',
        color: theme.palette.secondary.main
    };

    const [main, setMain] = useState(null);
    const [item, setItem] = useState(null);

    // Recursive function to find the current item
    const getCollapse = (menu) => {
        if (menu.children) {
            menu.children.forEach((collapse) => {
                if (collapse.type === 'collapse') {
                    getCollapse(collapse);
                } else if (collapse.type === 'item' && location.pathname === `${config.basename}${collapse.url}`) {
                    setMain(menu);
                    setItem(collapse);
                }
            });
        }
    };

    useEffect(() => {
        navigation?.items?.forEach((menu) => {
            if (menu.type === 'group') {
                getCollapse(menu);
            }
        });
    }, [location, navigation]);

    // Hide breadcrumbs for certain routes
    const hideBreadcrumbs = location.pathname === '/dashboard/default' || location.pathname === '/';

    // Define separator icon
    const SeparatorIcon = separator;
    const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="1rem" /> : <IconTallymark1 stroke={1.5} size="1rem" />;

    // Define main and item content
    const mainContent =
        main?.type === 'collapse' ? (
            <Typography component={Link} to="/" variant="subtitle1" sx={linkSX}>
                {icons && <main.icon style={iconStyle} />}
                {main.title}
            </Typography>
        ) : null;

    const itemContent =
        item?.type === 'item' ? (
            <Typography
                variant="subtitle1"
                component={Link}
                to={item.url}
                sx={{
                    ...linkSX,
                    color: 'grey.500'
                }}
            >
                {icons && <item.icon style={iconStyle} />}
                {item.title}
            </Typography>
        ) : null;

    const breadcrumbContent = !hideBreadcrumbs && (
        <Card
            // sx={{
            //     background: card ? theme.palette.background.default : 'transparent'
            // }}
            {...others}
        >
            <Box sx={{ pl: card ? 2 : 0 }}>
                <Grid
                    container
                    direction={rightAlign ? 'row' : 'column'}
                    justifyContent={rightAlign ? 'space-between' : 'flex-start'}
                    alignItems={rightAlign ? 'center' : 'flex-start'}
                    spacing={1}
                >
                    <Grid item>
                        <MuiBreadcrumbs
                            sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                            aria-label="breadcrumb"
                            maxItems={maxItems}
                            separator={separatorIcon}
                        >
                            <Typography component={Link} to="/" color="inherit" variant="subtitle1" sx={linkSX}>
                                {icons && <HomeTwoToneIcon sx={iconStyle} />}
                                {icon && <HomeIcon sx={{ ...iconStyle, mr: 0 }} />}
                                {!icon && 'Dashboard'}
                            </Typography>
                            {mainContent}
                            {itemContent}
                            {detailsTitle && (
                                <Typography variant="subtitle1" color="grey.500">
                                    {detailsTitle}
                                </Typography>
                            )}
                        </MuiBreadcrumbs>
                    </Grid>
                </Grid>
            </Box>
            {/* {card && divider && <Divider sx={{ borderColor: theme.palette.primary, mb: gridSpacing }} />} */}
        </Card>
    );

    return breadcrumbContent;
};

Breadcrumbs.propTypes = {
    card: PropTypes.bool,
    divider: PropTypes.bool,
    icon: PropTypes.bool,
    icons: PropTypes.bool,
    maxItems: PropTypes.number,
    navigation: PropTypes.object,
    rightAlign: PropTypes.bool,
    separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    title: PropTypes.bool,
    titleBottom: PropTypes.bool,
    detailsTitle: PropTypes.string
};

export default Breadcrumbs;
