import { useTheme } from '@emotion/react';
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
    Avatar,
    Pagination,
    CircularProgress,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    InputAdornment,
    TextField,
    MenuItem,
    Checkbox,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // For navigation
import { fetchAdmins } from 'store/adminActions'; // Import the action creator
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';

const AdminsList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({
        search: '',
        role: [],
        gender: [],
        bloodType: [],
        status: [],
        sortDir: []
    });
    const usersPerPage = 50;

    const { adminList } = useSelector((state) => state.admin);
    const customization = useSelector((state) => state.customization);
    const { loading, admins, error } = adminList;
    const { user: currentUser } = useSelector((state) => state.currentUser);

    useEffect(() => {
        dispatch(fetchAdmins(page, usersPerPage)); // Fetch admins based on current page and users per page
    }, [dispatch, page, currentUser]);

    const handleCardClick = (userId) => {
        navigate(`/gestion-des-admins/admins/${userId}`, { state: { id: userId } });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalUsers = admins?.data?.pagination?.total || 0;
    const totalPages = Math.ceil(totalUsers / usersPerPage);
    const paginatedData = admins?.data?.list || [];
    console.log(paginatedData);
    const handleFilterChange = (e) => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            [e.target.name]: e.target.value
        }));
        setPage(1); // Reset page when filters are changed
    };

    const clearFilters = () => {
        setPage(1); // Reset page when filters are cleared
        setFilter({
            search: '',
            site: [],
            gender: [],
            status: [],
            sortDir: []
        });
    };

    const useStyles = makeStyles((theme) => ({
        textField: {
            maxWidth: '350px',
            borderBottom: 'none',
            '& .MuiInputBase-input': {
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: theme.palette.grey[700],
                opacity: 1
            },
            '& .MuiOutlinedInput-input': {
                borderBottom: 'none'
            }
        },
        submitButton: {
            marginTop: theme.spacing(2),
            backgroundColor: '#1F41BB',
            height: '50px'
        }
    }));

    const classes = useStyles();

    // Determine the content to render based on loading and error states
    let content;

    if (loading) {
        content = (
            <Grid container spacing={gridSpacing} sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden', flexWrap: 'wrap' }}>
                <Grid
                    container
                    spacing={2}
                    sx={{ mb: 3, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', mx: 0, px: 1 }}
                >
                    <Grid item lg={3} md={4} xs={4}>
                        <TextField
                            name="search"
                            variant="outlined"
                            fullWidth
                            placeholder="Rechercher ..."
                            onChange={handleFilterChange}
                            value={filter.search}
                            className={classes.textField}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item lg={2} md={2} xs={2}>
                        <FormControl fullWidth>
                            <InputLabel className={classes.label}>Role</InputLabel>
                            <Select
                                multiple
                                name="role"
                                variant="outlined"
                                value={filter.role}
                                onChange={handleFilterChange}
                                renderValue={(selected) =>
                                    selected.map((val) => (val === 'region-manager' ? 'Region Manager' : 'Site Manager')).join(', ')
                                }
                            >
                                <MenuItem value="region-manager">
                                    <Checkbox checked={filter?.role?.name?.indexOf('region-manager') > -1} />
                                    <ListItemText primary="Region Manager" />
                                </MenuItem>
                                <MenuItem value="site-manager">
                                    <Checkbox checked={filter?.role?.name?.indexOf('site-manager') > -1} />
                                    <ListItemText primary="Region Manager" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} md={2} xs={2}>
                        <FormControl fullWidth>
                            <InputLabel className={classes.label}>Genre</InputLabel>
                            <Select
                                multiple
                                name="gender"
                                variant="outlined"
                                value={filter.gender}
                                onChange={handleFilterChange}
                                renderValue={(selected) => selected.map((val) => (val === 'man' ? 'Homme' : 'Femme')).join(', ')}
                            >
                                <MenuItem value="man">
                                    <Checkbox checked={filter.gender.indexOf('man') > -1} />
                                    <ListItemText primary="Homme" />
                                </MenuItem>
                                <MenuItem value="woman">
                                    <Checkbox checked={filter.gender.indexOf('woman') > -1} />
                                    <ListItemText primary="Femme" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ position: 'relative', height: '500px', overflow: 'auto' }}>
                    <Table stickyHeader aria-label="skeleton table">
                        {/* Table Head */}
                        <TableHead>
                            <TableRow>
                                <TableCell> </TableCell>
                                <TableCell>Nom</TableCell>
                                <TableCell>Rôle</TableCell>
                                <TableCell>Centre</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Date d&apos;intégration</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                            {/* Render skeleton rows */}
                            {[...Array(5)].map((_, index) => (
                                <TableRow key={index}>
                                    {/* Skeleton for Avatar */}
                                    <TableCell>
                                        <Skeleton variant="circular" width={60} height={60} />
                                    </TableCell>

                                    {/* Skeleton for Nom */}
                                    <TableCell>
                                        <Skeleton variant="text" width={100} height={30} />
                                    </TableCell>

                                    {/* Skeleton for Rôle */}
                                    <TableCell>
                                        <Skeleton variant="text" width={80} height={20} />
                                    </TableCell>

                                    {/* Skeleton for Centre */}
                                    <TableCell>
                                        <Skeleton variant="text" width={100} height={20} />
                                    </TableCell>

                                    {/* Skeleton for Email */}
                                    <TableCell>
                                        <Skeleton variant="text" width={150} height={20} />
                                    </TableCell>

                                    {/* Skeleton for Date d'Intégration */}
                                    <TableCell>
                                        <Skeleton variant="text" width={130} height={20} />
                                    </TableCell>

                                    {/* Skeleton for Status */}
                                    <TableCell>
                                        <Skeleton variant="rectangular" width={80} height={30} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    } else if (error) {
        content = (
            <Typography variant="body1" color="error" align="center">
                Error loading admins: {error}
            </Typography>
        );
    } else if (!loading && !error) {
        content = (
            <>
                <Grid container spacing={gridSpacing} sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden', flexWrap: 'wrap' }}>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mb: 3, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', mx: 0, px: 1 }}
                    >
                        <Grid item lg={4} md={8} xs={12}>
                            <TextField
                                name="search"
                                variant="outlined"
                                fullWidth
                                placeholder="Rechercher ..."
                                onChange={handleFilterChange}
                                value={filter.search}
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Role</InputLabel>
                                <Select
                                    multiple
                                    name="role"
                                    variant="outlined"
                                    value={filter.gender}
                                    onChange={handleFilterChange}
                                    renderValue={(selected) =>
                                        selected.map((val) => (val === 'region-manager' ? 'Region Manager' : 'Site Manager')).join(', ')
                                    }
                                >
                                    <MenuItem value="region-manager">
                                        <Checkbox checked={filter.gender.indexOf('region-manager') > -1} />
                                        <ListItemText primary="Region Manager" />
                                    </MenuItem>
                                    <MenuItem value="site-manager">
                                        <Checkbox checked={filter.gender.indexOf('site-manager') > -1} />
                                        <ListItemText primary="Region Manager" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Status</InputLabel>
                                <Select
                                    multiple
                                    name="status"
                                    variant="outlined"
                                    value={filter.status}
                                    onChange={handleFilterChange}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) return 'Tous';
                                        const uniqueValues = [...new Set(selected)];

                                        const isActiveSelected = uniqueValues.includes('active');
                                        const isInactiveSelected = uniqueValues.includes('inactive');
                                        const isTousSelected = uniqueValues.includes('');

                                        if (isActiveSelected && !isInactiveSelected && !isTousSelected) {
                                            return 'Actif';
                                        }
                                        if (isInactiveSelected && !isActiveSelected && !isTousSelected) {
                                            return 'Inactif';
                                        }
                                        return 'Tous';
                                    }}
                                >
                                    <MenuItem value="">
                                        <Checkbox checked={filter.status.indexOf('') > -1} />
                                        <ListItemText primary="Tous" />
                                    </MenuItem>
                                    <MenuItem value="active">
                                        <Checkbox checked={filter.status.indexOf('active') > -1} />
                                        <ListItemText primary="Actif" />
                                    </MenuItem>
                                    <MenuItem value="inactive">
                                        <Checkbox checked={filter.status.indexOf('inactive') > -1} />
                                        <ListItemText primary="Inactif" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={1.5} md={1.5} xs={2.5}>
                            <TrashIcon onClick={clearFilters} style={{ cursor: 'pointer' }} />
                        </Grid>
                    </Grid>
                    {/* {paginatedData.map((user) => ( */}
                    <TableContainer component={Paper} sx={{ position: 'relative', height: '500px', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            {/* Table Head */}
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: '900',
                                            color: '#202224',
                                            lineHeight: '11px'
                                        }}
                                    >
                                        {' '}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: '900',
                                            color: '#202224',
                                            lineHeight: '11px'
                                        }}
                                    >
                                        Nom
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: '900',
                                            color: '#202224',
                                            lineHeight: '11px'
                                        }}
                                    >
                                        Rôle
                                    </TableCell>
                                    {/* <TableCell
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: '900',
                                            color: '#202224',
                                            lineHeight: '11px'
                                        }}
                                    >
                                        Centre
                                    </TableCell> */}
                                    <TableCell
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: '900',
                                            color: '#202224',
                                            lineHeight: '11px'
                                        }}
                                    >
                                        Email
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: '900',
                                            color: '#202224',
                                            lineHeight: '11px'
                                        }}
                                    >
                                        Date d&apos;ntégration
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: '900',
                                            color: '#202224',
                                            lineHeight: '11px'
                                        }}
                                    >
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {/* Table Body */}
                            <TableBody>
                                {paginatedData.map((user) => (
                                    <TableRow
                                        key={user?.id}
                                        hover
                                        onClick={() => handleCardClick(user.id)}
                                        sx={{
                                            cursor: 'pointer',
                                            borderColor: currentUser?.id === user?.id ? 'primary.main' : 'none',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.05)'
                                            }
                                        }}
                                    >
                                        {/* Avatar */}
                                        <TableCell>
                                            <Avatar
                                                src={user?.profilePictureUrl}
                                                alt={user?.firstName}
                                                sx={{ width: '60px', height: '60px' }}
                                            />
                                        </TableCell>

                                        {/* Name */}
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                                variant="subtitle1"
                                            >
                                                {user?.firstName} {user?.lastName}
                                            </Typography>
                                        </TableCell>

                                        {/* Role */}
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '14px'
                                                }}
                                                variant="subtitle1"
                                            >
                                                {user?.role?.name}
                                            </Typography>
                                        </TableCell>

                                        {/* Centre */}
                                        {/* <TableCell>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[700],
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {user?.role?.name !== 'admin' ? `${user?.managedEntity?.name || ''}` : 'Administration'}
                                            </Typography>
                                        </TableCell> */}

                                        {/* Email */}
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '14px'
                                                }}
                                                variant="subtitle1"
                                            >
                                                {user?.email}
                                            </Typography>
                                        </TableCell>

                                        {/* Date d'Intégration */}
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.grey[600],
                                                    fontSize: '0.75rem'
                                                }}
                                                variant="subtitle1"
                                            >
                                                {user?.lastLoginAt
                                                    ? new Date(user?.lastLoginAt * 1000).toLocaleString('fr-FR', {
                                                          day: '2-digit',
                                                          month: 'long',
                                                          year: 'numeric',
                                                          hour: '2-digit',
                                                          minute: '2-digit',
                                                          hour12: false
                                                      })
                                                    : 'N/A'}
                                            </Typography>
                                        </TableCell>

                                        {/* Status */}
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    backgroundColor:
                                                        user?.accountStatus === 'active'
                                                            ? theme.palette.success.dark
                                                            : theme.palette.error.main,
                                                    color: theme.palette.text.hint,
                                                    borderRadius: '8px',
                                                    padding: '2px 8px',
                                                    fontSize: '0.75rem'
                                                }}
                                                variant="subtitle1"
                                            >
                                                {user?.accountStatus === 'active' ? 'Active' : 'Blocked'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'sticky',
                        bottom: 0,
                        alignItems: 'center',
                        backgroundColor: theme.palette.background.paper,
                        py: 2
                    }}
                >
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                </Box>
            </>
        );
    }

    return (
        <MainCard
            buttonLink="/gestion-des-admins/create"
            buttonTitle="Ajouter un admin"
            // content={content}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                flexGrow: 1
            }}
        >
            {content}
        </MainCard>
    );
};

export default AdminsList;
