import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Grid, Typography, TextField, FormControl, Button, CircularProgress, Select, MenuItem, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { fetchAdmins } from 'store/adminActions';
import { registerSite } from 'store/sitesActions';
import { fetchRegions } from 'store/regionsActions';
import { fetchProvinces } from 'store/provincesActions';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';
import StatusModal from 'ui-component/extended/StatusModal';
import AddSite from './addSite';

const SiteCreate = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { adminList } = useSelector((state) => state.admin);
    const { loading: adminsLoading, admins } = adminList;
    const regionsList = useSelector((state) => state.regions);
    const { regionList: regions, loading: regionsLoading, error } = regionsList;
    const { provinces: wilayas } = useSelector((state) => state.province);
    const { registerSuccess, loading } = useSelector((state) => state.sites); // Adjusted here
    const [formData, setFormData] = useState({
        name: '',
        designation: '',
        abbreviation: '',
        address: '',
        phone: '',
        responsible: '',
        regionId: '',
        provinceId: '',
        geolocation: {
            longitude: 36.812848841983445,
            latitude: 2.9539618208854828
        }
    });
    const [openModal, setOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    useEffect(() => {
        async function fetchData() {
            await dispatch(fetchAdmins());
            await dispatch(fetchRegions());
            await dispatch(fetchProvinces());
        }
        fetchData();
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const useStyles = makeStyles((theme) => ({
        textField: {
            height: '40px',
            maxWidth: '300px',
            '& .MuiInputBase-input': {
                height: '40px',
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiInput-underline:before': {
                borderBottom: 'none'
            }
        },
        label: {
            paddingLeft: '10px',
            fontWeight: '600'
        },
        submitButton: {
            marginTop: theme.spacing(2),
            backgroundColor: '#1F41BB',
            height: '50px',
            width: '30%'
        }
    }));

    useEffect(() => {
        if (registerSuccess) {
            setModalTitle('Succès');
            setModalContent('Création réussie !');
            setOpenModal(true);
            // navigate('/gestion-des-sites/sites');
        } else if (error) {
            setModalTitle('Erreur');
            setModalContent(`Erreur: ${error}`);
            setOpenModal(true);
        }
    }, [registerSuccess, error]); // Adjusted here

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Nom de site est requis';
        if (!formData.designation) newErrors.designation = 'Désignation est requise';
        if (!formData.abbreviation) newErrors.abbreviation = 'Abréviation est requise';
        if (!formData.responsible) newErrors.responsible = 'Responsable est requis';
        if (!formData.regionId) newErrors.regionId = 'Région est requise';
        if (!formData.provinceId) newErrors.provinceId = 'Wilaya est requise';
        if (!formData.phone) newErrors.phone = 'Téléphone est requis';
        if (!formData.address) newErrors.address = 'Adresse est requise';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [drawerOpen, setDrawerOpen] = useState(true);

    const handleOpen = () => setDrawerOpen(true);
    const handleClose = () => setDrawerOpen(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            await dispatch(registerSite(formData));
        } catch (error) {
            console.error('Error creating site:', error);
            setErrors(error.response?.data?.errors || {});
        }
    };

    const classes = useStyles();
    return (
        <MainCard
            title="Créer un site"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1,
                padding: 2
            }}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Nom de site"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Désignation"
                                        name="designation"
                                        value={formData.designation}
                                        onChange={handleInputChange}
                                        error={!!errors.designation}
                                        helperText={errors.designation}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Adresse"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        error={!!errors.address}
                                        helperText={errors.address}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Téléphone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Abréviation"
                                        name="abbreviation"
                                        value={formData.abbreviation}
                                        onChange={handleInputChange}
                                        error={!!errors.abbreviation}
                                        helperText={errors.abbreviation}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Responsable</InputLabel>
                                    <Select
                                        name="responsible"
                                        value={formData.responsible}
                                        onChange={handleInputChange}
                                        error={!!errors.responsible}
                                    >
                                        {admins?.data?.list?.map((admin) => (
                                            <MenuItem key={admin.id} value={admin.id}>
                                                {admin.firstName} {admin.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.responsible && <Typography color="error">{errors.responsible}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Région</InputLabel>
                                    <Select
                                        name="regionId"
                                        value={formData.regionId}
                                        onChange={handleInputChange}
                                        error={!!errors.regionId}
                                    >
                                        {regions?.regions?.map((region) => (
                                            <MenuItem key={region.id} value={region.id}>
                                                {region?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.regionId && <Typography color="error">{errors.regionId}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Wilaya</InputLabel>
                                    <Select
                                        name="provinceId"
                                        value={formData.provinceId}
                                        onChange={handleInputChange}
                                        error={!!errors.provinceId}
                                    >
                                        {wilayas?.map((wilaya) => (
                                            <MenuItem key={wilaya.id} value={wilaya.id}>
                                                {wilaya.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.provinceId && <Typography color="error">{errors.provinceId}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <Button
                                    className={classes.submitButton}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Créer un site'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            <StatusModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                title={modalTitle}
                content={modalContent}
                icon={registerSuccess ? 'success' : 'error'}
            />
            <AddSite isOpen={drawerOpen} onClose={handleClose} />
        </MainCard>
    );
};

export default SiteCreate;
