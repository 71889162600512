import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Chip,
    Card,
    CardContent,
    Divider,
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAudits } from 'store/auditsActions';
import {
    CheckCircle,
    Cancel,
    Description,
    Archive,
    Assignment,
    Add,
    Edit,
    Remove,
    Visibility,
    Lock,
    People,
    History,
    Error
} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import AuditLogSkeleton from 'ui-component/cards/Skeleton/AuditLogsSkeleton';
import DateRangePicker from 'ui-component/extended/DateRangePicker';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isBetween);

const activityIcons = {
    'auth.login': <CheckCircle sx={{ color: 'green' }} />,
    'auth.logout': <Cancel sx={{ color: 'red' }} />,
    'hazard.export': <Description sx={{ color: 'blue' }} />,
    'hazard.archive': <Archive sx={{ color: 'orange' }} />,
    'hazard.update': <Edit sx={{ color: 'purple' }} />,
    'site.archive': <Archive sx={{ color: 'orange' }} />,
    'site.update': <Edit sx={{ color: 'purple' }} />,
    'site.create': <Add sx={{ color: 'green' }} />,
    'hazard.create': <Add sx={{ color: 'green' }} />,
    'region.archive': <Archive sx={{ color: 'orange' }} />,
    'region.create': <Add sx={{ color: 'green' }} />,
    'employee.create': <Add sx={{ color: 'green' }} />,
    'employee.archive': <Archive sx={{ color: 'orange' }} />,
    'employee.update': <Edit sx={{ color: 'purple' }} />,
    'admin.create': <Add sx={{ color: 'green' }} />,
    'admin.archive': <Archive sx={{ color: 'orange' }} />,
    'admin.update': <Edit sx={{ color: 'purple' }} />,

    'auth.password_change': <Lock sx={{ color: 'blue' }} />,
    'profile.update': <Edit sx={{ color: 'purple' }} />,
    'user.role_update': <People sx={{ color: 'blue' }} />,
    'notification.sent': <Visibility sx={{ color: 'blue' }} />,
    'hazard.resolve': <CheckCircle sx={{ color: 'green' }} />,
    'hazard.error': <Error sx={{ color: 'red' }} />,
    'site.visited': <Visibility sx={{ color: 'blue' }} />,
    'employee.performance_review': <History sx={{ color: 'blue' }} />,
    'region.update': <Edit sx={{ color: 'purple' }} />,
    'admin.permissions_change': <Lock sx={{ color: 'blue' }} />,
    'admin.login_failed': <Error sx={{ color: 'red' }} />
};

const AuditLogs = () => {
    const dispatch = useDispatch();
    const { audits: logs, loading, hasMore, totalItems } = useSelector((state) => state.auditsReducer);
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        dispatch(fetchAudits({ page, perPage: 15, search: '', minDate: '', maxDate: '' }));
    }, [dispatch, page]);

    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const formatTime = (timestamp) => dayjs.unix(timestamp).format('HH:mm:ss');

    const formatDate = (timestamp) => {
        const date = dayjs.unix(timestamp);
        if (date.isToday()) return "Aujourd'hui";
        if (date.isYesterday()) return 'Hier';

        const lastWeekStart = dayjs().subtract(1, 'week').startOf('week');
        const lastWeekEnd = dayjs().subtract(1, 'week').endOf('week');
        if (date?.isBetween(lastWeekStart, lastWeekEnd, null, '[]')) return 'La semaine dernière';

        const lastMonthStart = dayjs().subtract(1, 'month').startOf('month');
        const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month');
        if (date?.isBetween(lastMonthStart, lastMonthEnd, null, '[]')) return 'Le mois dernier';

        return date.format('DD/MM/YYYY');
    };

    let lastLogDate = null;

    const [filters, setFilters] = useState({
        search: '',
        minDate: '',
        maxDate: '',
        sortBy: 'createdAt',
        sortDir: 'desc'
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleApplyFilters = () => {
        dispatch(
            fetchAudits({
                page: 1,
                perPage: 10,
                search: filters.search,
                minDate: filters.minDate,
                maxDate: filters.maxDate,
                sortDir: filters.sortDir,
                sortBy: filters.sortBy
            })
        );
    };

    return (
        <MainCard>
            <Grid container spacing={2}>
                {/* Filters Section */}
                <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                        position: { md: 'sticky', xs: 'relative' },
                        top: 0,
                        maxHeight: { md: '100vh' },
                        overflowY: { md: 'auto' },
                        borderLeft: { md: '1px solid #ddd' },
                        backgroundColor: '#fff',
                        order: { xs: 1, md: 1 },
                        pb: 2,
                        px: { xs: 1, md: 2 },
                        mb: { xs: 2, md: 0 }
                    }}
                >
                    <Box>
                        <Typography variant="h3" sx={{ mb: 2 }}>
                            Filtres
                        </Typography>

                        {/* Search Field */}
                        <TextField
                            label="Recherche"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                            fullWidth
                            size="small"
                            sx={{ mb: 2 }}
                        />

                        {/* Date Range Picker */}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <DateRangePicker
                                InputProps={{ size: 'small' }}
                                onDateChange={({ minDate, maxDate }) => {
                                    setFilters((prev) => ({
                                        ...prev,
                                        minDate,
                                        maxDate
                                    }));
                                }}
                            />
                        </FormControl>

                        {/* Sort By Select */}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="sort-by-label">Trier par</InputLabel>
                            <Select
                                labelId="sort-by-label"
                                value={filters.sortBy}
                                label="Trier par"
                                onChange={(e) => setFilters((prev) => ({ ...prev, sortBy: e.target.value }))}
                                size="small"
                            >
                                <MenuItem value="createdAt">Date de création</MenuItem>
                                <MenuItem value="activity">Activité</MenuItem>
                                <MenuItem value="message">Message</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Sort Direction Select */}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="sort-dir-label">Ordre</InputLabel>
                            <Select
                                labelId="sort-dir-label"
                                value={filters.sortDir}
                                label="Ordre"
                                onChange={(e) => setFilters((prev) => ({ ...prev, sortDir: e.target.value }))}
                                size="small"
                            >
                                <MenuItem value="desc">Descendant</MenuItem>
                                <MenuItem value="asc">Ascendant</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Apply Filters Button */}
                        <Button variant="contained" onClick={handleApplyFilters} fullWidth size="small">
                            Appliquer les filtres
                        </Button>
                    </Box>
                </Grid>
                {/* Timeline Logs */}
                <Grid
                    item
                    xs={12}
                    md={9}
                    sx={{
                        order: { xs: 2, md: 0 },
                        pr: { xs: 0, md: 2 }
                    }}
                >
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Journaux d&apos;Audit
                    </Typography>
                    <InfiniteScroll
                        dataLength={logs.length}
                        next={fetchMoreData}
                        sx={{ p: 0 }}
                        hasMore={hasMore}
                        loader={<AuditLogSkeleton />}
                        endMessage={<Typography variant="body1">Fin des journaux d&apos;audit</Typography>}
                    >
                        <Timeline
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0
                                },
                                justifyContent: 'flex-start',
                                mb: { xs: 1, md: 3 }
                            }}
                        >
                            {logs?.map((log, index) => {
                                const currentLogDate = formatDate(log.createdAt);
                                const isNewDay = currentLogDate !== lastLogDate;

                                lastLogDate = currentLogDate;

                                return (
                                    <React.Fragment key={log.id}>
                                        {isNewDay && (
                                            <Box sx={{ mt: 1 }}>
                                                <Divider>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            px: 1,
                                                            py: 0.5,
                                                            borderRadius: 20,
                                                            borderWidth: 1,
                                                            border: '1px solid lightgray',
                                                            borderColor: 'grey',
                                                            fontSize: { xs: '0.875rem', sm: '1rem' }
                                                        }}
                                                    >
                                                        {currentLogDate}
                                                    </Typography>
                                                </Divider>
                                            </Box>
                                        )}

                                        <TimelineItem sx={{ alignItems: 'flex-start', mb: { xs: 1, sm: 2 }, justifyContent: 'flex-end' }}>
                                            <TimelineSeparator
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    position: 'relative',
                                                    paddingRight: { xs: 1, sm: 2 },
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                    flex: 0,
                                                    flexDirection: 'row',
                                                    transform: { xs: 'rotate(-90deg)', sm: 'none' },
                                                    transformOrigin: 'right',
                                                    width: 1
                                                }}
                                            >
                                                {formatTime(log.createdAt)}
                                                {activityIcons[log.activity]}
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ textAlign: 'left', justifyContent: 'flex-start', m: 0 }}>
                                                <SubCard>
                                                    <Card sx={{ display: 'flex', alignItems: 'flex-start', p: { xs: 1, sm: 2 } }}>
                                                        <Avatar
                                                            src={log.user.profilePictureUrl}
                                                            alt={`${log.user.firstName} ${log.user.lastName}`}
                                                            sx={{ width: 40, height: 40, mr: 0 }}
                                                        />
                                                        <CardContent sx={{ flexGrow: 0, textAlign: 'start' }}>
                                                            <Typography variant="h5" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                                                                {log.user.firstName} {log.user.lastName}
                                                            </Typography>
                                                            <Typography variant="body1" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                                                                {log.user.email}
                                                            </Typography>
                                                            <Typography variant="body1">{log.user.phone}</Typography>
                                                            <Typography variant="h4">{log.description}</Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Adresse IP : {log.ipAddress}
                                                            </Typography>
                                                        </CardContent>
                                                        <Box sx={{ alignSelf: 'flex-start', color: 'white', display: { xs: 'none' } }}>
                                                            <Chip
                                                                label={log.user.accountStatus === 'active' ? 'Actif' : 'Inactif'}
                                                                color={log.user.accountStatus === 'active' ? 'success' : 'default'}
                                                            />
                                                        </Box>
                                                    </Card>
                                                </SubCard>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </React.Fragment>
                                );
                            })}
                        </Timeline>
                    </InfiniteScroll>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AuditLogs;
