import fetchApi from './api';

// Action Types for Regions
export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS_FAILURE';

export const CREATE_REGION_REQUEST = 'CREATE_REGION_REQUEST';
export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const CREATE_REGION_FAILURE = 'CREATE_REGION_FAILURE';

export const FETCH_REGION_REQUEST = 'FETCH_REGION_REQUEST';
export const FETCH_REGION_SUCCESS = 'FETCH_REGION_SUCCESS';
export const FETCH_REGION_FAILURE = 'FETCH_REGION_FAILURE';

export const UPDATE_REGION_REQUEST = 'UPDATE_REGION_REQUEST';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_FAILURE = 'UPDATE_REGION_FAILURE';

export const DELETE_REGION_REQUEST = 'DELETE_REGION_REQUEST';
export const DELETE_REGION_SUCCESS = 'DELETE_REGION_SUCCESS';
export const DELETE_REGION_FAILURE = 'DELETE_REGION_FAILURE';

export const RESTORE_REGION_REQUEST = 'RESTORE_REGION_REQUEST';
export const RESTORE_REGION_SUCCESS = 'RESTORE_REGION_SUCCESS';
export const RESTORE_REGION_FAILURE = 'RESTORE_REGION_FAILURE';

// Fetch Regions
export const fetchRegions =
    (page = 1, perPage = 50) =>
    async (dispatch) => {
        dispatch({ type: FETCH_REGIONS_REQUEST });
        try {
            const response = await fetchApi(`/regions?page=${page}&perPage=${perPage}&sortDir=desc&sortBy=id`);
            dispatch({ type: FETCH_REGIONS_SUCCESS, payload: response });
            console.log(response.data.list);
        } catch (error) {
            dispatch({ type: FETCH_REGIONS_FAILURE, payload: error.message });
        }
    };

// Create Region
export const createRegion = (regionData) => async (dispatch) => {
    dispatch({ type: CREATE_REGION_REQUEST });
    try {
        const data = await fetchApi('/regions', {
            method: 'POST',
            body: JSON.stringify(regionData)
        });
        dispatch({ type: CREATE_REGION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CREATE_REGION_FAILURE, payload: error.message });
    }
};

// Fetch Region by ID
export const fetchRegionById = (regionId) => async (dispatch) => {
    dispatch({ type: FETCH_REGION_REQUEST });
    try {
        const data = await fetchApi(`/regions/${regionId}`);
        dispatch({ type: FETCH_REGION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_REGION_FAILURE, payload: error.message });
    }
};

// Update Region
export const updateRegion = (regionId, regionData) => async (dispatch) => {
    dispatch({ type: UPDATE_REGION_REQUEST });
    try {
        const data = await fetchApi(`/regions/${regionId}`, {
            method: 'PUT',
            body: JSON.stringify(regionData)
        });
        dispatch({ type: UPDATE_REGION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_REGION_FAILURE, payload: error.message });
    }
};

// Delete Region
export const deleteRegion = (regionId) => async (dispatch) => {
    dispatch({ type: DELETE_REGION_REQUEST });
    try {
        await fetchApi(`/regions/${regionId}`, { method: 'DELETE' });
        dispatch({ type: DELETE_REGION_SUCCESS, payload: regionId });
    } catch (error) {
        dispatch({ type: DELETE_REGION_FAILURE, payload: error.message });
    }
};

// Restore Region
export const restoreRegion = (regionId) => async (dispatch) => {
    dispatch({ type: RESTORE_REGION_REQUEST });
    try {
        const data = await fetchApi(`/regions/${regionId}/restore`, { method: 'PATCH' });
        dispatch({ type: RESTORE_REGION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: RESTORE_REGION_FAILURE, payload: error.message });
    }
};
