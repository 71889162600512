import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
    Box,
    Grid,
    Typography,
    Avatar,
    TextField,
    FormControl,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    InputLabel,
    InputAdornment,
    IconButton,
    CardContent
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { createEmployee } from 'store/employeeActions';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import StatusModal from 'ui-component/extended/StatusModal';
import employeeReducer from 'store/employeeReducer';
import { useNavigate } from 'react-router';

const EmployeeCreate = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        position: 'developer',
        department: '',
        socialSecurityNumber: '',
        gender: '',
        siteId: '', // The value for the select field
        password: '',
        avatar: {
            base64: ''
        }
    });
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    // Sample sites to choose from
    const siteOptions = [
        { id: 1, name: 'Site A' },
        { id: 2, name: 'Site B' },
        { id: 3, name: 'Site C' }
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, avatar: { base64: reader.result.split(',')[1] } });
            };
            reader.readAsDataURL(file);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'Nom est requis';
        if (!formData.lastName) newErrors.lastName = 'Prénom est requis';
        if (!formData.email) newErrors.email = 'Email est requis';
        if (!formData.phone) newErrors.phone = 'N° Téléphone est requis';
        if (!formData.socialSecurityNumber) newErrors.socialSecurityNumber = 'Numéro de sécurité sociale est requis';
        if (!formData.gender) newErrors.gender = 'Genre est requis';
        if (!formData.siteId) newErrors.siteId = 'ID du site est requis';
        if (!formData.password) newErrors.password = 'Mot de passe est requis';
        if (!formData.avatar.base64) newErrors.avatar = "Image requise pour l'avatar";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            await dispatch(createEmployee(formData));
            // Handle success
        } catch (error) {
            console.error('Error creating employee:', error);
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
            }
        } finally {
            //     setLoading(false);
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [openModal, setOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const employeeDetail = useSelector((state) => state.employee);
    const { employee, loading, error, success } = employeeDetail.employeeCreate;
    const navigate = useNavigate();
    useEffect(() => {
        if (success) {
            setModalTitle('Succès');
            setModalContent('Creation réussie !');
            setOpenModal(true);
            navigate('/gestion-des-employes/employes');
        } else if (error) {
            setModalTitle('Erreur');
            setModalContent(`Erreur: ${error}`);
            setOpenModal(true);
        }
    }, [success, error]);

    const useStyles = makeStyles((theme) => ({
        textField: {
            height: '40px',
            maxWidth: '300px',
            '& .MuiInputBase-input': {
                height: '40px',
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiInput-underline:before': {
                borderBottom: 'none'
            }
        },
        label: {
            paddingLeft: '10px',
            fontWeight: '600'
        },
        submitButton: {
            marginTop: theme.spacing(2),
            backgroundColor: '#1F41BB',
            height: '50px'
        }
    }));

    const classes = useStyles();

    return (
        <MainCard
            title="Creation des Employee"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1,
                padding: 2
            }}
        >
            <Grid container spacing={gridSpacing}>
                {/* Avatar Section */}
                <Grid item xs={12} sm={4} md={3}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <FormControl>
                            <Avatar
                                src={
                                    formData.avatar.base64
                                        ? `data:image/png;base64,${formData.avatar.base64}`
                                        : 'https://via.placeholder.com/150'
                                }
                                alt={formData.firstName || 'Avatar'}
                                sx={{ width: 130, height: 130, mb: 2, cursor: 'pointer' }}
                                onClick={() => document.getElementById('avatar-upload').click()}
                            />
                        </FormControl>
                        <input type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} id="avatar-upload" />
                        <Typography
                            gutterBottom
                            variant="h4"
                            sx={{ fontWeight: '600', color: theme.palette.primary.mainContent, fontSize: '14px', mt: 2 }}
                        >
                            {formData.firstName} {formData.lastName}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ color: theme.palette.grey[700], fontWeight: '600', width: '100%', textAlign: 'center' }}
                        >
                            {formData.role}
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.grey[700], width: '100%', textAlign: 'center' }}>
                            {formData.email}
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.grey[700], width: '100%', textAlign: 'center' }}>
                            {formData.phone}
                        </Typography>
                    </CardContent>
                </Grid>

                {/* Form Section */}
                <Grid item xs={12} sm={8} md={9}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* First Name */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Nom
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        placeholder="Entrez le nom d'employée"
                                        variant="standard"
                                        className={classes.textField}
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName}
                                    />
                                </Box>
                            </Grid>

                            {/* Last Name */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Prénom
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        placeholder="Entrez le prénom d'employée"
                                        variant="standard"
                                        className={classes.textField}
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName}
                                    />
                                </Box>
                            </Grid>

                            {/* Email */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Email
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        placeholder="Entrez l'email"
                                        variant="standard"
                                        className={classes.textField}
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </Box>
                            </Grid>

                            {/* Phone */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        N° Téléphone
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        placeholder="Entrez le numéro de téléphone"
                                        variant="standard"
                                        className={classes.textField}
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                    />
                                </Box>
                            </Grid>

                            {/* Social Security Number */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Numéro de Sécurité Sociale
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        placeholder="Entrez le numéro de sécurité sociale"
                                        variant="standard"
                                        className={classes.textField}
                                        name="socialSecurityNumber"
                                        value={formData.socialSecurityNumber}
                                        onChange={handleInputChange}
                                        error={!!errors.socialSecurityNumber}
                                        helperText={errors.socialSecurityNumber}
                                    />
                                </Box>
                            </Grid>

                            {/* Password */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Mot de Passe
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Entrez un mot de passe"
                                        variant="standard"
                                        className={classes.textField}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        error={!!errors.password}
                                        helperText={errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/* Gender */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Genre
                                    </Typography>
                                    <FormControl fullWidth margin="normal">
                                        <Select
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleInputChange}
                                            variant="standard"
                                            className={classes.textField}
                                            error={!!errors.gender}
                                        >
                                            <MenuItem value="man">Masculin</MenuItem>
                                            <MenuItem value="woman">Féminin</MenuItem>
                                        </Select>
                                        {errors.gender && (
                                            <Typography variant="caption" color="error">
                                                {errors.gender}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Box>
                            </Grid>

                            {/* Site */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Site
                                    </Typography>
                                    <FormControl fullWidth margin="normal">
                                        <Select
                                            name="siteId"
                                            value={formData.siteId}
                                            onChange={handleInputChange}
                                            variant="standard"
                                            className={classes.textField}
                                            error={!!errors.siteId}
                                        >
                                            {siteOptions.map((site) => (
                                                <MenuItem key={site.id} value={site.id}>
                                                    {site.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.siteId && (
                                            <Typography variant="caption" color="error">
                                                {errors.siteId}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Box>
                            </Grid>

                            {/* Department */}
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="body1" className={classes.label}>
                                        Département
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        placeholder="Entrez le département"
                                        variant="standard"
                                        className={classes.textField}
                                        name="department"
                                        value={formData.department}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                            </Grid>

                            {/* Submit Button */}
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    className={classes.submitButton}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Créer un employé'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            <StatusModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                title={modalTitle}
                content={modalContent}
                icon={success ? 'success' : 'error'}
            />
        </MainCard>
    );
};

export default EmployeeCreate;
