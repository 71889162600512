/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Skeleton,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchNotifications, markAllNotificationsAsRead, markNotificationAsRead } from 'store/notificationActions';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MessageIcon from '@mui/icons-material/Message';

const ListItemWrapper = styled('div')(({ theme, isNew }) => ({
    cursor: 'pointer',
    padding: 16,
    background: isNew ? theme.palette.primary.light : 'transparent',
    '&:hover': {
        background: theme.palette.primary.light
    }
}));

const NotificationList = ({ filterType }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { notifications, loading, pagination } = useSelector((state) => state.notification);

    useEffect(() => {
        dispatch(fetchNotifications());
    }, [dispatch]);

    useEffect(() => {
        if (pagination.currentPage >= pagination.lastPage) {
            dispatch(markAllNotificationsAsRead());
        }
    }, [pagination.currentPage, pagination.lastPage]);

    const handleMarkAsRead = (notificationId) => {
        dispatch(markNotificationAsRead(notificationId));
    };
    const handleNavigate = (type, entityId, notificationId) => {
        handleMarkAsRead(notificationId);

        switch (type) {
            case 'hazard':
                navigate(`/gestion-des-hazards/declarations/${entityId}`);
                break;
            case 'action':
                navigate(`/gestion-des-acp/details/${entityId}`);
                break;
            case 'message':
                navigate(`/gestion-des-hazards/declarations/${entityId}`);
                break;
            default:
                break;
        }
    };

    const getIcon = (type) => {
        switch (type) {
            case 'hazard':
                return <WarningIcon color="error" />;
            case 'action':
                return <CheckCircleIcon color="success" />;
            case 'message':
                return <MessageIcon color="primary" />;
            default:
                return null;
        }
    };

    const fetchMoreData = () => {
        if (pagination.currentPage < pagination.lastPage) {
            dispatch(fetchNotifications(pagination.currentPage + 1));
        }
    };

    return (
        <InfiniteScroll
            dataLength={notifications.length}
            next={fetchMoreData}
            hasMore={pagination.currentPage < pagination.lastPage}
            loader={<Skeleton variant="rectangular" width="100%" height={50} />}
            endMessage={
                <Typography variant="body2" align="center" color="textSecondary">
                    Vous avez vu toutes les notifications.
                </Typography>
            }
        >
            <List>
                {notifications
                    .filter((notification) => {
                        if (filterType === 'all') return true;
                        if (filterType === 'new' && !notification.readAt) return true;
                        if (filterType === 'unread' && notification.readAt) return true;
                        return notification.content.subject.type === filterType;
                    })
                    .sort((a, b) => (a.readAt ? 1 : -1)) // Unread first
                    .map((notification) => (
                        <React.Fragment key={notification.id}>
                            <ListItemWrapper
                                isNew={!notification.readAt}
                                onClick={() =>
                                    handleNavigate(
                                        notification.content.subject.type,
                                        notification.content.subject.entityId,
                                        notification.id
                                    )
                                }
                            >
                                <ListItem alignItems="center">
                                    <ListItemAvatar>{getIcon(notification.content.subject.type)}</ListItemAvatar>
                                    <ListItemText
                                        sx={{ fontWeight: '700' }}
                                        primary={
                                            notification.content.subject.type === 'hazard'
                                                ? 'Déclaration'
                                                : notification.content.subject.type === 'action'
                                                ? 'Action'
                                                : 'Message'
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Grid container justifyContent="flex-end">
                                            <Grid item xs={12}>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    {notification.readAt ? 'Déjà vu' : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Grid container direction="column" className="list-container">
                                    <Grid item xs={12} sx={{ p: 1 }}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                fontWeight: '700',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'normal',
                                                display: 'block'
                                            }}
                                        >
                                            {notification.content.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            {!notification.readAt && (
                                                <Grid item>
                                                    <Chip label="Nouveau" color="primary" />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ListItemWrapper>
                            <Divider />
                        </React.Fragment>
                    ))}
            </List>
        </InfiniteScroll>
    );
};

export default NotificationList;
