import React, { useState, useEffect } from 'react';
import { Button, TextField, Autocomplete, Box, Grid, CircularProgress, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import wilaya from 'utils/wilaya';
import AlgeriaMapWithModal from 'ui-component/AlgeriaMap';
import MainCard from 'ui-component/cards/MainCard';
import { fetchAdmins } from 'store/adminActions';
import SubCard from 'ui-component/cards/SubCard';
import { createRegion } from 'store/regionsActions';

const CreateRegion = () => {
    const dispatch = useDispatch();
    const { adminList } = useSelector((state) => state.admin);
    const { loading, admins } = adminList;
    const regions = useSelector((state) => state.regions);
    const regionCreate = regions.regionCreate;
    const { loading: creating, success, error } = regionCreate;

    const [regionName, setRegionName] = useState('');
    const [selectedWilayas, setSelectedWilayas] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState(null);

    useEffect(() => {
        dispatch(fetchAdmins(1, 80)).catch((error) => console.error('Failed to fetch admins:', error));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setRegionName('');
            setSelectedWilayas([]);
            setSelectedAdmin(null);
        }
    }, [success]);

    const handleWilayaChange = (event, value) => {
        setSelectedWilayas(value || []);
    };

    const handleWilayaClick = (wilayaCode) => {
        setSelectedWilayas((prevSelected) => {
            const isAlreadySelected = prevSelected.some((w) => w.code === wilayaCode);
            if (!isAlreadySelected) {
                return [...prevSelected, { code: wilayaCode, name: wilaya[wilayaCode].name }];
            }
            return prevSelected.filter((w) => w.code !== wilayaCode);
        });
    };

    const handleSubmit = () => {
        // Extract province IDs
        const provinceIds = selectedWilayas.map((w) => wilaya[w.code]?.code).filter(Boolean);

        const regionData = {
            name: regionName,
            type: 'local',
            provinceIds
        };

        dispatch(createRegion(regionData));
    };

    const wilayaOptions = Object.keys(wilaya).map((key) => ({ code: key, name: wilaya[key].name }));

    return (
        <MainCard title="Création d'une région">
            <Grid container spacing={2} padding={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ padding: 2, borderRadius: 2 }}>
                        <TextField
                            label="Nom de Région"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={regionName}
                            onChange={(e) => setRegionName(e.target.value)}
                        />
                        <Autocomplete
                            multiple
                            options={wilayaOptions}
                            getOptionLabel={(option) => option?.name}
                            value={selectedWilayas}
                            onChange={handleWilayaChange}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            renderInput={(params) =>
                                loading ? (
                                    <Skeleton variant="text" width={200} />
                                ) : (
                                    <TextField {...params} label="Sélectionner les Wilayas" variant="outlined" placeholder="Wilayas" />
                                )
                            }
                            sx={{ mb: 2 }}
                        />
                        <Autocomplete
                            options={admins?.data?.list || []}
                            getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
                            value={selectedAdmin}
                            onChange={(event, value) => setSelectedAdmin(value)}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            renderInput={(params) =>
                                loading ? (
                                    <Skeleton variant="text" width={200} />
                                ) : (
                                    <TextField {...params} label="Sélectionner un responsable" variant="filled" placeholder="Responsable" />
                                )
                            }
                            sx={{ mb: 2 }}
                            disableClearable
                        />
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={creating}>
                            {creating ? <CircularProgress size={24} /> : 'Sauvegarder la Région'}
                        </Button>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height="100%" />
                    ) : (
                        <SubCard>
                            <AlgeriaMapWithModal selectedWilayas={selectedWilayas} onWilayaClick={handleWilayaClick} />
                        </SubCard>
                    )}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default CreateRegion;
