// assets
import { ReactComponent as IconTheme } from '../assets/images/icons/theme.svg';
import { ReactComponent as IconGestionAudits } from '../assets/images/icons/gestion_region_active.svg';
import { ReactComponent as IconGestionAuditsInctive } from '../assets/images/icons/gestion_region_inactive.svg';

// constant
const icons = {
    IconTheme,
    IconGestionAudits,
    IconGestionAuditsInctive
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const autre = {
    id: 'autre',
    title: 'Autre',
    caption: 'Autre Fonctionalites',
    type: 'group',
    permissions: ['configure_platform'],
    children: [
        {
            id: 'theme',
            title: 'Thème',
            type: 'item',
            icon: icons.IconTheme,
            iconInactive: icons.IconTheme,
            url: '/dashboard/default',
            breadcrumbs: false,
            permissions: ['configure_platform']
        },
        {
            id: 'audits',
            title: 'Audits',
            type: 'item',
            icon: icons.IconGestionAudits,
            iconInactive: icons.IconGestionAuditsInctive,
            url: '/audits',
            breadcrumbs: false,
            permissions: ['index_audits']
        }
    ]
};

export default autre;
