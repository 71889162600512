/* eslint-disable import/prefer-default-export */
import fetchApi from './api';

export const FETCH_ROLES_PERMISSIONS_REQUEST = 'FETCH_ROLES_PERMISSIONS_REQUEST';
export const FETCH_ROLES_PERMISSIONS_SUCCESS = 'FETCH_ROLES_PERMISSIONS_SUCCESS';
export const FETCH_ROLES_PERMISSIONS_FAILURE = 'FETCH_ROLES_PERMISSIONS_FAILURE';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const fetchRolesPermissions = () => async (dispatch) => {
    dispatch({ type: FETCH_ROLES_PERMISSIONS_REQUEST });

    try {
        const response = await fetchApi('/roles');
        const { data } = response;

        dispatch({
            type: FETCH_ROLES_PERMISSIONS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: FETCH_ROLES_PERMISSIONS_FAILURE,
            payload: error.message
        });
    }
};

// Create a new role
export const createRole = (roleData) => async (dispatch) => {
    dispatch({ type: CREATE_ROLE_REQUEST });

    try {
        const response = await fetchApi('/roles', { method: 'POST', body: JSON.stringify(roleData) });
        const { data } = response;

        dispatch({
            type: CREATE_ROLE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_ROLE_FAILURE,
            payload: error.message
        });
    }
};

// Update an existing role
export const updateRole = (roleId, roleData) => async (dispatch) => {
    dispatch({ type: UPDATE_ROLE_REQUEST });

    try {
        const response = await fetchApi(`/roles/${roleId}`, { method: 'PUT', body: JSON.stringify(roleData) });
        const { data } = response;

        dispatch({
            type: UPDATE_ROLE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ROLE_FAILURE,
            payload: error.message
        });
    }
};

// Delete an existing role
export const deleteRole = (roleId) => async (dispatch) => {
    dispatch({ type: DELETE_ROLE_REQUEST });

    try {
        await fetchApi.delete(`/roles/${roleId}`);

        dispatch({
            type: DELETE_ROLE_SUCCESS,
            payload: roleId
        });
    } catch (error) {
        dispatch({
            type: DELETE_ROLE_FAILURE,
            payload: error.message
        });
    }
};
