import {
    FETCH_ANALYSES_STATS_FAILURE,
    FETCH_ANALYSES_STATS_REQUEST,
    FETCH_ANALYSES_STATS_SUCCESS,
    FETCH_HEADQUARTER_STATS_FAILURE,
    FETCH_HEADQUARTER_STATS_REQUEST,
    FETCH_HEADQUARTER_STATS_SUCCESS,
    FETCH_PARTICIPATIONS_STATS_FAILURE,
    FETCH_PARTICIPATIONS_STATS_REQUEST,
    FETCH_PARTICIPATIONS_STATS_SUCCESS,
    FETCH_REGIONS_STATS_FAILURE,
    FETCH_REGIONS_STATS_REQUEST,
    FETCH_REGIONS_STATS_SUCCESS,
    FETCH_SITE_STAFF_STATS_FAILURE,
    FETCH_SITE_STAFF_STATS_REQUEST,
    FETCH_SITE_STAFF_STATS_SUCCESS,
    FETCH_SITES_STATS_FAILURE,
    FETCH_SITES_STATS_REQUEST,
    FETCH_SITES_STATS_SUCCESS
} from './statsActions';

const initialState = {
    sites: {
        data: [],
        loading: false,
        error: null
    },
    regions: {
        data: [],
        loading: false,
        error: null
    },
    headquarter: {
        data: {},
        loading: false,
        error: null
    },
    analyses: {
        data: [],
        loading: false,
        error: null
    },
    participations: {
        data: [],
        loading: false,
        error: null
    },
    siteStaff: {
        data: [],
        loading: false,
        error: null
    }
};

const analyticsStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SITES_STATS_REQUEST:
            return { ...state, sites: { ...state.sites, loading: true, error: null } };
        case FETCH_SITES_STATS_SUCCESS:
            return { ...state, sites: { loading: false, data: action.payload } };
        case FETCH_SITES_STATS_FAILURE:
            return { ...state, sites: { loading: false, error: action.payload } };

        case FETCH_REGIONS_STATS_REQUEST:
            return { ...state, regions: { ...state.regions, loading: true, error: null } };
        case FETCH_REGIONS_STATS_SUCCESS:
            return { ...state, regions: { loading: false, data: action.payload } };
        case FETCH_REGIONS_STATS_FAILURE:
            return { ...state, regions: { loading: false, error: action.payload } };

        case FETCH_HEADQUARTER_STATS_REQUEST:
            return { ...state, headquarter: { ...state.headquarter, loading: true, error: null } };
        case FETCH_HEADQUARTER_STATS_SUCCESS:
            return { ...state, headquarter: { loading: false, data: action.payload } };
        case FETCH_HEADQUARTER_STATS_FAILURE:
            return { ...state, headquarter: { loading: false, error: action.payload } };

        case FETCH_ANALYSES_STATS_REQUEST:
            return { ...state, analyses: { ...state.analyses, loading: true, error: null } };
        case FETCH_ANALYSES_STATS_SUCCESS:
            return { ...state, analyses: { loading: false, data: action.payload } };
        case FETCH_ANALYSES_STATS_FAILURE:
            return { ...state, analyses: { loading: false, error: action.payload } };

        case FETCH_PARTICIPATIONS_STATS_REQUEST:
            return { ...state, participations: { ...state.participations, loading: true, error: null } };
        case FETCH_PARTICIPATIONS_STATS_SUCCESS:
            return { ...state, participations: { loading: false, data: action.payload } };
        case FETCH_PARTICIPATIONS_STATS_FAILURE:
            return { ...state, participations: { loading: false, error: action.payload } };

        case FETCH_SITE_STAFF_STATS_REQUEST:
            return { ...state, siteStaff: { ...state.siteStaff, loading: true, error: null } };
        case FETCH_SITE_STAFF_STATS_SUCCESS:
            return { ...state, siteStaff: { loading: false, data: action.payload } };
        case FETCH_SITE_STAFF_STATS_FAILURE:
            return { ...state, siteStaff: { loading: false, error: action.payload } };

        default:
            return state;
    }
};

export default analyticsStatsReducer;
