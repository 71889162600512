export const LOGIN_REQUEST = '@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@auth/LOGIN_FAILURE';

export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = '@auth/LOGOUT_FAILURE';

// Action creators
export const loginRequest = (credentials) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
        const response = await fetch('https://preprod2.sensinglabo.com/api/auth/web-login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });

        const data = await response.json();

        if (response.ok && data.status === 'success') {
            await localStorage.setItem('authToken', data.data.token);
            await localStorage.setItem('user', data.data);
            dispatch({ type: LOGIN_SUCCESS, payload: data.data });
            return data; // Return response for further use
        }
        dispatch({ type: LOGIN_FAILURE, payload: data.message });
        return data; // Return response for further use
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error.message });
        throw error; // Re-throw error for handling in component
    }
};

export const loginSuccess = (userData) => ({
    type: LOGIN_SUCCESS,
    payload: userData
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error
});

export const logoutRequest = (token) => async (dispatch) => {
    dispatch({ type: LOGOUT_REQUEST });
    try {
        const response = await fetch('https://preprod2.sensinglabo.com/api/auth/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        });

        const data = await response.json();

        if (response.ok && data.status === 'success') {
            localStorage.removeItem('authToken');
            localStorage.removeItem('user');
            dispatch({ type: LOGOUT_SUCCESS });
            return data; // Return response for further use
        }
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        dispatch({ type: LOGOUT_FAILURE, payload: data.message });
        return data; // Return response for further use
    } catch (error) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        dispatch({ type: LOGOUT_FAILURE, payload: error.message });
        throw error; // Re-throw error for handling in component
    }
};

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS
});

export const logoutFailure = (error) => ({
    type: LOGOUT_FAILURE,
    payload: error
});
