import fetchApi from './api';
import FileSaver, { saveAs } from 'file-saver';

// Action Types
export const FETCH_HAZARDS_REQUEST = 'FETCH_HAZARDS_REQUEST';
export const FETCH_HAZARDS_SUCCESS = 'FETCH_HAZARDS_SUCCESS';
export const FETCH_HAZARDS_FAILURE = 'FETCH_HAZARDS_FAILURE';

export const FETCH_HAZARD_REQUEST = 'FETCH_HAZARD_REQUEST';
export const FETCH_HAZARD_SUCCESS = 'FETCH_HAZARD_SUCCESS';
export const FETCH_HAZARD_FAILURE = 'FETCH_HAZARD_FAILURE';

export const UPDATE_HAZARD_REQUEST = 'UPDATE_HAZARD_REQUEST';
export const UPDATE_HAZARD_SUCCESS = 'UPDATE_HAZARD_SUCCESS';
export const UPDATE_HAZARD_FAILURE = 'UPDATE_HAZARD_FAILURE';

export const PATCH_HAZARD_STATUS_REQUEST = 'PATCH_HAZARD_STATUS_REQUEST';
export const PATCH_HAZARD_STATUS_SUCCESS = 'PATCH_HAZARD_STATUS_SUCCESS';
export const PATCH_HAZARD_STATUS_FAILURE = 'PATCH_HAZARD_STATUS_FAILURE';

export const POST_COMMENT_REQUEST = 'POST_COMMENT_REQUEST';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_FAILURE = 'POST_COMMENT_FAILURE';

export const POST_MESSAGE_REQUEST = 'POST_MESSAGE_REQUEST';
export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';
export const POST_MESSAGE_FAILURE = 'POST_MESSAGE_FAILURE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const ARCHIVE_CATEGORY_REQUEST = 'ARCHIVE_CATEGORY_REQUEST';
export const ARCHIVE_CATEGORY_SUCCESS = 'ARCHIVE_CATEGORY_SUCCESS';
export const ARCHIVE_CATEGORY_FAILURE = 'ARCHIVE_CATEGORY_FAILURE';

export const RESTORE_CATEGORY_REQUEST = 'RESTORE_CATEGORY_REQUEST';
export const RESTORE_CATEGORY_SUCCESS = 'RESTORE_CATEGORY_SUCCESS';
export const RESTORE_CATEGORY_FAILURE = 'RESTORE_CATEGORY_FAILURE';

export const EXPORT_HAZARDS_REQUEST = 'EXPORT_HAZARDS_REQUEST';
export const EXPORT_HAZARDS_SUCCESS = 'EXPORT_HAZARDS_SUCCESS';
export const EXPORT_HAZARDS_FAILURE = 'EXPORT_HAZARDS_FAILURE';

// Action Creators
export const fetchHazardsRequest = () => ({
    type: FETCH_HAZARDS_REQUEST
});

export const fetchHazardsSuccess = (data) => ({
    type: FETCH_HAZARDS_SUCCESS,
    payload: data
});

export const fetchHazardsFailure = (error) => ({
    type: FETCH_HAZARDS_FAILURE,
    payload: error
});

export const fetchHazardRequest = () => ({
    type: FETCH_HAZARD_REQUEST
});

export const fetchHazardSuccess = (data) => ({
    type: FETCH_HAZARD_SUCCESS,
    payload: data
});

export const fetchHazardFailure = (error) => ({
    type: FETCH_HAZARD_FAILURE,
    payload: error
});

// Update Hazard
export const updateHazardRequest = () => ({
    type: UPDATE_HAZARD_REQUEST
});

export const updateHazardSuccess = (data) => ({
    type: UPDATE_HAZARD_SUCCESS,
    payload: data
});

export const updateHazardFailure = (error) => ({
    type: UPDATE_HAZARD_FAILURE,
    payload: error
});

// Patch Hazard Status
export const patchHazardStatusRequest = () => ({
    type: PATCH_HAZARD_STATUS_REQUEST
});

export const patchHazardStatusSuccess = (data) => ({
    type: PATCH_HAZARD_STATUS_SUCCESS,
    payload: data
});

export const patchHazardStatusFailure = (error) => ({
    type: PATCH_HAZARD_STATUS_FAILURE,
    payload: error
});

// Post Comment
export const postCommentRequest = () => ({
    type: POST_COMMENT_REQUEST
});

export const postCommentSuccess = (data) => ({
    type: POST_COMMENT_SUCCESS,
    payload: data
});

export const postCommentFailure = (error) => ({
    type: POST_COMMENT_FAILURE,
    payload: error
});

// Post Message
export const postMessageRequest = () => ({
    type: POST_MESSAGE_REQUEST
});

export const postMessageSuccess = (data) => ({
    type: POST_MESSAGE_SUCCESS,
    payload: data
});

export const postMessageFailure = (error) => ({
    type: POST_MESSAGE_FAILURE,
    payload: error
});

// Fetch Categories
export const fetchCategoriesRequest = () => ({
    type: FETCH_CATEGORIES_REQUEST
});

export const fetchCategoriesSuccess = (data) => ({
    type: FETCH_CATEGORIES_SUCCESS,
    payload: data
});

export const fetchCategoriesFailure = (error) => ({
    type: FETCH_CATEGORIES_FAILURE,
    payload: error
});

// Create Category
export const createCategoryRequest = () => ({ type: CREATE_CATEGORY_REQUEST });
export const createCategorySuccess = (data) => ({ type: CREATE_CATEGORY_SUCCESS, payload: data });
export const createCategoryFailure = (error) => ({ type: CREATE_CATEGORY_FAILURE, payload: error });

// Fetch Single Category
export const fetchCategoryRequest = () => ({ type: FETCH_CATEGORY_REQUEST });
export const fetchCategorySuccess = (data) => ({ type: FETCH_CATEGORY_SUCCESS, payload: data });
export const fetchCategoryFailure = (error) => ({ type: FETCH_CATEGORY_FAILURE, payload: error });

// Update Category
export const updateCategoryRequest = () => ({ type: UPDATE_CATEGORY_REQUEST });
export const updateCategorySuccess = (data) => ({ type: UPDATE_CATEGORY_SUCCESS, payload: data });
export const updateCategoryFailure = (error) => ({ type: UPDATE_CATEGORY_FAILURE, payload: error });

// Archive Category
export const archiveCategoryRequest = () => ({ type: ARCHIVE_CATEGORY_REQUEST });
export const archiveCategorySuccess = (data) => ({ type: ARCHIVE_CATEGORY_SUCCESS, payload: data });
export const archiveCategoryFailure = (error) => ({ type: ARCHIVE_CATEGORY_FAILURE, payload: error });

// Restore Category
export const restoreCategoryRequest = () => ({ type: RESTORE_CATEGORY_REQUEST });
export const restoreCategorySuccess = (data) => ({ type: RESTORE_CATEGORY_SUCCESS, payload: data });
export const restoreCategoryFailure = (error) => ({ type: RESTORE_CATEGORY_FAILURE, payload: error });

// Export hazards
// Restore Category
export const exportHazardsRequest = () => ({ type: EXPORT_HAZARDS_REQUEST });
export const exportHazardsSuccess = (data) => ({ type: EXPORT_HAZARDS_SUCCESS, payload: data });
export const exportHazardsFailure = (error) => ({ type: EXPORT_HAZARDS_FAILURE, payload: error });

// Async Action Creator (Thunk)
export const fetchHazards =
    (
        page = 1,
        perPage = 15,
        search = '',
        category = '',
        status = '',
        severity = '',
        site = '',
        type = '',
        anonymous = false,
        newHazard = false,
        minDate = '',
        maxDate = ''
    ) =>
    async (dispatch) => {
        dispatch({ type: FETCH_HAZARDS_REQUEST });

        try {
            // Build the query parameters based on provided filters
            const queryParams = new URLSearchParams({
                page,
                perPage,
                sortDir: 'desc', // Default sort direction
                sortBy: 'id' // Default sort field
            });

            // Add filters to query if they are provided
            if (search) queryParams.append('search', search);
            if (category) queryParams.append('category', category);
            if (status) queryParams.append('status', status);
            if (severity) queryParams.append('severity', severity);
            if (site) queryParams.append('site', site);
            if (type) queryParams.append('type', type);
            if (anonymous) queryParams.append('anonymous', anonymous);
            if (newHazard) queryParams.append('new', newHazard);
            if (minDate) queryParams.append('minDate', minDate);
            if (maxDate) queryParams.append('maxDate', maxDate);

            const queryString = `/hazards?${queryParams.toString()}`;

            // API call using the query string
            const response = await fetchApi(queryString);

            // Check if data is returned
            if (response.data.length === 0) {
                dispatch({
                    type: FETCH_HAZARDS_SUCCESS,
                    payload: { list: [], pagination: { currentPage: 1, totalPages: 1, totalItems: 0, perPage: 15 } }
                });
            } else {
                dispatch({
                    type: FETCH_HAZARDS_SUCCESS,
                    payload: {
                        list: response.data.list, // The hazard list from the API response
                        pagination: response.data.pagination, // Pagination details from the response
                        filters: {
                            page,
                            search,
                            category,
                            status,
                            severity,
                            site,
                            type,
                            anonymous,
                            newHazard,
                            minDate,
                            maxDate
                        }
                    }
                });
            }
        } catch (error) {
            dispatch({ type: FETCH_HAZARDS_FAILURE, payload: error.message });
        }
    };

// Fetch Single Hazard
export const fetchHazard = (hazardId) => async (dispatch) => {
    dispatch(fetchHazardRequest());

    try {
        const result = await fetchApi(`/hazards/${hazardId}`);
        dispatch(fetchHazardSuccess(result.data));
    } catch (error) {
        dispatch(fetchHazardFailure(error.message));
    }
};

// Update Hazard
export const updateHazard = (hazardId, hazardData) => async (dispatch) => {
    dispatch(updateHazardRequest());

    try {
        const result = await fetchApi(`/hazards/${hazardId}`, {
            method: 'PUT',
            body: JSON.stringify(hazardData)
        });
        console.log('result update hazard : ', result);
        dispatch(updateHazardSuccess(result.data));
    } catch (error) {
        dispatch(updateHazardFailure(error.message));
    }
};

// Patch Hazard Status
export const patchHazardStatus = (hazardId, status) => async (dispatch) => {
    dispatch(patchHazardStatusRequest());

    try {
        const result = await fetchApi(`/hazards/${hazardId}/status`, {
            method: 'PATCH',
            body: JSON.stringify({ status })
        });
        dispatch(patchHazardStatusSuccess(result.data));
    } catch (error) {
        dispatch(patchHazardStatusFailure(error.message));
    }
};

// Post Comment
export const postComment = (hazardId, commentMessage) => async (dispatch) => {
    dispatch(postCommentRequest());

    try {
        const result = await fetchApi(`/hazards/${hazardId}/comments`, {
            method: 'POST',
            body: JSON.stringify({ comment: commentMessage })
        });
        dispatch(postCommentSuccess(result.data));
    } catch (error) {
        dispatch(postCommentFailure(error.message));
    }
};

// Post Message
export const postMessage =
    (hazardId, messageContent, recipients = []) =>
    async (dispatch) => {
        dispatch({ type: POST_MESSAGE_REQUEST });

        try {
            // Construct the request body
            const body = JSON.stringify({
                message: messageContent,
                recipients // Include recipients only if provided
            });

            // Make the API call to send the message
            const result = await fetchApi(`/hazards/${hazardId}/conversation/messages`, {
                method: 'POST',
                body
            });

            // Dispatch the success action with the new message data
            dispatch({ type: POST_MESSAGE_SUCCESS, payload: result.data });
            console.log(result);
        } catch (error) {
            // Dispatch the failure action with the error message
            dispatch({ type: POST_MESSAGE_FAILURE, payload: error.message });
        }
    };

// Fetch Categories
export const fetchCategories = () => async (dispatch) => {
    await dispatch(fetchCategoriesRequest());

    try {
        const result = await fetchApi('/hazards/categories');
        await dispatch(fetchCategoriesSuccess(result.data));
    } catch (error) {
        await dispatch(fetchCategoriesFailure(error.message));
    }
};

// Create a New Hazard Category
export const createCategory = (categoryData) => async (dispatch) => {
    dispatch(createCategoryRequest());

    try {
        const result = await fetchApi('/hazards/categories', {
            method: 'POST',
            body: JSON.stringify(categoryData)
        });
        dispatch(createCategorySuccess(result.data));
    } catch (error) {
        dispatch(createCategoryFailure(error.message));
    }
};

// Fetch a Specific Hazard Category by ID
export const fetchCategory = (categoryId) => async (dispatch) => {
    dispatch(fetchCategoryRequest());

    try {
        const result = await fetchApi(`/hazards/categories/${categoryId}`);
        dispatch(fetchCategorySuccess(result.data));
    } catch (error) {
        dispatch(fetchCategoryFailure(error.message));
    }
};

// Update a Hazard Category by ID
export const updateCategory = (categoryId, categoryData) => async (dispatch) => {
    dispatch(updateCategoryRequest());

    try {
        const result = await fetchApi(`/hazards/categories/${categoryId}`, {
            method: 'PUT',
            body: JSON.stringify(categoryData)
        });
        dispatch(updateCategorySuccess(result.data));
    } catch (error) {
        dispatch(updateCategoryFailure(error.message));
    }
};

// Archive a Hazard Category by ID
export const archiveCategory = (categoryId) => async (dispatch) => {
    dispatch(archiveCategoryRequest());

    try {
        const result = await fetchApi(`/hazards/categories/${categoryId}`, {
            method: 'DELETE'
        });
        dispatch(archiveCategorySuccess(result.data));
    } catch (error) {
        dispatch(archiveCategoryFailure(error.message));
    }
};

// Restore an Archived Hazard Category
export const restoreCategory = (categoryId) => async (dispatch) => {
    dispatch(restoreCategoryRequest());

    try {
        const result = await fetchApi(`/hazards/categories/${categoryId}/restore`, {
            method: 'PATCH'
        });
        dispatch(restoreCategorySuccess(result.data));
    } catch (error) {
        dispatch(restoreCategoryFailure(error.message));
    }
};

export const exportHazards =
    (
        page = 1,
        perPage = 15,
        search = '',
        category = '',
        status = '',
        severity = '',
        site = '',
        type = '',
        anonymous = false,
        newHazard = false,
        minDate = '',
        maxDate = ''
    ) =>
    async (dispatch) => {
        dispatch({ type: EXPORT_HAZARDS_REQUEST });

        try {
            // Constructing query parameters
            const queryParams = new URLSearchParams({
                page,
                perPage,
                sortDir: 'desc',
                site,
                category,
                type,
                status,
                severity,
                anonymous,
                new: newHazard,
                minDate,
                maxDate
            });

            // Add the search parameter only if it is provided
            if (search) {
                queryParams.append('search', search);
            }

            // Construct the query string
            const queryString = `/hazards/export?${queryParams.toString()}`;

            // Fetching the binary data as Blob (bypassing throttling)
            const response = await fetchApi(
                queryString,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                },
                true
            );

            // Check if the response is okay
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Convert the response to a Blob
            const blob = new Blob([response], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            // Use FileSaver.js to trigger the download
            FileSaver.saveAs(blob, 'Liste_des_declarations.xlsx');
            console.log('blob : ', blob);

            // Optionally, dispatch the binary data to Redux if needed
            dispatch({ type: EXPORT_HAZARDS_SUCCESS, payload: response });
        } catch (error) {
            dispatch({ type: EXPORT_HAZARDS_FAILURE, payload: error.message });
        }
    };
