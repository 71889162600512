import {
    FETCH_ACTIONS_REQUEST,
    FETCH_ACTIONS_SUCCESS,
    FETCH_ACTIONS_FAILURE,
    CREATE_ACTION_PLAN_REQUEST,
    CREATE_ACTION_PLAN_SUCCESS,
    CREATE_ACTION_PLAN_FAILURE
} from './acpActions';

const initialState = {
    loading: false,
    actions: {
        list: [], // To store the list of actions
        pagination: {
            currentPage: 1,
            totalPages: 1,
            totalItems: 0,
            perPage: 15
        }, // Pagination data
        filters: {
            search: '', // Search term
            category: '', // Category filter
            status: '', // Status filter
            severity: '', // Severity filter
            site: '', // Site filter
            type: '', // Type filter
            anonymous: false, // Anonymous filter
            newAction: false, // New action filter
            minDate: '', // Minimum date filter
            maxDate: ''
        },
        hasMore: true
    },
    createdAction: null,
    error: null
};

// Reducer function
const actionsReducer = (state = initialState, action) => {
    switch (action.type) {
        // Fetch actions request
        case FETCH_ACTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        // Fetch actions success
        case FETCH_ACTIONS_SUCCESS: {
            const newPagination = action.payload?.pagination;
            const newActions = action.payload?.list;
            console.log(newPagination);
            return {
                ...state,
                loading: false,
                actions: {
                    ...state.actions,
                    list:
                        newPagination.currentPage === 1
                            ? newActions // Replace the list if it's the first page
                            : [...state.actions.list, ...newActions], // Otherwise, append the new actions
                    pagination: newPagination // Update pagination details
                },
                hasMore: newPagination.currentPage < newPagination.lastPage // Check if more pages are available
            };
        }

        // Fetch actions failure
        case FETCH_ACTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        // Create action plan request
        case CREATE_ACTION_PLAN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                createdAction: null
            };

        // Create action plan success
        case CREATE_ACTION_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                createdAction: action.payload, // Store the newly created action
                actions: {
                    ...state.actions,
                    list: [action.payload, ...state.actions.list] // Prepend the new action to the list
                },
                error: null
            };

        // Create action plan failure
        case CREATE_ACTION_PLAN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default actionsReducer;
