import { FETCH_AUDITS_FAILURE, FETCH_AUDITS_REQUEST, FETCH_AUDITS_SUCCESS } from './auditsActions';

const initialState = {
    audits: [],
    loading: false,
    error: null,
    page: 1,
    perPage: 15,
    totalPages: 0,
    totalItems: 0,
    search: '',
    minDate: null,
    maxDate: null,
    sortDir: 'desc',
    sortBy: 'activity',
    hasMore: true
};

const auditsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AUDITS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_AUDITS_SUCCESS:
            return {
                ...state,
                loading: false,
                // Reset audits if a new filter is applied (page 1), or append if scrolling
                audits: action.payload.reset ? action.payload.audits : [...state.audits, ...action.payload.audits],
                totalPages: action.payload.pagination.lastPage,
                totalItems: action.payload.pagination.total,
                page: action.payload.page,
                hasMore: action.payload.pagination.currentPage < action.payload.pagination.lastPage
            };
        case FETCH_AUDITS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default auditsReducer;
