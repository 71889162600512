import {
    FETCH_NOTIFICATIONS_REQUEST,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    MARK_NOTIFICATION_READ_REQUEST,
    MARK_NOTIFICATION_READ_SUCCESS,
    MARK_NOTIFICATION_READ_FAILURE,
    MARK_ALL_NOTIFICATIONS_READ_SUCCESS
} from './notificationActions';

const initialState = {
    notifications: [],
    loading: false,
    error: null,
    pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0,
        lastPage: 1
    }
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_REQUEST:
        case MARK_NOTIFICATION_READ_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: [...state.notifications, ...action.payload.list], // Append new notifications
                pagination: action.payload.pagination
            };
        case FETCH_NOTIFICATIONS_FAILURE:
        case MARK_NOTIFICATION_READ_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case MARK_NOTIFICATION_READ_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: state.notifications.map((notification) =>
                    notification.id === action.notificationId
                        ? { ...notification, readAt: Date.now() / 1000 } // Mark as read
                        : notification
                )
            };
        case MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: state.notifications.map((notification) => ({
                    ...notification,
                    readAt: Date.now() / 1000 // Mark all as read
                }))
            };
        default:
            return state;
    }
};

export default notificationReducer;
