import React, { useEffect, useState } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    InputAdornment,
    IconButton,
    Menu,
    MenuItem,
    Grid,
    MenuList,
    ClickAwayListener,
    Popper,
    ButtonGroup,
    Grow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import Avatar from 'ui-component/extended/Avatar';
import { useTheme } from '@emotion/react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    archiveEmployee,
    createEmployee,
    fetchEmployeeById,
    fetchHazardsByEmployeeId,
    restoreEmployee,
    updateEmployee
} from 'store/employeeActions';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useParams } from 'react-router';
import StatusModal from 'ui-component/extended/StatusModal';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme) => ({
    textField: {
        height: '40px',
        maxWidth: '300px',
        '& .MuiInputBase-input': {
            height: '40px',
            margin: 0,
            backgroundColor: theme.palette.background.paper,
            width: '100%',
            borderRadius: `${theme.customization?.borderRadius || 4}px`,
            color: theme.palette.text.dark,
            paddingLeft: '10px',
            fontWeight: '600'
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        },
        '& .MuiInputBase-input:hover:before': {
            borderBottom: 'none'
        },
        '& .MuiInputBase-input:after': {
            borderBottom: 'none'
        },
        '& .MuiInputBase-input:focus': {
            borderBottom: '1px solid blue'
        },
        '& .MuiInputBase-input:focus:before': {
            borderBottom: '2px solid blue'
        }
    },
    label: {
        paddingLeft: '10px'
    },
    submitButton: {
        marginTop: theme.spacing(2),
        backgroundColor: '#1F41BB',
        height: '50px'
    },
    tableContainer: {
        marginTop: theme.spacing(2)
    }
}));

const mockHazardData = [
    { id: 1, type: 'Chemical', description: 'Exposure to harmful chemicals', date: '2023-08-15' },
    { id: 2, type: 'Physical', description: 'Heavy lifting', date: '2023-09-10' }
];

const EmployeeDetail = (props) => {
    // const {
    //     employee,
    //     loading,
    //     error,
    //     fetchEmployeeById,
    //     updateEmployee,
    //     archiveEmployee,
    //     restoreEmployee,
    //     createEmployee
    // } = props;
    const { id } = useParams();
    const [tabValue, setTabValue] = useState(0);
    const classes = useStyles();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const [employeeEdit, setEmployeeEdit] = useState({
        firstName: '',
        lastName: '',
        job: '',
        department: '',
        site: '',
        email: '',
        phone: '',
        immatriculation: '',
        socialSecurityNumber: '',
        gender: '',
        siteId: ''
    });
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const employeeDetail = useSelector((state) => state.employee);
    const { employee, loading, error } = employeeDetail?.employeeDetails;
    const { hazards, loading: loadingHazards, error: errorHazards } = employeeDetail?.employeeHazards;
    const [avatarBase46, setAvatarBase64] = useState('');
    const { loading: loadingEdit, success: successEdit, error: errorEdit } = employeeDetail?.employeeUpdate;
    useEffect(() => {
        const employeeId = id;
        async function fetchData() {
            await dispatch(fetchEmployeeById(employeeId));
            await dispatch(fetchHazardsByEmployeeId(employeeId));
        }
        fetchData();
        console.log(employeeDetail, hazards);
    }, [id, dispatch]);

    const [openModal, setOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        if (employee) {
            setEmployeeEdit({
                profilePictureUrl: { url: employee?.profilePictureUrl || '', base64: '' },
                firstName: employee?.firstName || '',
                lastName: employee?.lastName || '',
                job: employee?.job || '',
                department: employee?.department || '',
                site: employee?.site || '',
                siteId: employee?.site.id || '',
                email: employee?.email || '',
                phone: employee?.phone || '',
                immatriculation: employee?.immatriculation || '',
                socialSecurityNumber: employee?.socialSecurityNumber || '',
                gender: employee?.gender || 'man'
                // password: null
            });
        }
        console.log(employee);
        setOpenModal(false);
    }, [employee]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleUpdate = () => {
        const siteId = typeof employeeEdit?.site === 'object' ? employeeEdit?.site?.id : employeeEdit?.site;

        const updatedData = {
            ...employeeEdit,
            avatar: { base64: employeeEdit?.profilePictureUrl?.base64 },
            siteId,
            gender: 'man'
        };

        if (password) {
            updatedData.password = password;
        }

        dispatch(updateEmployee(id, updatedData));
        setOpenModal(true);
    };

    useEffect(() => {
        if (successEdit) {
            setModalTitle('Succès');
            setModalContent('Mise à jour réussie !');
            setPassword('');
        } else if (errorEdit) {
            setModalTitle('Erreur');
            setModalContent(`Erreur: ${errorEdit}`);
        }
    }, [successEdit, errorEdit]);

    const handleArchive = () => {
        archiveEmployee(employeeEdit?.id);
    };

    const handleRestore = () => {
        restoreEmployee(employeeEdit?.id);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEmployeeEdit({ ...employeeEdit, profilePictureUrl: { base64: reader.result.split(',')[1], url: null } }); // Extract base64 part
            };
            reader.readAsDataURL(file);
        }
    };

    const [openActions, setOpenActions] = React.useState(false);
    const anchorActionsRef = React.useRef(null);

    const handleActionsToggle = () => {
        setOpenActions((prevOpen) => !prevOpen);
    };

    const handleActionsClick = (event) => {
        setOpenActions((prevOpen) => !prevOpen);
    };

    const handleActionsClose = (event) => {
        if (anchorActionsRef.current && anchorActionsRef.current.contains(event.target)) {
            return;
        }

        setOpenActions(false);
    };
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const validatePassword = () => {
        const newErrors = {};
        if (!password) newErrors.password = 'Le mot de passe est requis';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Handle change for text fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeEdit((prevEmployee) => ({
            ...prevEmployee,
            [name]: value
        }));
    };

    return (
        <MainCard
            title="Details"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1
            }}
        >
            <Box sx={{ display: 'flex', height: 'auto' }}>
                {/* Left: Tabs */}
                <Box sx={{ borderRight: 1, borderColor: 'divider', width: '25%' }}>
                    <Tabs
                        orientation="vertical"
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="Employee details tabs"
                        sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
                    >
                        <Tab label="Information générale de l’employé" />
                        <Tab label="Gestion de Mot de Passe" />
                        <Tab label="Suivi Historique des Hazards" />
                    </Tabs>
                </Box>

                {/* Right: Forms and Table */}
                <Box sx={{ flexGrow: 1 }}>
                    {tabValue === 0 && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
                            {/* Profile Picture and Basic Info Section */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'background.paper',
                                    padding: 2,
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1
                                }}
                            >
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="avatar-upload"
                                />
                                <Avatar
                                    src={
                                        employeeEdit?.profilePictureUrl?.url ||
                                        `data:image/png;base64,${employeeEdit?.profilePictureUrl?.base64}`
                                    }
                                    sx={{ width: 80, height: 80, marginRight: 2 }}
                                    onClick={() => document.getElementById('avatar-upload').click()}
                                />
                                <Box>
                                    <Typography variant="h3" sx={{ color: theme.palette.text.dark }}>{`${
                                        employeeEdit?.firstName || 'Sarpi'
                                    } ${employeeEdit?.lastName || 'Employee'}`}</Typography>

                                    <Typography variant="body1" color="textSecondary">
                                        {employeeEdit?.job || 'Sarpi Agent'}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Form Section */}
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    overflowY: 'auto',
                                    padding: 2
                                }}
                            >
                                <Box
                                    component="form"
                                    noValidate
                                    autoComplete="off"
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' },
                                        gap: 4
                                    }}
                                >
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            Nom
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="Entrez le nom d'employée"
                                            variant="standard"
                                            className={classes.textField}
                                            name="firstName"
                                            value={employeeEdit?.firstName || 'Sarpi'}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            Prénom
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="Entrez le prénom d'employée"
                                            variant="standard"
                                            className={classes.textField}
                                            name="lastName"
                                            value={employeeEdit?.lastName || 'Employée'}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            POSTE
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="POSTE"
                                            variant="standard"
                                            className={classes.textField}
                                            name="job"
                                            value={employeeEdit?.job || 'Agent'}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            Département
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="Département"
                                            variant="standard"
                                            className={classes.textField}
                                            name="department"
                                            value={employeeEdit?.department || ''}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            Site
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="Site"
                                            variant="standard"
                                            className={classes.textField}
                                            name="site"
                                            value={employeeEdit?.site?.name || ''}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            E-mail
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="E-mail"
                                            variant="standard"
                                            className={classes.textField}
                                            name="email"
                                            value={employeeEdit?.email || 'example@sarpi.dz'}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            N° Téléphone
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="N° Téléphone"
                                            variant="standard"
                                            className={classes.textField}
                                            name="phone"
                                            value={employeeEdit?.phone || '+213 123 ..'}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            Immatriculation
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="XXXXXX XX"
                                            variant="standard"
                                            className={classes.textField}
                                            name="immatriculation"
                                            value={employeeEdit?.immatriculation || ''}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            NSS
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            placeholder="XXXXXXXX"
                                            variant="standard"
                                            className={classes.textField}
                                            name="socialSecurityNumber"
                                            value={employeeEdit?.socialSecurityNumber || ''}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            {/* Submit Button */}
                            <Box
                                sx={{
                                    padding: 2,
                                    borderTop: 1,
                                    borderColor: 'divider',
                                    backgroundColor: 'background.paper'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    className={classes.submitButton}
                                    onClick={handleUpdate}
                                    disabled={loadingEdit}
                                >
                                    {loadingEdit ? 'Enregistrement...' : 'Enregistrer'}
                                </Button>
                                <ButtonGroup
                                    className={classes.submitButton}
                                    variant="contained"
                                    ref={anchorActionsRef}
                                    aria-label="Button group with a nested menu"
                                >
                                    <Button onClick={handleActionsClick}>Avancee</Button>
                                    <Button
                                        size="small"
                                        aria-controls={openActions ? 'split-button-menu' : undefined}
                                        aria-expanded={openActions ? 'true' : undefined}
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={handleActionsToggle}
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                                <Popper
                                    sx={{ zIndex: 1 }}
                                    open={openActions}
                                    anchorEl={anchorActionsRef.current}
                                    role={undefined}
                                    transition
                                    disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleActionsClose}>
                                                    <MenuList id="split-button-menu" autoFocusItem>
                                                        <MenuItem onClick={handleArchive} disabled={loading}>
                                                            Archiver
                                                        </MenuItem>
                                                        <MenuItem onClick={handleRestore} disabled={loading}>
                                                            Restaurer
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>
                        </Box>
                    )}

                    {tabValue === 1 && (
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                padding: 2
                            }}
                        >
                            <Box component="form" noValidate autoComplete="off">
                                <Box sx={{ display: 'grid', gap: 2 }}>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            Nouveau Mot de Passe
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            variant="standard"
                                            error={Boolean(errors.password)}
                                            helperText={errors.password}
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            onChange={handleChange}
                                            placeholder="Entrez votre nouveau mot de passe"
                                            className={classes.textField}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" className={classes.label}>
                                            Confirmer le Nouveau Mot de Passe
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            variant="standard"
                                            error={Boolean(errors.password)}
                                            helperText={errors.password}
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={handleChange}
                                            name="password"
                                            placeholder="Confirmez votre nouveau mot de passe"
                                            className={classes.textField}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Button
                                    variant="contained"
                                    disabled={loadingEdit}
                                    onClick={handleUpdate}
                                    color="primary"
                                    className={classes.submitButton}
                                >
                                    {loadingEdit ? 'Changement de mot de pass ..' : 'Changer le mot de passe'}
                                </Button>
                            </Box>
                        </Box>
                    )}

                    {tabValue === 2 && (
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                padding: 2
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '150px' }}>
                                <Typography variant="subtitle2">Résultat</Typography>
                                <Typography variant="subtitle1" sx={{ color: theme.palette.text.dark }}>
                                    {hazards.length} Hazards
                                </Typography>
                            </Box>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Reference</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Catégorie</TableCell>
                                            <TableCell>Sévérité</TableCell>
                                            <TableCell>Priorité</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Replace with real hazard data */}
                                        {hazards?.map((hazard) => (
                                            <TableRow key={hazard?.id}>
                                                <TableCell>{hazard?.reference}</TableCell>
                                                <TableCell>{hazard?.type?.name?.fr}</TableCell>
                                                <TableCell>{hazard?.category?.name?.fr}</TableCell>
                                                <TableCell>{hazard?.severity}</TableCell>
                                                <TableCell>{hazard?.priority}</TableCell>
                                                <TableCell>{hazard?.date}</TableCell>
                                                <TableCell>{hazard?.status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                    <StatusModal
                        open={openModal}
                        handleClose={() => setOpenModal(false)}
                        title={modalTitle}
                        content={modalContent}
                        icon={successEdit ? 'success' : 'error'}
                    />
                </Box>
            </Box>
        </MainCard>
    );
};

// Map state and dispatch to props
const mapStateToProps = (state) => ({
    employee: state.employeeEdit?.employeeDetails.employee,
    loading: state.employeeEdit?.employeeUpdate?.loading,
    error: state.employeeEdit?.employeeUpdate?.error
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchEmployeeById,
            updateEmployee,
            archiveEmployee,
            restoreEmployee,
            createEmployee
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetail);
