import React, { Suspense } from 'react';
import Slider from 'react-slick/lib/slider';
import { Skeleton, Grid } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import totalHazardsIcon from 'assets/images/icons/total_hazards.svg';
import hazardsEnCoursIcon from 'assets/images/icons/hazards_en_cours.png';
import hazardsCritiquesIcon from 'assets/images/icons/hazards_critiques.svg';
import hazardsResolusIcon from 'assets/images/icons/hazards_resolus.svg';
import { useSelector } from 'react-redux';

const LazyStatsCard = React.lazy(() => import('ui-component/stats-card-grid/StatsCard'));

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;

    const theme = useTheme();
    return (
        <ArrowRight
            role="button"
            tabIndex={0}
            className={className}
            onClick={onClick}
            style={{ ...style, display: 'block', color: theme.palette.primary.dark, right: '0px', zIndex: 1 }}
            onKeyPress={(e) => {
                if (e.key === 'ArrowRight' || e.key === ' ') {
                    onClick();
                }
            }}
        />
    );
};

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    const theme = useTheme();
    return (
        <ArrowLeft
            role="button"
            tabIndex={0}
            className={className}
            onClick={onClick}
            style={{ ...style, display: 'block', color: theme.palette.primary.dark, marginLeft: '20px', zIndex: 1 }}
            onKeyPress={(e) => {
                if (e.key === 'ArrowLeft' || e.key === ' ') {
                    onClick();
                }
            }}
        />
    );
};

const CustomSlider = ({ loading, cardsData }) => {
    const theme = useTheme();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const renderStatCard = (statData, icon) => (
        <Suspense fallback={<Skeleton variant="rectangular" width={200} height={138} sx={{ mx: 2 }} />}>
            <LazyStatsCard
                isLoading={loading}
                title={statData?.label}
                counter={statData?.value || 0}
                type={statData?.growth?.up ? 'avance' : 'lent'}
                percentage={statData?.growth?.value}
                svgIcon={icon}
                sx={{ margin: '0 10px' }}
            />
        </Suspense>
    );

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: leftDrawerOpened ? 4 : 5,
        slidesToScroll: 3,
        autoplaySpeed: 8000,
        autoplay: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false
                }
            }
        ]
    };

    return (
        <Slider {...sliderSettings}>
            {cardsData &&
                Object.keys(cardsData).map((key) => (
                    <Grid item lg={4} md={6} sm={6} xs={12} key={key}>
                        {renderStatCard(cardsData[key], cardsData[key].iconUrl)}
                    </Grid>
                ))}
        </Slider>
    );
};

export default CustomSlider;
