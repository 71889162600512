import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginRequest, logoutRequest } from 'store/authenticationActions';
import { fetchUser } from 'store/currentUserActions';
import Loader from 'ui-component/Loader';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { user } = useSelector((state) => state.authentication);
    const token = localStorage.getItem('authToken');
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            if (token) {
                try {
                    await dispatch(fetchUser());
                    setIsAuthenticated(true);
                    if (location.pathname === '/login' || location.pathname === 'password-reset') {
                        navigate('/');
                    } else {
                        navigate(location.pathname);
                    }
                } catch (error) {
                    localStorage.removeItem('authToken');
                    setIsAuthenticated(false);
                    navigate('/login');
                } finally {
                    setLoading(false);
                }
            } else {
                setIsAuthenticated(false);
                setLoading(false);
                // Only navigate to login if not already there
                if (location.pathname !== '/login') {
                    navigate('/login'); // Navigate to login if no token and not already on login
                }
            }
        };

        checkAuth();
    }, [dispatch, navigate, token]);

    const login = async (credentials) => {
        try {
            const response = await dispatch(loginRequest(credentials));
            if (response.status === 'success') {
                // localStorage.setItem('authToken', response.data.token);
                setIsAuthenticated(true);
                navigate('/'); // Redirect after successful login
            }
        } catch (error) {
            console.error('Login error:', error.message);
        }
    };

    const logout = async () => {
        try {
            await dispatch(logoutRequest(token));
            localStorage.removeItem('authToken');
            setIsAuthenticated(false);
            navigate('/login'); // Redirect after successful logout
        } catch (error) {
            console.error('Logout error:', error.message);
        }
    };

    if (loading) {
        return <Loader />; // Show loader while loading/auth checking
    }

    return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
