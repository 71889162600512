import fetchApi from './api';

export const FETCH_ADMINS_REQUEST = 'FETCH_ADMINS_REQUEST';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE';

export const fetchAdmins =
    (page = 1, perPage = 50) =>
    async (dispatch) => {
        dispatch({ type: FETCH_ADMINS_REQUEST });
        try {
            const response = await fetchApi(`/admins?page=${page}&perPage=${perPage}&sortDir=desc&sortBy=id`);
            dispatch({ type: FETCH_ADMINS_SUCCESS, payload: response });
        } catch (error) {
            dispatch({ type: FETCH_ADMINS_FAILURE, payload: error.message });
        }
    };

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';

export const createAdmin = (adminData) => async (dispatch) => {
    dispatch({ type: CREATE_ADMIN_REQUEST });
    try {
        const data = await fetchApi('/admins', {
            method: 'POST',
            body: JSON.stringify(adminData)
        });
        dispatch({ type: CREATE_ADMIN_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CREATE_ADMIN_FAILURE, payload: error.message });
    }
};

export const FETCH_ADMIN_REQUEST = 'FETCH_ADMIN_REQUEST';
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS';
export const FETCH_ADMIN_FAILURE = 'FETCH_ADMIN_FAILURE';

export const fetchAdminById = (adminId) => async (dispatch) => {
    dispatch({ type: FETCH_ADMIN_REQUEST });
    try {
        const data = await fetchApi(`/admins/${adminId}`);
        console.log(data.data);
        dispatch({ type: FETCH_ADMIN_SUCCESS, payload: data.data });
    } catch (error) {
        dispatch({ type: FETCH_ADMIN_FAILURE, payload: error.message });
    }
};

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';

export const updateAdmin = (adminId, adminData) => async (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_REQUEST });
    try {
        const data = await fetchApi(`/admins/${adminId}`, {
            method: 'PUT',
            body: JSON.stringify(adminData)
        });
        dispatch({ type: UPDATE_ADMIN_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_ADMIN_FAILURE, payload: error.message });
    }
};
