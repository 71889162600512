import { useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Box,
    Tabs,
    Tab,
    TextField,
    Button,
    Modal,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Avatar,
    Checkbox,
    ListItemText,
    Chip,
    CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { Send } from '@mui/icons-material';
import { ReactComponent as AnonymeIcon } from 'assets/images/icons/anonyme.svg';
import { fetchAdmins } from 'store/adminActions';
import logo from 'assets/images/sarpi-logo.jpeg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ReactComponent as LocalizationIcon } from 'assets/images/icons/localization.svg';
import { ReactComponent as CategoryIcon } from 'assets/images/icons/truck.svg';
import { ReactComponent as CloturedIcon } from 'assets/images/icons/cloture.svg';
import { ReactComponent as NotCloturedIcon } from 'assets/images/icons/notCloture.svg';
import { ReactComponent as CloturedNIcon } from 'assets/images/icons/clotureN.svg';
import { ReactComponent as OpenIcon } from 'assets/images/icons/open.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/images/icons/priority.svg';
import { ReactComponent as InProgressIcon } from 'assets/images/icons/cours.svg';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { useLocation } from 'react-router';
import { makeStyles, styled } from '@mui/styles';

const ActionBox = styled(Box)(({ theme }) => ({
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    backgroundColor: '#F9F9F9'
}));

const statusOptions = [
    { value: 'validated', label: 'Validé' },
    { value: 'in-progress', label: 'En Cours' },
    { value: 'resolved', label: 'Résolu' },
    { value: 'canceled', label: 'Annulé' }
];

const statusActionsOptions = [
    { value: 'open', label: 'Ouvert' },
    { value: 'in-progress', label: 'En Cours' },
    { value: 'completed', label: 'Complété' },
    { value: 'overdue', label: 'En retard' }
];

// eslint-disable-next-line arrow-body-style
const TabContent = ({ relatedHazard }) => {
    const getLabel = (value, options) => {
        const item = options.find((option) => option.value === value);
        return item ? item.label : 'Inconnu';
    };

    const theme = useTheme();
    const getStatusColor = (status) => {
        const colorMapping = {
            validated: theme.palette.info.main,
            'in-progress': theme.palette.warning.dark,
            resolved: theme.palette.success.main,
            canceled: theme.palette.error.dark
        };
        return colorMapping[status] || theme.palette.text.primary;
    };

    const getStatusActionsColor = (status) => {
        const colorMapping = {
            open: theme.palette.info.main,
            'in-progress': theme.palette.warning.dark,
            completed: theme.palette.success.dark,
            overdue: theme.palette.error.dark
        };
        return colorMapping[status] || theme.palette.text.primary;
    };

    const renderActionStatusIcon = (status) => {
        switch (status) {
            case 'completed':
                return <CloturedNIcon />;
            case 'closed':
                return <CloturedIcon />;
            case 'open':
                return <OpenIcon />;
            case 'in-progress':
                return <InProgressIcon />;
            default:
                return null;
        }
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', px: 1, width: '100%' }}>
            <Box
                sx={{
                    alignItems: 'start',
                    justifyContent: 'space-between'
                }}
            >
                <Box>
                    <img src={logo} alt="Sarpi" width={40} height={50} />
                </Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {relatedHazard?.reference}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: getStatusColor(relatedHazard?.status), fontStyle: 'italic' }}>
                    {getLabel(relatedHazard?.status, statusOptions)}
                </Typography>

                <Typography variant="subtitle1">
                    {!relatedHazard?.isAnonymous ? (
                        `${relatedHazard?.user?.firstName} ${relatedHazard?.user?.lastName}`
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AnonymeIcon style={{ marginRight: '8px' }} />
                            <Typography>Inconnu</Typography>
                        </Box>
                    )}
                </Typography>

                {/* Category and Site */}
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <CategoryIcon sx={{ fontSize: 'small' }} />
                    <Typography sx={{ ml: 1 }} variant="subtitle1">
                        {relatedHazard?.category?.name?.fr}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocalizationIcon sx={{ fontSize: 'small' }} />
                    <Typography sx={{ ml: 1 }} variant="subtitle1">
                        {relatedHazard?.site?.name}
                    </Typography>
                </Box>
            </Box>

            {/* Actions */}
            {relatedHazard?.actions && relatedHazard?.actions.length > 0 && (
                <Box sx={{ mt: 2, height: 400, overflow: 'scroll' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Actions
                    </Typography>
                    {relatedHazard?.actions.map((action, index) => (
                        <Timeline
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                    margin: 0
                                }
                            }}
                            position="right"
                        >
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot color={action.type.toLowerCase() === 'corrective' ? 'success' : 'warning'} />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography variant="subtitle1">
                                        {new Date(action.startedAt).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </Typography>

                                    <ActionBox key={index}>
                                        <Typography variant="subtitle1">
                                            {action.reference} - {action.type}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ color: getStatusActionsColor(action?.status), fontWeight: '500' }}
                                        >
                                            {renderActionStatusIcon(action?.status)} {getLabel(action?.status, statusActionsOptions)}
                                        </Typography>
                                        <Typography variant="subtitle1">Échéance : {action.dueDate}</Typography>
                                        <Typography variant="subtitle1">
                                            {action?.responsiblePersons[0]?.firstName} {action?.responsiblePersons[0]?.lastName}
                                        </Typography>
                                    </ActionBox>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    ))}
                </Box>
            )}
        </Box>
    );
};

const CreateAcp = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [assignedPeople, setAssignedPeople] = useState([]);
    const [acpDetails, setAcpDetails] = useState({
        reference: '',
        type: '',
        initialStatus: '',
        dueDate: '',
        description: ''
    });
    const [newPeople, setNewPeople] = useState([]);
    const dispatch = useDispatch();
    const acp = useSelector((state) => state?.acp?.currentAcp);
    const { adminList } = useSelector((state) => state.admin);
    const { loading, admins } = adminList;

    useEffect(() => {
        dispatch(fetchAdmins(1, 80)).catch((error) => console.error('Failed to fetch admins:', error));
    }, [dispatch]);

    useEffect(() => {
        if (acp) {
            setAcpDetails({
                reference: acp.reference || '',
                type: acp.type || '',
                initialStatus: acp.initialStatus || '',
                dueDate: acp.dueDate || '',
                description: acp.description || ''
            });
            setAssignedPeople(acp.assignedPeople || []);
        }
    }, [acp]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const location = useLocation();
    const relatedHazard = location.state?.relatedHazard || {};
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    useEffect(() => {
        dispatch(fetchAdmins(1, 80)).catch((error) => console.error('Failed to fetch admins:', error));
    }, [dispatch]);

    useEffect(() => {
        if (acp) {
            setAcpDetails({
                reference: acp.reference || '',
                type: acp.type || '',
                initialStatus: acp.initialStatus || '',
                dueDate: acp.dueDate || '',
                description: acp.description || ''
            });
            setAssignedPeople(acp.assignedPeople || []);
        }
    }, [acp]);

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setAcpDetails({
            ...acpDetails,
            [name]: value
        });
    };

    const handleAddPeople = () => {
        if (newPeople.length) {
            setAssignedPeople([...assignedPeople, ...admins?.data?.list.filter((admin) => newPeople.includes(admin?.id))]);
            setNewPeople([]);
            setOpenModal(false);
        }
    };

    const handleSelectChange = (event) => {
        setNewPeople(event.target.value);
    };
    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const loadMoreAdmins = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1);
            dispatch(fetchAdmins(page + 1, 80)).then((response) => {
                if (response.data.list.length === 0) {
                    setHasMore(false);
                }
            });
        }
    };

    const filteredAdmins = admins?.data?.list?.filter((admin) =>
        `${admin.firstName} ${admin.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDeleteAdmin = (adminId) => {
        setNewPeople((prevSelected) => prevSelected.filter((id) => id !== adminId));
    };

    const useStyles = makeStyles((theme) => ({
        textField: {
            height: '40px',
            maxWidth: '300px',
            '& .MuiInputBase-input': {
                height: '40px',
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiInput-underline:before': {
                borderBottom: 'none'
            }
        },
        label: {
            paddingLeft: '10px',
            fontWeight: '600'
        },
        submitButton: {
            marginTop: theme.spacing(2),
            backgroundColor: '#1F41BB',
            height: '50px'
        }
    }));

    const StyledTab = styled(Tab)(({ theme }) => ({
        borderRadius: '6.74px',
        opacity: 1,
        justifyContent: 'center',
        padding: theme.spacing(1),
        '&.Mui-selected': {
            border: '1.56px solid rgba(41, 98, 255, 1)'
            // backgroundColor: 'rgba(41, 98, 255, 0.05)'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)'
        },
        '& .MuiTabs-indicatorSpan': {
            maxHeight: 4,
            backgroundColor: 'transparent'
        },
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            width: 0,
            backgroundColor: 'transparent !important'
        }
    }));

    const classes = useStyles();

    return (
        <MainCard
            title="Creation de ACP"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ width: '30%' }}>
                            <StyledTab key={0} label={<TabContent relatedHazard={relatedHazard} />} />
                        </Box>

                        <Box sx={{ width: '70%', p: 2 }}>
                            <Typography variant="h4" gutterBottom>
                                Action création
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Type de l&apos;action</InputLabel>
                                <Select name="type" value={acpDetails.type} onChange={handleFieldChange} label="Type d'ACP">
                                    <MenuItem value="corrective">Corrective</MenuItem>
                                    <MenuItem value="preventive">Preventive</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Date d'échéance"
                                type="date"
                                name="dueDate"
                                value={acpDetails.dueDate}
                                onChange={handleFieldChange}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Description"
                                name="description"
                                value={acpDetails.description}
                                onChange={handleFieldChange}
                                multiline
                                rows={4}
                                fullWidth
                                margin="normal"
                            />

                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Personnes Assignées
                                </Typography>
                                {assignedPeople.map((person, index) => (
                                    <Chip
                                        key={index}
                                        avatar={<Avatar src={person.profilePictureUrl} />}
                                        label={`${person.firstName} ${person.lastName}`}
                                        sx={{ margin: '0 8px 8px 0' }}
                                    />
                                ))}
                            </Box>
                            {/* Multi-select for adding people */}
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Sélectionner des personnes</InputLabel>
                                <Select
                                    multiple
                                    value={newPeople}
                                    onChange={handleSelectChange}
                                    renderValue={(selected) =>
                                        selected
                                            .map((adminId) => {
                                                const admin = admins?.data?.list?.find((admin) => admin.id === adminId);
                                                return admin ? `${admin?.firstName} ${admin?.lastName}` : '';
                                            })
                                            .join(', ')
                                    }
                                >
                                    {/* Search bar inside the Select dropdown */}
                                    <MenuItem>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Rechercher par nom"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            fullWidth
                                        />
                                    </MenuItem>

                                    {/* Display filtered admins with infinite scroll */}
                                    {filteredAdmins?.map((admin) => (
                                        <MenuItem key={admin.id} value={admin?.id}>
                                            <Checkbox checked={newPeople?.indexOf(admin?.id) > -1} />
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle1">
                                                        {admin?.firstName} {admin?.lastName}
                                                    </Typography>
                                                }
                                                secondary={<Typography variant="subtitle2">{admin.role.name}</Typography>}
                                            />
                                        </MenuItem>
                                    ))}
                                    {hasMore && (
                                        <MenuItem onClick={loadMoreAdmins}>
                                            <CircularProgress size={24} />
                                            <Typography variant="body2">Chargement...</Typography>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="primary" onClick={handleAddPeople} disabled={loading}>
                                    {loading ? <CircularProgress size={24} /> : 'Ajouter des personnes'}
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default CreateAcp;
