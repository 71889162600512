import {
    CardContent,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { fetchSitesStats } from 'store/statsActions';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DynamicLineChart from 'ui-component/charts/DynamicLikeChart';
import TotalHazardsCard from '../DynamicCard';
import ProjectReportsSkeleton from 'ui-component/cards/Skeleton/ProjectsRapportsSkeleton';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

const ProjectRepports = () => {
    const dispatch = useDispatch();
    const { sites: sitesData } = useSelector((state) => state.AnalyticsStats);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const chartsContainerRef = useRef(null);

    useEffect(() => {
        dispatch(fetchSitesStats());
    }, [dispatch]);

    const hazardStats = [
        { name: 'Hazards Critiques', count: sitesData?.analyses?.criticalHazards, type: 'blue' },
        { name: 'Hazards Récurrents', count: sitesData?.analyses?.recurrentHazards, type: 'light' },
        { name: 'Temps de Réponse Moyen', count: sitesData?.analyses?.averageResponseTime, type: 'blue' },
        { name: 'Temps de Résolution Moyen', count: sitesData?.analyses?.averageResolutionTime, type: 'light' }
    ];
    const transformBarChartData = (data) => {
        const categories = data?.series.map((project) => project?.name) || [];
        const statusNames = data?.categories || [];
        const series = statusNames.map((status, index) => ({
            name: status,
            data: data?.series.map((project) => project.data[index])
        }));
        return { categories, series, title: data?.title };
    };

    const reportedHazardsBarChart = useMemo(() => transformBarChartData(sitesData?.data?.reportedHazardsBarChart), [sitesData]);
    const hazardsStatusesBarChart = useMemo(() => transformBarChartData(sitesData?.data?.hazardsStatusesBarChart), [sitesData]);
    const reportedHazardsLineChart = useMemo(
        () => sitesData?.data?.reportedHazardsLineChart || { categories: [], series: [] },
        [sitesData]
    );
    const resolvedHazardsLineChart = useMemo(
        () => sitesData?.data?.resolvedHazardsLineChart || { categories: [], series: [] },
        [sitesData]
    );

    if (sitesData.loading) {
        return <ProjectReportsSkeleton />;
    }
    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <SubCard>
                        <CardContent>
                            <Typography variant="h3">Des chiffres precis pour chaque projet</Typography>
                            <StyledTableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nom</TableCell>
                                            <TableCell>Abbréviation</TableCell>
                                            <TableCell>Désignation</TableCell>
                                            <TableCell>Total</TableCell>
                                            <TableCell>Ouvert</TableCell>
                                            <TableCell>Résolu</TableCell>
                                            <TableCell>Validé</TableCell>
                                            <TableCell>Progression</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sitesData?.data?.stats?.map((stat) => (
                                            <TableRow key={stat.id}>
                                                <TableCell>{stat.name}</TableCell>
                                                <TableCell>{stat.abbreviation}</TableCell>
                                                <TableCell>{stat.designation}</TableCell>
                                                <TableCell>{stat.total}</TableCell>
                                                <TableCell>{stat.open}</TableCell>
                                                <TableCell>{stat.resolved}</TableCell>
                                                <TableCell>{stat.validated}</TableCell>
                                                <TableCell>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={stat.total ? (stat.resolved / stat.total) * 100 : 0}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </StyledTableContainer>
                        </CardContent>
                    </SubCard>
                </Grid>
                <Grid item lg={9} md={9} xs={12}>
                    <SubCard>
                        <DynamicLineChart
                            isHorizontal
                            data={reportedHazardsBarChart}
                            xAxisTitle="Par projet"
                            yAxisTitle="Hazards"
                            type="bar"
                        />
                    </SubCard>
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                    <Grid container spacing={gridSpacing}>
                        {hazardStats.map((stat, index) => (
                            <Grid key={index} item xs={12}>
                                <TotalHazardsCard isLoading={false} count={stat.count} name={stat.name} type={stat.type} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SubCard style={{ height: '100%' }}>
                        <DynamicLineChart
                            isHorizontal
                            isStacked
                            data={hazardsStatusesBarChart}
                            xAxisTitle="Par projet"
                            yAxisTitle="Hazards"
                            type="bar"
                        />
                    </SubCard>
                </Grid>

                <Grid item xs={12}>
                    <SubCard style={{ height: '100%' }}>
                        <DynamicLineChart data={reportedHazardsLineChart} xAxisTitle="Par projet" yAxisTitle="Hazards" type="line" />
                    </SubCard>
                </Grid>

                <Grid item xs={12}>
                    <SubCard style={{ height: '100%' }}>
                        <DynamicLineChart data={resolvedHazardsLineChart} xAxisTitle="Par projet" yAxisTitle="Hazards" type="line" />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ProjectRepports;
