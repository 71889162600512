import fetchApi from './api';

export const FETCH_STATS_REQUEST = 'FETCH_STATS_REQUEST';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE';

export const fetchStats = () => async (dispatch) => {
    dispatch({ type: FETCH_STATS_REQUEST });
    try {
        const response = await fetchApi('/dashboard/stats');
        console.log(response.data);
        dispatch({
            type: FETCH_STATS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_STATS_FAILURE,
            payload: error.message
        });
    }
};
