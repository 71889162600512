import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Skeleton,
    FormControl,
    TextField,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tabs,
    Tab,
    Box,
    Button
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminById } from 'store/adminActions';

const AdminDetail = () => {
    const theme = useTheme();
    const { id } = useParams();
    const dispatch = useDispatch();
    const admin = useSelector((state) => state.admin.adminDetails);
    const { admin: adminData } = admin;

    // Local state for form data and edit mode
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: ''
    });
    const [tabValue, setTabValue] = useState(0);
    const [isEditing, setIsEditing] = useState(false); // Edit mode state

    useEffect(() => {
        dispatch(fetchAdminById(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (adminData) {
            setFormData({
                firstName: adminData.firstName || '',
                lastName: adminData.lastName || '',
                email: adminData.email || '',
                phone: adminData.phone || '',
                role: adminData.role || ''
            });
        }
    }, [adminData]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = () => {
        // Implement save logic here (e.g., dispatch an action to save changes)
        console.log('Save changes', formData);
        setIsEditing(false); // Exit edit mode after saving
    };

    const renderTabContent = () => {
        if (tabValue === 0) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                label="Nom"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                label="Prénom"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                label="N° Téléphone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            );
        }
        if (tabValue === 1) {
            return (
                <FormControl component="fieldset">
                    <FormLabel component="legend">Choisir un rôle</FormLabel>
                    <RadioGroup
                        name="role"
                        value={formData.role}
                        onChange={handleInputChange}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                        disabled={!isEditing}
                    >
                        <FormControlLabel value="region-manager" control={<Radio />} label="Gestionnaire de région" />
                        <FormControlLabel value="site-manager" control={<Radio />} label="Gestionnaire de site" />
                    </RadioGroup>
                </FormControl>
            );
        }
        return null;
    };

    return (
        <MainCard
            title="Admin Details"
            buttonTitle="Modifier"
            //     onButtonClick={() => setIsEditing(true)}
            buttonLink={`/gestion-des-admins/admins/edit/${id}`}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1,
                padding: 2
            }}
        >
            <Grid container spacing={gridSpacing}>
                {/* Card Section */}
                <Grid item xs={12} sm={4} md={3}>
                    <Card
                        sx={{
                            position: 'relative',
                            borderRadius: '8px',
                            border: '1px solid #ddd',
                            transition: 'transform 0.3s ease, border-color 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.03)',
                                borderColor: 'primary.main'
                            }
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                overflow: 'hidden'
                            }}
                        >
                            {adminData?.profilePictureUrl ? (
                                <img
                                    src={`${adminData?.profilePictureUrl}`}
                                    alt={`${adminData?.firstName} ${adminData?.lastName}`}
                                    style={{ width: 130, height: 130, borderRadius: '50%' }}
                                />
                            ) : (
                                <Skeleton variant="circular" width={130} height={130} />
                            )}
                            <Typography
                                gutterBottom
                                variant="h4"
                                sx={{ fontWeight: '600', color: theme.palette.primary.mainContent, fontSize: '14px', mt: 2 }}
                            >
                                {adminData?.firstName || <Skeleton width={120} />} {adminData?.lastName || <Skeleton width={120} />}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ color: theme.palette.grey[700], fontWeight: '600', width: '100%', textAlign: 'center' }}
                            >
                                {adminData?.role?.name || <Skeleton width={100} />}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[700], width: '100%', textAlign: 'center' }}>
                                {adminData?.email || <Skeleton width={150} />}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[700], width: '100%', textAlign: 'center' }}>
                                {adminData?.phone || <Skeleton width={150} />}
                            </Typography>
                        </CardContent>
                        <Box sx={{ borderLeft: 1, borderColor: 'divider' }}>
                            <Tabs
                                orientation="vertical"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Admin details tabs"
                                sx={{ borderLeft: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Information général de l’admin" />
                                <Tab label="Choix des Rôles" />
                            </Tabs>
                        </Box>
                    </Card>
                </Grid>

                {/* Form Section */}
                <Grid item xs={12} sm={8} md={9}>
                    {renderTabContent()}
                    {isEditing && (
                        <Box mt={2}>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                Enregistrer
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AdminDetail;
