import React, { useEffect, useState, memo } from 'react';
import { Box, Typography, Avatar, IconButton, TextField, Menu, MenuItem, Skeleton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as AIcon } from 'assets/images/icons/Icon@.svg';
import { ReactComponent as SendIcon } from 'assets/images/icons/Vectorsend_msg.svg';
// import chatBackground from 'assets/images/chat_bg.png';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const ChatBox = memo(
    ({
        currentHazard,
        selectedMembers,
        availableMembers,
        message,
        handleMessageChange,
        handleSend,
        handleRemoveMember,
        anchorEl,
        setAnchorEl,
        handleMemberSelect,
        theme,
        currentUser,
        loading
    }) => {
        const [cachedMessages, setCachedMessages] = useState([]);
        const navigate = useNavigate();
        useEffect(() => {
            const cached = JSON.parse(localStorage.getItem(`chat_${currentHazard?.id}`)) || [];
            setCachedMessages(cached);
        }, [currentHazard]);

        useEffect(() => {
            if (currentHazard?.conversation?.messages) {
                localStorage.setItem(`chat_${currentHazard?.id}`, JSON.stringify(currentHazard.conversation.messages));
            }
        }, [currentHazard?.conversation?.messages]);

        return (
            <Box
                sx={{
                    height: '85vh',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 4,
                    borderRadius: '8px',
                    py: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`
                }}
            >
                <Typography variant="h3" sx={{ color: theme.palette.text.primary, px: 2 }} gutterBottom>
                    Chat
                </Typography>

                <Box
                    sx={{
                        flex: 1,
                        overflowY: 'scroll',
                        // backgroundImage: `url(${chatBackground})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'repeat',
                        px: 2
                    }}
                >
                    {loading
                        ? Array.from(new Array(5)).map((_, index) => (
                              <Box key={index} sx={{ mb: 2 }}>
                                  <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                                      <Box sx={{ flexGrow: 1 }}>
                                          <Skeleton variant="text" width="40%" sx={{ mb: 0.5 }} />
                                          <Skeleton variant="rectangular" width="80%" height={40} />
                                      </Box>
                                  </Box>
                              </Box>
                          ))
                        : (currentHazard?.conversation?.messages || cachedMessages).map((msg) => (
                              <Box
                                  key={msg.id}
                                  sx={{
                                      mb: 2,
                                      display: 'flex',
                                      flexDirection: msg.user.id === currentUser.id ? 'row-reverse' : 'row',
                                      alignItems: 'center'
                                  }}
                              >
                                  <Box>
                                      <Avatar
                                          src={msg.user.profilePictureUrl}
                                          onClick={() =>
                                              msg.user.id !== currentUser.id && navigate(`/gestion-des-admins/admins/${msg?.user?.id}`)
                                          }
                                          sx={{
                                              ml: msg.user.id !== currentUser.id ? 0 : 1,
                                              mr: msg.user.id === currentUser.id ? 0 : 1,
                                              order: msg.user.id === currentUser.id ? 2 : 1,
                                              width: 25,
                                              height: 25,
                                              cursor: msg.user.id !== currentUser.id && 'pointer'
                                          }}
                                      />
                                  </Box>
                                  <Box
                                      sx={{
                                          textAlign: msg.user.id === currentUser.id ? 'right' : 'left',
                                          order: msg.user.id === currentUser.id ? 1 : 2
                                      }}
                                  >
                                      {msg.user.id === currentUser.id && (
                                          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mb: 0.5 }}>
                                              @{msg.recipients?.map((recipient) => recipient.firstName).join(', ')}
                                          </Typography>
                                      )}
                                      <Typography
                                          variant="body1"
                                          sx={{
                                              backgroundColor:
                                                  msg.user.id === currentUser.id ? theme.palette.primary.main : theme.palette.grey[300],
                                              color:
                                                  msg.user.id === currentUser.id
                                                      ? theme.palette.primary.contrastText
                                                      : theme.palette.text.primary,
                                              borderRadius: '12px',
                                              padding: '10px',
                                              fontWeight: 400,
                                              fontSize: '12px',
                                              wordBreak: 'break-word',
                                              whiteSpace: 'pre-wrap'
                                          }}
                                      >
                                          {msg.content}
                                      </Typography>
                                      <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary, mb: 0.5 }}>
                                          {new Date(currentHazard?.createdAt * 1000).toLocaleString('fr-FR', {
                                              year: 'numeric',
                                              month: '2-digit',
                                              day: '2-digit',
                                              hour: '2-digit',
                                              minute: '2-digit',
                                              hour12: false
                                          })}
                                      </Typography>
                                  </Box>
                              </Box>
                          ))}
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', py: 1, overflow: 'scroll' }}>
                        {selectedMembers.map((member) => (
                            <Box
                                key={member.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: theme.palette.grey[300],
                                    borderRadius: '50px',
                                    padding: '3px 8px',
                                    marginInline: 1,
                                    whiteSpace: 'nowrap',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    position: 'relative'
                                }}
                            >
                                <Avatar src={member.profilePictureUrl} sx={{ width: 20, height: 20, marginRight: 1 }} />
                                <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                                    {member.firstName} {member.lastName}
                                </Typography>
                                <IconButton
                                    size="small"
                                    sx={{ right: 0, top: '50%', color: 'red' }}
                                    onClick={() => handleRemoveMember(member.id)}
                                >
                                    <CloseIcon fontSize="bold" />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            borderTop: `1px solid ${theme.palette.divider}`
                        }}
                    >
                        <TextField
                            fullWidth
                            value={message}
                            onChange={handleMessageChange}
                            placeholder="Entrez votre message..."
                            variant="outlined"
                            multiline
                            InputProps={{
                                endAdornment: (
                                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <IconButton
                                            size="small"
                                            sx={{ color: 'blue', marginRight: 1 }}
                                            onClick={(e) => setAnchorEl(e.currentTarget)}
                                        >
                                            <AIcon size={30} sx={{ fontSize: 24 }} />
                                        </IconButton>
                                        <IconButton size="small" sx={{ color: 'blue' }} onClick={handleSend}>
                                            <SendIcon sx={{ fontSize: 24 }} />
                                        </IconButton>
                                    </Box>
                                )
                            }}
                            sx={{ marginTop: 1, mx: 1 }}
                        />
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                            {availableMembers.map((member) => (
                                <MenuItem key={member.id} onClick={() => handleMemberSelect(member)}>
                                    <Avatar src={member.profilePictureUrl} sx={{ mr: 1 }} />
                                    {member.firstName} {member.lastName}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Box>
            </Box>
        );
    }
);

ChatBox.propTypes = {
    currentHazard: PropTypes.object,
    selectedMembers: PropTypes.array.isRequired,
    availableMembers: PropTypes.array.isRequired,
    message: PropTypes.string.isRequired,
    handleMessageChange: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    handleRemoveMember: PropTypes.func.isRequired,
    anchorEl: PropTypes.object,
    setAnchorEl: PropTypes.func.isRequired,
    handleMemberSelect: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

export default ChatBox;
