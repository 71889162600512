import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material';

export default function EmployeeListSkeleton() {
    return (
        <Table>
            <TableBody>
                {Array.from(new Array(3)).map((_, index) => (
                    <TableRow key={index}>
                        {Array.from(new Array(9)).map((_, colIndex) => (
                            <TableCell key={colIndex}>
                                <Skeleton variant="text" width="100%" />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
