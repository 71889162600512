import { useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Skeleton,
    Box,
    IconButton,
    Divider,
    ListItemButton,
    ListItemText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slider,
    Modal,
    TextField,
    Button,
    Avatar,
    Menu,
    CircularProgress
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import LogoSection from 'layout/MainLayout/LogoSection';
import { ReactComponent as Localization } from 'assets/images/icons/localization.svg';
import { ReactComponent as Zoom } from 'assets/images/icons/zoom.svg';
import { ReactComponent as ReloadIcon } from 'assets/images/icons/Vectorreload.svg';
import { ReactComponent as Calendar } from 'assets/images/icons/calendar.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/images/icons/check-mark-done.svg';
import { Send } from '@mui/icons-material';
import { fetchCategories, fetchHazard, patchHazardStatus, postComment, postMessage, updateHazard } from 'store/hazardsActions';
import MediaNavigator from 'ui-component/extended/MediaNavigator';
import ChatBox from 'ui-component/extended/ChatBox';
import { useHasPermission } from 'utils/hasPermission';

const DeclarationDetail = () => {
    // console.clear();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const [status, setStatus] = useState('');
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState([]);
    const [severity, setSeverity] = useState(50);
    const [priority, setPriority] = useState(50);

    const { loading, updateHazardLoading, statusUpdateLoading, commentPostLoading, error } = useSelector((state) => state.hazards);
    const currentHazard = useSelector((state) => state.hazards.currentHazard);
    const categories = useSelector((state) => state.hazards.categories);
    const { user } = useSelector((state) => state.currentUser);

    const severities = [
        { value: 0, label: 'Mineur', color: theme.palette.primary.dark, num: 'minor' },
        { value: 50, label: 'Faible', color: theme.palette.primary.main, num: 'low' },
        { value: 100, label: 'Significatif', color: theme.palette.error.main, num: 'significant' },
        { value: 150, label: 'Catastrophique', color: theme.palette.error.dark, num: 'catastrophic' }
    ];

    const priorities = [
        { value: 0, label: 'Faible', color: theme.palette.primary.main, num: 'minor' },
        { value: 50, label: 'Modéré', color: theme.palette.warning.dark, num: 'moderate' },
        { value: 100, label: 'Critique', color: theme.palette.error.main, num: 'critical' },
        { value: 150, label: 'Urgent', color: theme.palette.error.dark, num: 'urgent' }
    ];

    const statusOptions = [
        { value: 'En Cours', label: 'En Cours', color: theme.palette.warning.dark, num: 'in-progress' },
        { value: 'Validé', label: 'Validé', color: theme.palette.success.dark, num: 'validated' },
        { value: 'Résolu', label: 'Résolu', color: theme.palette.primary.dark, num: 'resolved' },
        { value: 'Annulé', label: 'Annulé', color: theme.palette.error.dark, num: 'canceled' }
    ];

    const canManageHazards = useHasPermission('view_hazards');
    useEffect(() => {
        if (canManageHazards) {
            dispatch(fetchHazard(id));
            dispatch(fetchCategories());
        } else {
            navigate('/');
        }
    }, [dispatch, id]);
    const location = useLocation();

    const { severity: severityValue, priority: priorityValue } = location.state || {};
    useEffect(() => {
        if (currentHazard) {
            setStatus(currentHazard?.status || '');
            setComments(currentHazard?.comments || []);
            const severityLabel = currentHazard?.severity?.toLowerCase() || 'faible';
            const priorityLabel = currentHazard?.priority?.toLowerCase() || 'modéré';

            // Find corresponding severity and priority values
            const severityItem = severities.find((item) => item.num.toLowerCase() === severityLabel);
            const priorityItem = priorities.find((item) => item.num.toLowerCase() === priorityLabel);

            setSeverity(severityItem ? severityItem.value : 50);
            setPriority(priorityItem ? priorityItem.value : 50);
        }
    }, [currentHazard]);

    const [open, setOpen] = useState(false);
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSeverityChange = (event, newValue) => {
        setSeverity(event.target.value);
    };

    const handlePriorityChange = (event, newValue) => {
        setPriority(event.target.value);
    };
    const [sortedComments, setSortedComments] = useState([]);

    useEffect(() => {
        if (currentHazard) {
            setComments(currentHazard?.comments || []);
        }
    }, [currentHazard]);

    useEffect(() => {
        const sorted = [...comments].sort((a, b) => a.createdAt - b.createdAt);
        setSortedComments(sorted);
    }, [comments]);

    const handleAddComment = async () => {
        if (newComment.trim()) {
            const newCommentObject = {
                id: Date.now(),
                content: newComment.trim(),
                createdAt: Date.now() / 1000, // Use the current time as createdAt
                user: {
                    id: user?.id,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    profilePictureUrl: user?.profilePictureUrl
                }
            };

            // Update local state with the new comment immediately
            setComments((prevComments) => [...prevComments, newCommentObject]);

            // Optionally sort comments again after adding
            const sorted = [...comments, newCommentObject].sort((a, b) => a.createdAt - b.createdAt);
            setSortedComments(sorted);

            try {
                await dispatch(postComment(id, newCommentObject?.content));
            } catch (error) {
                console.error('Failed to post comment:', error);
            }
            setNewComment('');

            setOpen(false);
        }
    };
    const handleUpdateHazard = async () => {
        const selectedSeverity = severities.find((item) => item.value === severity);
        const selectedPriority = priorities.find((item) => item.value === priority);

        const severityNum = selectedSeverity ? selectedSeverity.num : 'low'; // Default to 'low' if not found
        const priorityNum = selectedPriority ? selectedPriority.num : 'moderate'; // Default to 'moderate' if not found

        const statusNum = statusOptions.find((item) => item.value === status)?.num || 'in-progress'; // Default to 'En Cours'
        // Update the hazard with the actual data
        await dispatch(
            updateHazard(currentHazard.id, {
                status: statusNum,
                severity: severityNum,
                priority: priorityNum,
                categoryId: 1,
                typeId: 1
            })
        );

        // Fetch updated hazard data
        await dispatch(fetchHazard(id));
        setOpen(true);
    };

    const [severityGradient, setSeverityGradient] = useState('');
    const [priorityGradient, setPriorityGradient] = useState('');

    useEffect(() => {
        const getGradient = (items, value) => {
            const numItems = items.findIndex((item) => item.value >= value) + 1;
            return `linear-gradient(to right, ${items
                .slice(0, numItems)
                .map((item) => item.color)
                .join(', ')})`;
        };

        setSeverityGradient(getGradient(severities, severity || currentHazard?.severity));
        setPriorityGradient(getGradient(priorities, priority));
    }, [severity, priority, severities, priorities, currentHazard]);

    const [defaultSeveritieValue, setDefaultSeverityValue] = useState(25);
    useEffect(() => {
        const severityIndex = severities.findIndex((s) => s.label === currentHazard?.severity);
        setDefaultSeverityValue(severityIndex !== -1 ? severities[severityIndex].value : 0);
    }, [severities, currentHazard]);

    const [message, setMessage] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMessageChange = (e) => {
        setMessage(e.target.value);

        // Handle @ mention dropdown visibility and selection
        const value = e.target.value;
        if (value.includes('@')) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handleMemberSelect = (member) => {
        setSelectedMembers([...selectedMembers, member]);
        setMessage(message.replace(/@\w*$/, `@${member.firstName}`));
        setAnchorEl(null);
    };

    // Filter members for dropdown
    const availableMembers =
        currentHazard?.conversation?.members?.filter((member) => !selectedMembers?.some((selected) => selected?.id === member?.id)) || [];

    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);

    const handleSend = async () => {
        if (message.trim()) {
            const newMessageObject = {
                id: Date.now(), // Temporary ID for optimistic update
                content: message.trim(),
                createdAt: Date.now() / 1000, // Convert to seconds for your current format
                user: {
                    id: user?.id,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    profilePictureUrl: user?.profilePictureUrl
                },
                status: 'pending'
            };
            const selectedMemberIds = selectedMembers.map((member) => member.id);
            // Optimistically add the new message to the state
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, newMessageObject];
                localStorage.setItem(`messages_${currentHazard?.id}`, JSON.stringify(updatedMessages)); // Cache the messages in localStorage
                return updatedMessages;
            });

            // Clear the input field after sending the message
            setMessage('');

            try {
                const response = dispatch(postMessage(currentHazard?.id, message.trim(), selectedMemberIds));

                // If successful, update the message status to 'sent' and assign the new message ID from the response
                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.id === newMessageObject.id
                            ? { ...msg, status: 'sent', id: response?.id } // Replace temporary ID with the real ID
                            : msg
                    )
                );
                await dispatch(fetchHazard(id));
                // Update localStorage with the new messages (including the correct message ID)
                localStorage.setItem(`messages_${currentHazard?.id}`, JSON.stringify(messages));
            } catch (error) {
                console.error('Failed to send message:', error);

                // If the message fails to send, mark the status as 'failed'
                setMessages((prevMessages) =>
                    prevMessages.map((msg) => (msg.id === newMessageObject.id ? { ...msg, status: 'failed' } : msg))
                );
            }
        }
    };

    const handleRemoveMember = (memberId) => {
        setSelectedMembers((prevMembers) => prevMembers.filter((member) => member.id !== memberId));
    };

    const handleNavigateMap = () => {
        navigate('/gestion-des-hazards/declarations/map', { state: { hazardData: currentHazard } });
    };

    const renderMarks = (items) =>
        items.map((item) => ({
            value: item.value,
            label: <span style={{ color: item.color }}>{item.label}</span>
        }));

    const sliderStyles = {
        color: theme.palette.primary.main,
        height: 20,
        '& .MuiSlider-track': {
            height: 20,
            boxShadow: '2.5px 2.5px 5px 0px rgba(35, 58, 92, 1) inset',
            border: '1px'
        },
        '& .MuiSlider-rail': {
            borderRadius: '76px',
            height: 20,
            boxShadow: '2.5px 2.5px 5px 0px rgba(35, 58, 92, 1) inset',
            backgroundColor: '#B8B8B8'
        },
        '& .MuiSlider-thumb': {
            height: '28px',
            width: '44px',
            borderRadius: '76px',
            backgroundColor: 'white',
            boxShadow: '2.5px 2.5px 10px 0px rgba(166, 171, 189, 1)',
            border: '0.5px solid currentColor',
            '&:focus, &:hover, &$active': {
                boxShadow: '-2.5px -2.5px 10px 0px rgba(250, 251, 255, 0.4)'
            }
        },
        '& .MuiSlider-mark': {
            height: 8,
            width: 8,
            backgroundColor: 'transparent'
        },
        '& .MuiSlider-markLabel': {
            color: '#000',
            textAlign: 'center',
            whiteSpace: 'normal',
            width: '70px',
            fontSize: '12px',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px'
        },
        '& .MuiSlider-valueLabel': {
            color: '#000',
            textAlign: 'center',
            whiteSpace: 'normal',
            width: '70px',
            fontSize: '12px',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px'
        }
    };

    return (
        <MainCard
            title="Hazard Detail"
            buttonLink="/some-path"
            // buttonTitle="Click Me"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                position: 'relative',
                flexGrow: 1
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <ChatBox
                        currentHazard={currentHazard}
                        selectedMembers={selectedMembers}
                        availableMembers={availableMembers}
                        message={message}
                        handleMessageChange={handleMessageChange}
                        handleSend={handleSend}
                        handleRemoveMember={handleRemoveMember}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleMemberSelect={handleMemberSelect}
                        theme={theme}
                        loading={loading}
                        currentUser={user}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    {loading ? (
                        <Skeleton variant="rectangular" height={400} />
                    ) : (
                        currentHazard && (
                            <Box
                                sx={{
                                    height: '85vh',
                                    display: 'flex',
                                    overflowX: 'hidden',
                                    flexDirection: 'column',
                                    paddingInline: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                        overflowX: 'hidden',
                                        backgroundColor: theme.palette.background.paper,
                                        borderBottom: `1px solid ${theme.palette.divider}`
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LogoSection />
                                        <Box sx={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                                            {currentHazard.isAnonymous ? (
                                                <Typography variant="h4" color={theme.palette.text.primary} fontWeight={600}>
                                                    Rapporteur Anonyme
                                                </Typography>
                                            ) : (
                                                <Typography variant="h4" color={theme.palette.text.primary} fontWeight={600}>
                                                    {`${currentHazard?.user?.firstName || ''} ${
                                                        currentHazard?.user?.lastName || ''
                                                    }`.trim() || 'Rapporteur Anonyme'}
                                                </Typography>
                                            )}
                                            <Typography variant="h4" color={theme.palette.text.primary} fontWeight={600}>
                                                {new Date(currentHazard?.createdAt * 1000).toLocaleString('fr-FR', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false
                                                })}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        overflowY: 'auto',
                                        p: 2,
                                        backgroundColor: theme.palette.background.paper
                                    }}
                                >
                                    <Typography variant="h4" gutterBottom>
                                        {currentHazard?.category?.name?.fr} / Catégorie
                                    </Typography>
                                    <Typography variant="h6" paragraph>
                                        {currentHazard?.description || 'Vide'}
                                    </Typography>

                                    <Divider sx={{ my: 2 }} />

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton edge="start" aria-label="localization">
                                                <Localization />
                                            </IconButton>
                                            <Typography variant="h5" sx={{ mx: 1, fontSize: '14px', fontWeight: 500 }}>
                                                Localization / {currentHazard?.site?.name || 'Vide'}
                                            </Typography>
                                        </Box>
                                        <IconButton edge="end" aria-label="zoom" onClick={handleNavigateMap}>
                                            <Zoom />
                                        </IconButton>
                                    </Box>

                                    <Grid container spacing={1}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="status-select-label"
                                                id="status-select"
                                                variant="outlined"
                                                value={status || currentHazard?.status || ''}
                                                label={false}
                                                renderValue={(selected) => {
                                                    const selectedOption = statusOptions?.find((option) => option?.num === selected);
                                                    return (
                                                        <span style={{ color: selectedOption?.color }}>
                                                            {selectedOption?.value || 'En cours'}
                                                        </span>
                                                    );
                                                }}
                                                sx={{
                                                    color: statusOptions?.find((item) => item?.num === status)?.color,
                                                    px: 2,
                                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                    border: 'none',
                                                    borderWidth: 0,
                                                    borderRadius: '8px',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        border: 'none'
                                                    }
                                                }}
                                                onChange={handleStatusChange}
                                                displayEmpty
                                            >
                                                <MenuItem disabled value="">
                                                    <em>
                                                        état actuel :{' '}
                                                        {statusOptions?.find((item) => item?.num === currentHazard?.status)?.value ||
                                                            'En cours'}
                                                    </em>
                                                </MenuItem>
                                                {statusOptions?.map((statusOption, index) => (
                                                    <MenuItem key={index} sx={{ color: statusOption?.color }} value={statusOption?.num}>
                                                        {statusOption?.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item my={2} mx={2} sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ width: 350, justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography variant="h4" sx={{ textAlign: 'left', display: 'flex', width: '100%' }}>
                                                Sévérité
                                            </Typography>
                                            <Slider
                                                aria-label="Sévérité"
                                                defaultValue={severities?.find((item) => item?.num === severityValue)?.value}
                                                step={null}
                                                min={0}
                                                max={150}
                                                valueLabelDisplay="off"
                                                marks={renderMarks(severities)}
                                                sx={{
                                                    ...sliderStyles,
                                                    '& .MuiSlider-track': {
                                                        background: severityGradient,
                                                        boxShadow: '2.5px 2.5px 5px 0px rgba(35, 58, 92, 1) inset',
                                                        border: '1px solid rgba(255, 255, 255, 0.4)'
                                                    }
                                                }}
                                                onChange={handleSeverityChange}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item my={3} mx={2} sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ width: 350, justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography variant="h4">Priorité</Typography>
                                            <Slider
                                                aria-label="Priorité"
                                                defaultValue={priorities?.find((item) => item?.num === priorityValue)?.value || severity}
                                                min={0}
                                                max={150}
                                                step={null}
                                                valueLabelDisplay="off"
                                                marks={renderMarks(priorities)}
                                                sx={{
                                                    ...sliderStyles,
                                                    '& .MuiSlider-track': {
                                                        background: priorityGradient,
                                                        boxShadow: '2.5px 2.5px 5px 0px rgba(35, 58, 92, 1) inset',
                                                        border: '1px solid rgba(255, 255, 255, 0.4)'
                                                    }
                                                }}
                                                onChange={handlePriorityChange}
                                            />
                                        </Box>
                                    </Grid>

                                    <Box sx={{ mt: 3 }}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box sx={{ position: 'relative', py: 1, mt: 0, width: '100%' }}>
                                                {loading ? (
                                                    <Skeleton variant="rectangular" width="100%" height={400} />
                                                ) : (
                                                    <>
                                                        <MediaNavigator isLoading={loading} currentHazard={currentHazard} />
                                                        <Typography
                                                            variant="h2"
                                                            sx={{ color: theme.palette.text.primary, width: '100%', display: 'flex' }}
                                                            gutterBottom
                                                        >
                                                            Commentaires
                                                        </Typography>
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    width: '100%',
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    zIndex: 1,
                                                    backgroundColor: theme.palette.text.paper,
                                                    p: 1
                                                }}
                                            >
                                                <Box>
                                                    {sortedComments?.map((comment, index) => {
                                                        const isCommentOwner = user?.id === comment?.user?.id; // Ensure 'user' is defined as the current logged-in user
                                                        return (
                                                            <Box
                                                                key={comment?.id || index}
                                                                sx={{
                                                                    my: 1,
                                                                    display: 'flex',
                                                                    alignItems: 'start',
                                                                    justifyContent: isCommentOwner ? 'flex-end' : 'flex-start',
                                                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                                                    textAlign: isCommentOwner ? 'right' : 'left',
                                                                    py: 2
                                                                }}
                                                            >
                                                                {/* Display avatar based on ownership */}
                                                                {!isCommentOwner && (
                                                                    <Avatar sx={{ mr: 2 }} src={comment?.user?.profilePictureUrl} />
                                                                )}

                                                                <Box sx={{ flex: 1 }}>
                                                                    <Box
                                                                        sx={{
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'space-between',
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        {/* Reverse timestamp and name if the user is the owner */}
                                                                        {!isCommentOwner && (
                                                                            <Typography
                                                                                variant="body2"
                                                                                sx={{
                                                                                    color: theme.palette.text.primary,
                                                                                    fontWeight: '600'
                                                                                }}
                                                                            >
                                                                                {comment?.user?.firstName} {comment?.user?.lastName}
                                                                            </Typography>
                                                                        )}

                                                                        <Typography
                                                                            variant="body2"
                                                                            sx={{
                                                                                color: theme.palette.text.secondary,
                                                                                fontWeight: '600',
                                                                                px: 1
                                                                            }}
                                                                        >
                                                                            {new Date(comment?.createdAt * 1000).toLocaleString('fr-FR', {
                                                                                year: 'numeric',
                                                                                month: '2-digit',
                                                                                day: '2-digit',
                                                                                hour: '2-digit',
                                                                                minute: '2-digit',
                                                                                hour12: false
                                                                            })}
                                                                        </Typography>

                                                                        {isCommentOwner && (
                                                                            <Typography
                                                                                variant="body2"
                                                                                sx={{
                                                                                    color: theme.palette.text.primary,
                                                                                    fontWeight: '600'
                                                                                }}
                                                                            >
                                                                                {comment?.user?.firstName} {comment?.user?.lastName}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>

                                                                    <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                                                                        {comment?.content}
                                                                    </Typography>
                                                                </Box>

                                                                {/* Show the avatar on the right if the user is the owner */}
                                                                {isCommentOwner && (
                                                                    <Avatar sx={{ ml: 2 }} src={comment?.user?.profilePictureUrl} />
                                                                )}
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    height: '100%',
                                                    maxHeight: '55px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    overflowX: 'hidden',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    value={newComment}
                                                    onChange={(e) => setNewComment(e.target.value)}
                                                    placeholder="Laisser un commentaire"
                                                    hiddenLabel
                                                    variant="filled"
                                                />
                                                <Box sx={{ textAlign: 'right' }}>
                                                    {commentPostLoading ? (
                                                        <CircularProgress size={24} />
                                                    ) : (
                                                        <IconButton onClick={handleAddComment} color="primary">
                                                            <Send />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        position: 'sticky',
                                        bottom: 0,
                                        zIndex: 1,
                                        backgroundColor: theme.palette.background.paper,
                                        borderTop: `1px solid ${theme.palette.divider}`,
                                        p: 2
                                    }}
                                >
                                    <ListItemButton
                                        className="status-button"
                                        sx={{
                                            borderRadius: '8px',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            backgroundColor: theme.palette.primary.dark,
                                            width: '100%'
                                        }}
                                        onClick={handleUpdateHazard}
                                        disabled={updateHazardLoading}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography sx={{ color: theme.palette.primary.light }} variant="h4">
                                                    {updateHazardLoading ? 'ENREGISTREMENT ...' : 'ENREGISTRER'}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Box>
                            </Box>
                        )
                    )}
                </Grid>
            </Grid>

            <Modal open={open} onClose={() => setOpen(false)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                        borderRadius: 2,
                        width: 500,
                        textAlign: 'center'
                    }}
                >
                    <CheckMarkIcon sx={{ fontSize: '24px', mb: 2 }} />
                    <Typography variant="h2" gutterBottom>
                        Le hazard est modifié avec succès
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                            Ok
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {/* <StatusModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                title={modalTitle}
                content={modalContent}
                icon={successEdit ? 'success' : 'error'}
            /> */}
        </MainCard>
    );
};

export default DeclarationDetail;
