/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Paper,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Skeleton,
    Box,
    Button,
    Typography,
    Checkbox,
    ListItemText,
    InputAdornment
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import HazardsTableSkeleton from 'ui-component/cards/Skeleton/HazardsTable';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, fetchHazards } from 'store/hazardsActions';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import { ReactComponent as AnonymeIcon } from 'assets/images/icons/anonyme.svg';
import DownloadCSV from 'ui-component/extended/DownloadCSV';
import { fetchSites } from 'store/sitesActions';
import { fetchEmployees } from 'store/employeeActions';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import DateRangePicker from 'ui-component/extended/DateRangePicker';
import { useHasPermission } from 'utils/hasPermission';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImportXlsxModal from 'ui-component/extended/ImportXSLX';

const DeclarationsList = () => {
    const severities = [
        { value: 'minor', label: 'Mineur', color: '#1565C0' },
        { value: 'low', label: 'Faible', color: '#1976D2' },
        { value: 'significant', label: 'Significatif', color: '#FBC02D' },
        { value: 'catastrophic', label: 'Catastrophique', color: '#D32F2F' }
    ];

    const statusOptions = [
        { value: 'validated', label: 'Validé' },
        { value: 'in-progress', label: 'En Cours' },
        { value: 'resolved', label: 'Résolu' },
        { value: 'canceled', label: 'Annulé' }
    ];
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('reference');
    const [page, setPage] = useState(1);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { hazards, categories, loading } = useSelector((state) => state.hazards);
    const { sites } = useSelector((state) => state.sites);
    const { user } = useSelector((state) => state.currentUser);
    const [filter, setFilter] = useState({
        search: '',
        severity: [],
        anonymous: [],
        site: [],
        category: [],
        status: [],
        newHazard: '',
        minDate: '',
        maxDate: ''
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setPage(1);
        if (name === 'status') {
            if (value.includes('Tous')) {
                // If "Tous" is selected, set to all status options
                const allStatusValues = statusOptions.map((option) => option.value);
                setFilter((prev) => ({
                    ...prev,
                    [name]: allStatusValues
                }));
            } else {
                // Update status filter with selected values, excluding "Tous"
                setFilter((prev) => ({
                    ...prev,
                    [name]: value
                }));
            }
        } else {
            // Handle other filter changes
            setFilter((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleRequestSort = (property) => {
        const isAscending = orderBy === property && order === 'desc';
        setOrder(isAscending ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const canManageHazards = useHasPermission('index_hazards');

    useEffect(() => {
        if (!canManageHazards) {
            console.log("Non autorisé : L'utilisateur n'a pas la permission de gérer les dangers.");
            navigate('/');
            // toast.error("Vous n'avez pas la permission d'accéder à cette page.");
            return;
        }

        const formattedFilter = {
            search: filter.search,
            severity: filter.severity.join(', '), // Convertir le tableau de sévérité en chaîne
            anonymous: filter.anonymous.length > 0 ? filter.anonymous[0] === 'true' : false,
            site: filter.site.join(','), // Convertir le tableau de site en chaîne
            category: filter.category.join(','), // Convertir le tableau de catégorie en chaîne
            status: filter.status.join(','), // Convertir le tableau de statut en chaîne
            newHazard: filter.newHazard,
            minDate: filter.minDate,
            maxDate: filter.maxDate
        };

        const fetchInitialData = async () => {
            setPage(1);
            try {
                dispatch(
                    fetchHazards(
                        page,
                        15,
                        formattedFilter.search,
                        formattedFilter.category,
                        formattedFilter.status,
                        formattedFilter.severity,
                        formattedFilter.site,
                        formattedFilter.type,
                        formattedFilter.anonymous,
                        formattedFilter.newHazard,
                        formattedFilter.minDate,
                        formattedFilter.maxDate
                    )
                );
            } catch (err) {
                console.error('Erreur lors de la récupération des données :', err);
            }
        };

        fetchInitialData();
    }, [dispatch, canManageHazards, filter]);

    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
        const formattedFilter = {
            search: filter.search,
            severity: filter.severity.join(', '), // Convertir le tableau de sévérité en chaîne
            anonymous: filter.anonymous.length > 0 ? filter.anonymous[0] === 'true' : false,
            site: filter.site.join(','), // Convertir le tableau de site en chaîne
            category: filter.category.join(','), // Convertir le tableau de catégorie en chaîne
            status: filter.status.join(','), // Convertir le tableau de statut en chaîne
            newHazard: filter.newHazard,
            minDate: filter.minDate,
            maxDate: filter.maxDate
        };
        dispatch(
            fetchHazards(
                page,
                15,
                formattedFilter.search,
                formattedFilter.category,
                formattedFilter.status,
                formattedFilter.severity,
                formattedFilter.site,
                formattedFilter.type,
                formattedFilter.anonymous,
                formattedFilter.newHazard,
                formattedFilter.minDate,
                formattedFilter.maxDate
            )
        );
    };
    // Clear Filters...
    const clearFilters = () => {
        setPage(1); // Reset page when filters are cleared
        setFilter({
            search: '',
            severity: [],
            anonymous: [],
            site: [],
            category: [],
            status: [],
            minDate: '',
            maxDate: ''
        });
    };

    const fetchInitialCategories = () => {
        if (!categories?.length) {
            dispatch(fetchCategories());
        }
    };

    // Fetch initial sites
    const fetchInitialSites = () => {
        if (!sites.list.length) {
            dispatch(fetchSites(1, 15));
        }
    };

    const fetchMoreSites = () => {
        if (!sites.hasMore) return;

        // Fetch the next page of sites
        dispatch(fetchSites(sites.pagination.currentPage + 1, 15)).then((newSites) => {
            // Check if more data is available
            // setHasMore(newSites.length > 0);
        });
    };

    const canViewHazard = useHasPermission('view_hazards');
    const handleRowClick = (id) => {
        const selectedHazard = hazards.list.find((hazard) => hazard.id === id);
        console.log(canViewHazard);
        if (selectedHazard && canViewHazard) {
            navigate(`/gestion-des-hazards/declarations/${id}`, {
                state: {
                    id: selectedHazard.id,
                    severity: selectedHazard.severity,
                    priority: selectedHazard.priority
                }
            });
        }
    };

    const getLabel = (value, options) => {
        const item = options.find((option) => option.value === value);
        return item ? item.label : 'Inconnu';
    };

    const getSeverityColor = (value) => {
        const item = severities.find((s) => s.value === value);
        return item ? item.color : theme.palette.text.primary;
    };

    const useStyles = makeStyles((theme) => ({
        textField: {
            maxWidth: '250px',
            borderBottom: 'none',
            '& .MuiInputBase-input': {
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: theme.palette.grey[700],
                opacity: 1
            },
            '& .MuiOutlinedInput-input': {
                borderBottom: 'none'
            }
        },
        submitButton: {
            marginTop: theme.spacing(2),
            backgroundColor: '#1F41BB',
            height: '50px'
        }
    }));

    const classes = useStyles();

    const getStatusColor = (status) => {
        const colorMapping = {
            validated: theme.palette.info.main,
            'in-progress': theme.palette.warning.dark,
            resolved: theme.palette.success.main,
            canceled: theme.palette.error.dark
        };
        return colorMapping[status] || theme.palette.text.primary;
    };

    return (
        <MainCard sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', position: 'relative' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mb: 3, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', mx: 0, px: 1 }}
                    >
                        <Grid item lg={2.5} md={2.5} xs={2.5}>
                            <TextField
                                name="search"
                                variant="outlined"
                                fullWidth
                                placeholder="Rechercher ..."
                                onChange={handleFilterChange}
                                value={filter.search}
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item lg={1.8} md={1.6} xs={1.6}>
                            <DateRangePicker
                                onDateChange={({ minDate, maxDate }) => {
                                    setFilter((prev) => ({
                                        ...prev,
                                        minDate,
                                        maxDate
                                    }));
                                    console.log(filter.minDate, filter.maxDate);
                                }}
                                className={classes.text}
                            />
                        </Grid>

                        <Grid item lg={1.6} md={1.6} xs={4}>
                            <InfiniteScroll
                                dataLength={categories?.list?.length || 0} // Current length of the data
                                // next={fetchMoreCategories} // Function to fetch the next set of data
                                hasMore={false} // Boolean to check if there are more data to fetch
                                loader={<MenuItem disabled>Chargement...</MenuItem>} // Loading indicator
                                scrollableTarget="category-dropdown" // A custom scroll target (optional)
                            >
                                <FormControl fullWidth>
                                    <InputLabel className={classes.label}>Catégorie</InputLabel>
                                    <Select
                                        multiple
                                        name="category"
                                        variant="outlined"
                                        value={filter.category}
                                        onChange={handleFilterChange}
                                        className={classes.select}
                                        onOpen={() => fetchInitialCategories()} // Fetch initial categories when dropdown opens
                                        renderValue={(selected) =>
                                            selected
                                                .map((categoryId) => {
                                                    const category = categories?.find((category) => category.id === categoryId);
                                                    return category ? `${category.name.fr}` : '';
                                                })
                                                .join(', ')
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                style: { maxHeight: 300, overflow: 'auto' } // Control max height of dropdown
                                            }
                                        }}
                                    >
                                        {categories?.map((category) => (
                                            <MenuItem key={category.id} value={category.id}>
                                                <Checkbox checked={filter.category.indexOf(category.id) > -1} />
                                                <ListItemText primary={`${category?.name.fr}`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </InfiniteScroll>
                        </Grid>

                        {user.role !== 'site-manager' && (
                            <Grid item lg={1.6} md={1.6} xs={4}>
                                <InfiniteScroll
                                    dataLength={sites?.list?.length || 0} // Length of current list
                                    next={fetchMoreSites} // Function to fetch more sites
                                    hasMore={sites?.hasMore || false} // Boolean to check if there are more sites
                                    loader={<MenuItem disabled>Chargement...</MenuItem>} // Loader while fetching more
                                    scrollableTarget="site-dropdown" // Set the scrollable target to the dropdown
                                >
                                    <FormControl fullWidth>
                                        <InputLabel className={classes.label}>Site</InputLabel>
                                        <Select
                                            multiple
                                            name="site"
                                            variant="outlined"
                                            value={filter.site} // Bind to the filter state
                                            onChange={handleFilterChange} // Handle multiple selection
                                            className={classes.select}
                                            onOpen={() => fetchInitialSites()} // Fetch initial sites when dropdown opens
                                            renderValue={(selected) =>
                                                selected
                                                    .map((siteId) => {
                                                        const site = sites?.list?.find((site) => site.id === siteId);
                                                        return site ? `${site.name}` : '';
                                                    })
                                                    .join(', ')
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    style: { maxHeight: 300, overflow: 'auto' } // Control max height of dropdown
                                                }
                                            }}
                                        >
                                            {/* Scrollable target for infinite scroll */}
                                            {sites?.list?.map((site) => (
                                                <MenuItem key={site.id} id="site-dropdown" value={site.id}>
                                                    <Checkbox checked={filter.site.indexOf(site.id) > -1} />
                                                    <ListItemText primary={`${site?.name}`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </InfiniteScroll>
                            </Grid>
                        )}

                        <Grid item lg={1.5} md={1.6} xs={4}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Anonyme</InputLabel>
                                <Select
                                    multiple
                                    name="anonymous"
                                    variant="outlined"
                                    value={filter.anonymous}
                                    onChange={handleFilterChange}
                                    className={classes.select}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) return 'Tous';
                                        const uniqueValues = [...new Set(selected)];

                                        const isAnonymeSelected = uniqueValues.includes('true');
                                        const isTousSelected = uniqueValues.includes('false');

                                        if (isAnonymeSelected && !isTousSelected) {
                                            return 'Anonyme';
                                        }
                                        return 'Tous';
                                    }}
                                >
                                    <MenuItem value="false">
                                        <Checkbox checked={filter.anonymous.indexOf('false') > -1} />
                                        <ListItemText primary="Tous" />
                                    </MenuItem>
                                    <MenuItem value="true">
                                        <Checkbox checked={filter.anonymous.indexOf('true') > -1} />
                                        <ListItemText primary="Anonyme" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={1.5} md={1.6} xs={4}>
                            <FormControl fullWidth>
                                <InputLabel className={classes.label}>Status</InputLabel>
                                <Select
                                    variant="outlined"
                                    multiple
                                    name="status"
                                    value={filter.status}
                                    className={classes.select}
                                    onChange={handleFilterChange}
                                    renderValue={(selected) => {
                                        const allStatusValues = statusOptions.map((option) => option.value);
                                        const isAllSelected = allStatusValues.every((value) => selected.includes(value));

                                        if (isAllSelected) return 'Tous';

                                        return selected
                                            .map((value) => {
                                                const option = statusOptions.find((option) => option.value === value);
                                                return option ? option.label : '';
                                            })
                                            .join(', ');
                                    }}
                                >
                                    <MenuItem value="Tous">
                                        <Checkbox checked={filter.status.length === statusOptions.length} />
                                        <ListItemText primary="Tous" />
                                    </MenuItem>
                                    {statusOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <Checkbox checked={filter.status.indexOf(option.value) > -1} />
                                            <ListItemText primary={option.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={1.5} md={1.6} xs={4}>
                            <TrashIcon onClick={clearFilters} style={{ cursor: 'pointer' }} />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '150px' }}>
                            <Typography variant="subtitle2">Résultat</Typography>
                            <Typography variant="subtitle1">{hazards.pagination.total} Hazard</Typography>
                        </Box>
                        <ImportXlsxModal />
                    </Box>
                    <Box sx={{ position: 'relative', overflow: 'auto' }}>
                        <InfiniteScroll
                            dataLength={hazards?.list?.length}
                            next={fetchMoreData}
                            hasMore={hazards.hasMore}
                            loader={<HazardsTableSkeleton />}
                            scrollableTarget="scrollableTable"
                            endMessage={
                                <Typography variant="body2" align="center" color="textSecondary">
                                    Vous avez vu toutes les notifications.
                                </Typography>
                            }
                        >
                            <TableContainer component={Paper} sx={{ top: 0, left: 0, right: 0, bottom: 50 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {[
                                                { id: 'reference', label: 'Référence' },
                                                { id: 'reporter', label: 'Rapporteur' },
                                                { id: 'category', label: 'Catégorie' },
                                                { id: 'type', label: 'Type' },
                                                { id: 'site', label: 'Site' },
                                                { id: 'createdAt', label: 'Date de déclaration' },
                                                { id: 'observedAt', label: "Date d'observation" },
                                                { id: 'severity', label: 'Sévérité' },
                                                { id: 'status', label: 'Status' }
                                            ].map(({ id, label }) => (
                                                <TableCell
                                                    key={id}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        fontWeight: '900',
                                                        color: '#202224',
                                                        lineHeight: '11px'
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === id}
                                                        direction={orderBy === id ? order : 'asc'}
                                                        onClick={() => handleRequestSort(id)}
                                                    >
                                                        {label}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {hazards.list.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={8} align="center">
                                                    Aucune donnée disponible
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            hazards.list.map((hazard) => (
                                                <>
                                                    <TableRow
                                                        key={hazard.id}
                                                        hover
                                                        onClick={() => handleRowClick(hazard.id)}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            maxHeight: '65px'
                                                        }}
                                                    >
                                                        <TableCell
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                fontWeight: '500',
                                                                color: '#202224',
                                                                lineHeight: '11px'
                                                            }}
                                                        >
                                                            {hazard.reference}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                fontWeight: '500',
                                                                color: '#202224',
                                                                lineHeight: '11px'
                                                            }}
                                                        >
                                                            {!hazard?.isAnonymous ? (
                                                                <span style={{ whiteSpace: 'nowrap' }}>
                                                                    {hazard?.user?.firstName} {hazard?.user?.lastName}
                                                                </span>
                                                            ) : (
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <AnonymeIcon style={{ marginRight: '8px' }} />
                                                                    <Typography>Inconnu</Typography>
                                                                </Box>
                                                            )}
                                                        </TableCell>
                                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={hazard?.category?.iconUrl}
                                                                    alt={hazard?.category?.name?.fr || 'icon'}
                                                                    style={{ marginRight: '8px', width: '23px' }}
                                                                />
                                                                <Typography
                                                                    sx={{ fontWeight: '400', color: '#202224', lineHeight: '11px' }}
                                                                >
                                                                    {hazard?.category?.name?.fr}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                fontWeight: '400',
                                                                color: '#202224'
                                                            }}
                                                        >
                                                            {hazard?.type?.name?.fr}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                fontWeight: '400',
                                                                color: '#202224'
                                                            }}
                                                        >
                                                            {hazard?.site?.abbreviation}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                fontWeight: '500',
                                                                color: '#202224',
                                                                lineHeight: '11px'
                                                            }}
                                                        >
                                                            {new Date(hazard.createdAt * 1000).toLocaleString('fr-FR', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short'
                                                            })}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                fontWeight: '500',
                                                                color: '#202224',
                                                                lineHeight: '11px'
                                                            }}
                                                        >
                                                            {new Date(hazard.observedAt * 1000).toLocaleString('fr-FR', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short'
                                                            })}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: getSeverityColor(hazard.severity),
                                                                fontWeight: '500',
                                                                whiteSpace: 'nowrap',
                                                                lineHeight: '11px'
                                                            }}
                                                        >
                                                            {getLabel(hazard.severity, severities)}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: getStatusColor(hazard.status),
                                                                fontWeight: '500',
                                                                whiteSpace: 'nowrap',
                                                                lineHeight: '11px'
                                                            }}
                                                        >
                                                            {getLabel(hazard.status, statusOptions)}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </InfiniteScroll>
                    </Box>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default DeclarationsList;
