import { combineReducers } from 'redux';
import {
    FETCH_ADMINS_REQUEST,
    FETCH_ADMINS_SUCCESS,
    FETCH_ADMINS_FAILURE,
    CREATE_ADMIN_REQUEST,
    CREATE_ADMIN_SUCCESS,
    CREATE_ADMIN_FAILURE,
    FETCH_ADMIN_REQUEST,
    FETCH_ADMIN_SUCCESS,
    FETCH_ADMIN_FAILURE,
    UPDATE_ADMIN_REQUEST,
    UPDATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_FAILURE
} from './adminActions';

// Reducer for Admin List
const initialAdminListState = {
    loading: false,
    admins: [],
    error: null
};

const adminListReducer = (state = initialAdminListState, action) => {
    switch (action.type) {
        case FETCH_ADMINS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_ADMINS_SUCCESS:
            return { ...state, loading: false, admins: action.payload };
        case FETCH_ADMINS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Admin Creation
const initialAdminCreateState = {
    loading: false,
    success: false,
    error: null
};

const adminCreateReducer = (state = initialAdminCreateState, action) => {
    switch (action.type) {
        case CREATE_ADMIN_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case CREATE_ADMIN_SUCCESS:
            return { ...state, loading: false, success: true };
        case CREATE_ADMIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Single Admin Details
const initialAdminDetailsState = {
    loading: false,
    admin: {},
    error: null
};

const adminDetailsReducer = (state = initialAdminDetailsState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_ADMIN_SUCCESS:
            return { ...state, loading: false, admin: action.payload };
        case FETCH_ADMIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer for Admin Update
const initialAdminUpdateState = {
    loading: false,
    success: false,
    error: null
};

const adminUpdateReducer = (state = initialAdminUpdateState, action) => {
    switch (action.type) {
        case UPDATE_ADMIN_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case UPDATE_ADMIN_SUCCESS:
            return { ...state, loading: false, success: true };
        case UPDATE_ADMIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Combine all reducers
const adminReducer = combineReducers({
    adminList: adminListReducer,
    adminCreate: adminCreateReducer,
    adminDetails: adminDetailsReducer,
    adminUpdate: adminUpdateReducer
});

export default adminReducer;
