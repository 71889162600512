import {
    CardContent,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { fetchRegionsStats } from 'store/statsActions';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DynamicLineChart from 'ui-component/charts/DynamicLikeChart';
import TotalHazardsCard from '../DynamicCard';
import RegionsRepportsSkeleton from 'ui-component/cards/Skeleton/RegionsRapportsSkeleton';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

const RegionsRepports = () => {
    const dispatch = useDispatch();
    const { regions: regionsData } = useSelector((state) => state.AnalyticsStats);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const chartsContainerRef = useRef(null);

    useEffect(() => {
        dispatch(fetchRegionsStats());
    }, [dispatch]);

    const transformBarChartData = (data) => {
        const categories = data?.series.map((project) => project?.name) || [];
        const statusNames = data?.categories || [];
        const series = statusNames?.map((status, index) => ({
            name: status,
            data: data?.series.map((project) => project?.data[index])
        }));
        return { categories, series, title: data?.title };
    };

    const reportedHazardsByRegionBarChart = useMemo(() => transformBarChartData(regionsData?.data?.reportedHazardsBarChart), [regionsData]);
    const hazardsStatusesByRegionBarBarChart = useMemo(
        () => transformBarChartData(regionsData?.data?.hazardsStatusesBarChart),
        [regionsData]
    );
    const reportedHazardsByRegionBarLineChart = useMemo(
        () => regionsData?.data?.reportedHazardsLineChart || { categories: [], series: [] },
        [regionsData]
    );
    const resolvedHazardsByRegionBarLineChart = useMemo(
        () => regionsData?.data?.resolvedHazardsLineChart || { categories: [], series: [] },
        [regionsData]
    );

    if (regionsData.loading) {
        return <RegionsRepportsSkeleton />;
    }

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <SubCard>
                        <CardContent>
                            <Typography variant="h3">Details chiffres par region</Typography>
                            <StyledTableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nom</TableCell>
                                            {/* <TableCell>Type</TableCell> */}
                                            <TableCell>Total</TableCell>
                                            <TableCell>Ouvert</TableCell>
                                            <TableCell>Résolu</TableCell>
                                            <TableCell>Validé</TableCell>
                                            <TableCell>Progression</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {regionsData?.data?.stats?.map((stat) => (
                                            <TableRow key={stat.id}>
                                                <TableCell>{stat.name}</TableCell>
                                                {/* <TableCell>{stat.type}</TableCell> */}
                                                <TableCell>{stat.total}</TableCell>
                                                <TableCell>{stat.open}</TableCell>
                                                <TableCell>{stat.resolved}</TableCell>
                                                <TableCell>{stat.validated}</TableCell>
                                                <TableCell>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={stat.total ? (stat.resolved / stat.total) * 100 : 0}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </StyledTableContainer>
                        </CardContent>
                    </SubCard>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <SubCard style={{ height: '100%' }}>
                        <DynamicLineChart
                            isHorizontal
                            data={hazardsStatusesByRegionBarBarChart}
                            xAxisTitle="Par projet"
                            yAxisTitle="Hazards"
                            type="bar"
                        />
                    </SubCard>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <DynamicLineChart
                            data={reportedHazardsByRegionBarLineChart}
                            xAxisTitle="Par région"
                            yAxisTitle="Hazards"
                            type="line"
                        />
                    </SubCard>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <DynamicLineChart
                            isHorizontal
                            data={reportedHazardsByRegionBarChart}
                            xAxisTitle="Par région"
                            yAxisTitle="Hazards"
                            type="bar"
                        />
                    </SubCard>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <DynamicLineChart
                            isHorizontal
                            data={resolvedHazardsByRegionBarLineChart}
                            xAxisTitle="Par région"
                            yAxisTitle="Hazards"
                            type="line"
                        />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default RegionsRepports;
