import React from 'react';
import {
    Grid,
    Skeleton,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    LinearProgress
} from '@mui/material';
import { styled } from '@mui/styles';
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

// eslint-disable-next-line arrow-body-style
const ProjectReportsSkeleton = () => {
    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <SubCard>
                        <CardContent>
                            <Skeleton variant="text" height={40} width="60%" />
                            <StyledTableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" width={100} />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.from({ length: 5 }).map((_, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Skeleton variant="text" width={100} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton variant="text" width={100} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton variant="text" width={100} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton variant="text" width={100} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton variant="text" width={100} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton variant="text" width={100} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton variant="text" width={100} />
                                                </TableCell>
                                                <TableCell>
                                                    <LinearProgress variant="indeterminate" />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </StyledTableContainer>
                        </CardContent>
                    </SubCard>
                </Grid>

                {/* Placeholder for charts */}
                <Grid item lg={9} md={9} xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={250} />
                    </SubCard>
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                    <Grid container spacing={gridSpacing}>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <Grid key={index} item xs={12}>
                                <Skeleton variant="rectangular" height={100} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* More chart skeletons */}
                <Grid item xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={250} />
                    </SubCard>
                </Grid>

                <Grid item xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={250} />
                    </SubCard>
                </Grid>

                <Grid item xs={12}>
                    <SubCard>
                        <Skeleton variant="rectangular" height={250} />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ProjectReportsSkeleton;
