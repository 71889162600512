import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { exportHazards } from 'store/hazardsActions';
import FileSaver from 'file-saver';

const ImportXlsxModal = () => {
    const dispatch = useDispatch();
    const { loading, error, data } = useSelector((state) => state.hazards.export);
    const [open, setOpen] = useState(false);

    const params = {
        page: 1,
        perPage: 15,
        search: '',
        category: '',
        status: '',
        severity: '',
        site: '',
        type: '',
        anonymous: false,
        newHazard: false,
        minDate: '',
        maxDate: ''
    };

    // Function to handle the export of hazards data
    const handleExport = () => {
        dispatch(
            exportHazards(
                params.page,
                params.perPage,
                params.search,
                params.category,
                params.status,
                params.severity,
                params.site,
                params.type,
                params.anonymous,
                params.newHazard,
                params.minDate,
                params.maxDate
            )
        );
    };

    // Effect to handle downloading the file once data is available
    useEffect(() => {
        if (data) {
            // Trigger download of the blob data using FileSaver
            FileSaver.saveAs(data, 'Liste_des_declarations.xlsx');
        }
        console.log('data : ', data);
    }, [data]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                sx={{
                    borderRadius: '20px',
                    padding: '6px 16px',
                    textTransform: 'capitalize',
                    backgroundColor: '#2962FF'
                }}
            >
                Exporter les Déclarations
            </Button>

            {/* Modal for exporting hazards data */}
            <Modal open={open} onClose={handleClose} aria-labelledby="export-modal-title" aria-describedby="export-modal-description">
                <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', width: '80%', height: '30vh' }}>
                    <Typography variant="h6" gutterBottom>
                        Exporter les Déclarations
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Êtes-vous sûr de vouloir exporter la liste des déclarations ?
                    </Typography>
                    {error && <Typography color="error">{error}</Typography>} {/* Display error if any */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleExport}
                        sx={{ marginRight: 2 }}
                        disabled={loading} // Disable button if loading
                    >
                        {loading ? <CircularProgress size={24} /> : 'Confirmer Exportation'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default ImportXlsxModal;
