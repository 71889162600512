import fetchApi from './api';

export const FETCH_PROVINCES_REQUEST = 'FETCH_PROVINCES_REQUEST';
export const FETCH_PROVINCES_SUCCESS = 'FETCH_PROVINCES_SUCCESS';
export const FETCH_PROVINCES_FAILURE = 'FETCH_PROVINCES_FAILURE';

export const fetchProvinces = () => async (dispatch) => {
    dispatch({ type: FETCH_PROVINCES_REQUEST });
    try {
        const response = await fetchApi('/provinces', { method: 'GET' });
        dispatch({ type: FETCH_PROVINCES_SUCCESS, payload: response?.data });
    } catch (error) {
        dispatch({ type: FETCH_PROVINCES_FAILURE, error: error.message });
    }
};
