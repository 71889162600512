import fetchApi from './api';

export const FETCH_AUDITS_REQUEST = 'FETCH_AUDITS_REQUEST';
export const FETCH_AUDITS_SUCCESS = 'FETCH_AUDITS_SUCCESS';
export const FETCH_AUDITS_FAILURE = 'FETCH_AUDITS_FAILURE';

export const fetchAudits =
    ({ page = 1, perPage = 15, search = '', minDate, maxDate, sortDir = 'desc', sortBy = 'created_at' }) =>
    async (dispatch) => {
        dispatch({ type: FETCH_AUDITS_REQUEST });
        try {
            const response = await fetchApi(
                `/audits?page=${page}&perPage=${perPage}&search=${search}&minDate=${minDate}&maxDate=${maxDate}&sortDir=${sortDir}&sortBy=${sortBy}`
            );
            const audits = await response.data;
            dispatch({
                type: FETCH_AUDITS_SUCCESS,
                payload: {
                    audits: audits.list,
                    pagination: audits.pagination,
                    page,
                    reset: page === 1
                }
            });
        } catch (error) {
            dispatch({
                type: FETCH_AUDITS_FAILURE,
                payload: error.message
            });
        }
    };
