// reducer.js
import {
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
    PASSWORD_RESET_VERIFY_CODE_REQUEST,
    PASSWORD_RESET_VERIFY_CODE_SUCCESS,
    PASSWORD_RESET_VERIFY_CODE_FAILURE,
    PASSWORD_RESET_RESET_REQUEST,
    PASSWORD_RESET_RESET_SUCCESS,
    PASSWORD_RESET_RESET_FAILURE
} from './resetPasswordActions';

const initialState = {
    loading: false,
    resetRequestSuccess: false,
    verifyCodeSuccess: false,
    resetPasswordSuccess: false,
    error: null
};

const passwordResetReducer = (state = initialState, action) => {
    switch (action.type) {
        case PASSWORD_RESET_REQUEST:
        case PASSWORD_RESET_VERIFY_CODE_REQUEST:
        case PASSWORD_RESET_RESET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                resetRequestSuccess: true,
                error: null
            };

        case PASSWORD_RESET_VERIFY_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyCodeSuccess: true,
                error: null
            };

        case PASSWORD_RESET_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                resetPasswordSuccess: true,
                error: null
            };

        case PASSWORD_RESET_FAILURE:
        case PASSWORD_RESET_VERIFY_CODE_FAILURE:
        case PASSWORD_RESET_RESET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default passwordResetReducer;
