import React from 'react';
import { Dialog, Button, Typography, Box } from '@mui/material';
import { ReactComponent as CheckMarkIcon } from 'assets/images/icons/check-mark-done.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/error-icon.svg'; // Add your error icon path

const StatusModal = ({ open, handleClose, content, title, icon }) => (
    <Dialog open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
            sx={{
                bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
                borderRadius: 2,
                width: 500,
                textAlign: 'center'
            }}
        >
            {icon === 'success' ? <CheckMarkIcon sx={{ fontSize: '24px', mb: 2 }} /> : <ErrorIcon sx={{ fontSize: '24px', mb: 2 }} />}
            <Typography variant="h2" sx={{ color: 'text.primary' }} gutterBottom>
                {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {content}
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ backgroundColor: '#1F41BB', padding: '8px 30px' }}
                    onClick={handleClose}
                >
                    OK
                </Button>
            </Box>
        </Box>
    </Dialog>
);

export default StatusModal;
