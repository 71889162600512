import fetchApi from './api';

export const FETCH_SITES_REQUEST = 'FETCH_SITES_REQUEST';
export const FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS';
export const FETCH_SITES_FAILURE = 'FETCH_SITES_FAILURE';

export const fetchSites =
    ({ page = 1, perPage = 15, sortDir = 'desc', sortBy = 'id', search = '', region = '', province = '' } = {}) =>
    async (dispatch) => {
        dispatch({ type: FETCH_SITES_REQUEST });
        try {
            // Construct query parameters
            const queryParams = new URLSearchParams({
                page,
                perPage,
                sortDir,
                sortBy
            });

            if (search) queryParams.append('search', search);
            if (region) queryParams.append('region', region);
            if (province) queryParams.append('province', province);

            // Make sure to use '?' to start the query parameters in the URL
            const response = await fetchApi(`/sites?${queryParams.toString()}`);

            dispatch({
                type: FETCH_SITES_SUCCESS,
                payload: response?.data
            });
        } catch (error) {
            dispatch({
                type: FETCH_SITES_FAILURE,
                payload: error.message
            });
        }
    };

export const REGISTER_SITE_REQUEST = 'REGISTER_SITE_REQUEST';
export const REGISTER_SITE_SUCCESS = 'REGISTER_SITE_SUCCESS';
export const REGISTER_SITE_FAILURE = 'REGISTER_SITE_FAILURE';

export const registerSite = (siteData) => async (dispatch) => {
    dispatch({ type: REGISTER_SITE_REQUEST });
    try {
        const response = await fetchApi(`/sites`, { method: 'POST', body: JSON.stringify(siteData) });
        console.log(response);
        dispatch({
            type: REGISTER_SITE_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: REGISTER_SITE_FAILURE,
            payload: error.message
        });
    }
};

export const UPDATE_SITE_REQUEST = 'UPDATE_SITE_REQUEST';
export const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_FAILURE = 'UPDATE_SITE_FAILURE';

export const updateSite = (siteId, siteData) => async (dispatch) => {
    dispatch({ type: UPDATE_SITE_REQUEST });
    try {
        const response = await fetchApi.put(`/sites/${siteId}`, siteData);
        dispatch({
            type: UPDATE_SITE_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SITE_FAILURE,
            payload: error.message
        });
    }
};

export const DELETE_SITE_REQUEST = 'DELETE_SITE_REQUEST';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE';

export const deleteSite = (siteId) => async (dispatch) => {
    dispatch({ type: DELETE_SITE_REQUEST });
    try {
        await fetchApi.delete(`/sites/${siteId}`);
        dispatch({
            type: DELETE_SITE_SUCCESS,
            payload: siteId
        });
    } catch (error) {
        dispatch({
            type: DELETE_SITE_FAILURE,
            payload: error.message
        });
    }
};

export const RESTORE_SITE_REQUEST = 'RESTORE_SITE_REQUEST';
export const RESTORE_SITE_SUCCESS = 'RESTORE_SITE_SUCCESS';
export const RESTORE_SITE_FAILURE = 'RESTORE_SITE_FAILURE';

export const restoreSite = (siteId) => async (dispatch) => {
    dispatch({ type: RESTORE_SITE_REQUEST });
    try {
        const response = await fetchApi.patch(`/sites/${siteId}/restore`);
        dispatch({
            type: RESTORE_SITE_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: RESTORE_SITE_FAILURE,
            payload: error.message
        });
    }
};
