// assets
import { ReactComponent as IconDashboard } from '../assets/images/icons/dashboard_active.svg';
import { ReactComponent as IconDashboardInctive } from '../assets/images/icons/dashboard_inactive.svg';

import { ReactComponent as IconGestionHazard } from '../assets/images/icons/gestion_hazarrds_inactive.svg';
import { ReactComponent as IconGestionEmploye } from '../assets/images/icons/gestion_employes_active.svg';
import { ReactComponent as IconGestionEmployeInctive } from '../assets/images/icons/gestion_employes_inactive.svg';

import { ReactComponent as IconGestionAdmin } from '../assets/images/icons/gestion_admin_active.svg';
import { ReactComponent as IconGestionAdminInctive } from '../assets/images/icons/gestion_admin_inactive.svg';

import { ReactComponent as IconGestionRegion } from '../assets/images/icons/gestion_region_active.svg';
import { ReactComponent as IconGestionRegionInctive } from '../assets/images/icons/gestion_region_inactive.svg';

import { ReactComponent as IconGestionSite } from '../assets/images/icons/gestion_site_active.svg';
import { ReactComponent as IconGestionSiteInctive } from '../assets/images/icons/gestion_site_inactive.svg';

import { ReactComponent as IconGestionACP } from '../assets/images/icons/gestion_acp_active.svg';
import { ReactComponent as IconGestionACPInctive } from '../assets/images/icons/gestion_acp_inactive.svg';

import { ReactComponent as IconConfiguration } from '../assets/images/icons/configuration_inactive.svg';
import { ReactComponent as IconTheme } from '../assets/images/icons/theme.svg';

import { IconHelp } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDashboardInctive,
    IconGestionHazard,
    IconGestionEmploye,
    IconGestionEmployeInctive,
    IconGestionAdmin,
    IconGestionAdminInctive,
    IconGestionRegion,
    IconGestionRegionInctive,
    IconGestionACP,
    IconGestionACPInctive,
    IconGestionSite,
    IconGestionSiteInctive,
    IconConfiguration,
    IconTheme,
    IconHelp
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Menu',
    type: 'group',
    url: '/dashboard/default',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            iconInactive: icons.IconDashboardInctive,
            statsVisible: false,
            breadcrumbs: false
            // permissions: [] // No permissions required
        },
        {
            id: 'gestion_des_hazards',
            title: 'Gestion des Hazards',
            type: 'collapse',
            icon: icons.IconGestionHazard,
            iconInactive: icons.IconGestionHazard,
            breadcrumbs: false,
            statsVisible: true,
            permissions: ['index_hazards'],
            children: [
                {
                    id: 'liste_des_declarations',
                    title: 'Liste des Déclarations',
                    type: 'item',
                    url: '/gestion-des-hazards/declarations',
                    statsVisible: true,
                    breadcrumbs: true,
                    permissions: ['index_hazards', 'view_hazards']
                },
                {
                    id: 'suivi_historique',
                    title: 'Suivi Historique',
                    type: 'item',
                    url: '/gestion-des-hazards/historiques',
                    statsVisible: false,
                    breadcrumbs: true,
                    permissions: ['view_hazards']
                }
            ]
        },
        {
            id: 'gestion_des_acp',
            title: 'Gestion des ACP',
            type: 'item',
            url: '/gestion-des-acp',
            icon: icons.IconGestionACP,
            iconInactive: icons.IconGestionACPInctive,
            statsVisible: true,
            breadcrumbs: false,
            permissions: ['index_actions', 'view_actions']
        },
        {
            id: 'gestion_des_employes',
            title: 'Gestion des Employés',
            type: 'item',
            icon: icons.IconGestionEmploye,
            iconInactive: icons.IconGestionEmployeInctive,
            statsVisible: true,
            breadcrumbs: true,
            url: '/gestion-des-employes/employes',
            permissions: ['index_employees', 'view_employees', 'archive_employees']
        },
        {
            id: 'gestion_des_admins',
            title: 'Gestion des Admins',
            type: 'collapse',
            icon: icons.IconGestionAdminInctive,
            iconInactive: icons.IconGestionAdminInctive,
            statsVisible: true,
            breadcrumbs: true,
            permissions: ['index_admins'],
            children: [
                {
                    id: 'liste_des_admins',
                    title: 'Liste des admins',
                    type: 'item',
                    url: '/gestion-des-admins/admins',
                    statsVisible: false,
                    breadcrumbs: true,
                    permissions: ['index_admins']
                },
                {
                    id: 'gestion_des_roles',
                    title: 'Gestion des rôles',
                    type: 'item',
                    url: '/gestion-des-admins/roles',
                    statsVisible: false,
                    breadcrumbs: true
                    // permissions: []
                }
            ]
        },
        {
            id: 'gestion_des_regions',
            title: 'Gestion des Sites et Régions',
            type: 'collapse',
            icon: icons.IconGestionRegionInctive,
            iconInactive: icons.IconGestionRegionInctive,
            statsVisible: true,
            breadcrumbs: true,
            permissions: ['index_sites', 'index_regions'],
            children: [
                {
                    id: 'liste_des_regions',
                    title: 'Liste des régions',
                    type: 'item',
                    url: '/gestion-des-regions/regions',
                    statsVisible: true,
                    breadcrumbs: true,
                    permissions: ['index_regions', 'view_regions']
                },
                {
                    id: 'liste_des_sites',
                    title: 'Gestion des sites',
                    type: 'item',
                    url: '/gestion-des-sites/sites',
                    statsVisible: true,
                    breadcrumbs: true,
                    permissions: ['index_sites', 'view_sites']
                }
            ]
        },
        {
            id: 'analytics',
            title: 'Analytique',
            type: 'collapse',
            url: '/analytiques',
            icon: icons.IconDashboardInctive,
            iconInactive: icons.IconDashboardInctive,
            statsVisible: true,
            breadcrumbs: false,
            children: [
                {
                    id: 'rapports_par_projet',
                    title: 'Rapport par projet',
                    type: 'item',
                    url: '/analytiques/projets',
                    statsVisible: true,
                    breadcrumbs: true
                },
                {
                    id: 'rapports_par_region',
                    title: 'Rapport par région',
                    type: 'item',
                    url: '/analytiques/regions',
                    statsVisible: true,
                    breadcrumbs: true
                },
                {
                    id: 'rapports_society',
                    title: 'Statistiques globales au niveau de la société',
                    type: 'item',
                    url: '/analytiques/society',
                    statsVisible: true,
                    breadcrumbs: true
                },
                {
                    id: 'rapports_participations',
                    title: 'Participation et répartition des reportings',
                    type: 'item',
                    url: '/analytiques/participations',
                    statsVisible: true,
                    breadcrumbs: true
                },
                {
                    id: 'rapports_par_sites',
                    title: 'Statistiques par site et effectif',
                    type: 'item',
                    url: '/analytiques/sites',
                    statsVisible: true,
                    breadcrumbs: true
                }
            ],
            permissions: ['view_analytics']
        },
        {
            id: 'configuration_de_la_plateforme',
            title: 'Configuration de la Plateforme',
            type: 'item',
            url: '/configurations',
            icon: icons.IconConfiguration,
            iconInactive: icons.IconConfiguration,
            statsVisible: false,
            breadcrumbs: false,
            permissions: ['configure_platform']
        }
    ]
};

export default dashboard;
