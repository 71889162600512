import fetchApi from './api'; // Assuming your API utility is imported

// Action Types
export const FETCH_ACTIONS_REQUEST = 'FETCH_ACTIONS_REQUEST';
export const FETCH_ACTIONS_SUCCESS = 'FETCH_ACTIONS_SUCCESS';
export const FETCH_ACTIONS_FAILURE = 'FETCH_ACTIONS_FAILURE';

export const CREATE_ACTION_PLAN_REQUEST = 'CREATE_ACTION_PLAN_REQUEST';
export const CREATE_ACTION_PLAN_SUCCESS = 'CREATE_ACTION_PLAN_SUCCESS';
export const CREATE_ACTION_PLAN_FAILURE = 'CREATE_ACTION_PLAN_FAILURE';

// Action Creators for fetching actions
export const fetchActionsRequest = () => ({
    type: FETCH_ACTIONS_REQUEST
});

export const fetchActionsSuccess = (data) => ({
    type: FETCH_ACTIONS_SUCCESS,
    payload: data
});

export const fetchActionsFailure = (error) => ({
    type: FETCH_ACTIONS_FAILURE,
    payload: error
});

// Async action for fetching actions (Thunk)
export const fetchActions =
    (page = 1, perPage = 15, search = '', site = '', status = '', type = '') =>
    async (dispatch) => {
        dispatch(fetchActionsRequest());
        try {
            const queryParams = new URLSearchParams({
                page,
                perPage,
                sortDir: 'desc',
                sortBy: 'id'
            });

            // Append parameters correctly
            if (search) queryParams.append('search', search);
            if (site) queryParams.append('site', site); // Site ID or list of site IDs
            if (status) queryParams.append('status', status); // Status like 'open', 'completed'
            if (type) queryParams.append('type', type); // Type of action (e.g., 'corrective')

            // Fetch the data from the API
            const response = await fetchApi(`/actions?${queryParams.toString()}`);

            console.log(response?.data);
            dispatch(fetchActionsSuccess(response?.data));
        } catch (error) {
            dispatch(fetchActionsFailure(error.message));
        }
    };

// Action Creators for creating a new action plan
export const createActionPlanRequest = () => ({
    type: CREATE_ACTION_PLAN_REQUEST
});

export const createActionPlanSuccess = (data) => ({
    type: CREATE_ACTION_PLAN_SUCCESS,
    payload: data
});

export const createActionPlanFailure = (error) => ({
    type: CREATE_ACTION_PLAN_FAILURE,
    payload: error
});

// Async action for creating a new action plan (Thunk)
export const createActionPlan = (newActionData) => async (dispatch) => {
    dispatch(createActionPlanRequest());
    try {
        const response = await fetchApi('/actions', {
            method: 'POST',
            body: JSON.stringify(newActionData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(createActionPlanSuccess(response.data));
    } catch (error) {
        dispatch(createActionPlanFailure(error.message));
    }
};
