import {
    CardContent,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { fetchParticipationsStats } from 'store/statsActions';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DynamicLineChart from 'ui-component/charts/DynamicLikeChart';
import TotalHazardsCard from '../DynamicCard';
import ParticipationRepartitionReportingsSkeleton from 'ui-component/cards/Skeleton/ParticipationsRapportsSkeleton';

const MAX_HEIGHT = 600;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius
}));

const ParticipationRepartitionReportings = () => {
    const dispatch = useDispatch();
    const { participations: participationsData } = useSelector((state) => state.AnalyticsStats);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const chartsContainerRef = useRef(null);

    useEffect(() => {
        dispatch(fetchParticipationsStats());
    }, [dispatch]);

    const transformBarChartData = (data) => {
        const categories = data?.series?.map((project) => project?.name) || [];
        const statusNames = data?.categories || [];
        const series = statusNames.map((status, index) => ({
            name: status,
            data: data?.series?.map((project) => project.data[index])
        }));
        return { categories, series, title: data?.title };
    };

    const reportedParticipationsHazards = useMemo(() => participationsData?.data?.reportedHazards, [participationsData]);
    const participationReporting = useMemo(() => transformBarChartData(participationsData?.data?.barChart), [participationsData]);

    if (participationsData.loading) {
        return <ParticipationRepartitionReportingsSkeleton />;
    }

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }} lg={12}>
                    <Grid lg={12} container spacing={gridSpacing} sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                        <Grid item sm={12} md={12} lg={4}>
                            <TotalHazardsCard
                                isLoading={false}
                                count={participationsData?.data?.participations}
                                name="Total participations"
                                type="light"
                            />
                        </Grid>
                        <Grid item sm={12} md={12} lg={4}>
                            <TotalHazardsCard
                                isLoading={false}
                                count={reportedParticipationsHazards?.sarpiStaff}
                                name="Sarpi staff"
                                type="blue"
                            />
                        </Grid>
                        <Grid item sm={12} md={12} lg={4}>
                            <TotalHazardsCard
                                isLoading={false}
                                count={reportedParticipationsHazards?.subcontractors}
                                name="Sous traitants"
                                type="light"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <SubCard>
                        <DynamicLineChart
                            // isHorizontal
                            isStacked
                            data={participationReporting}
                            xAxisTitle="Statistiques"
                            yAxisTitle="Participation"
                            type="bar"
                        />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ParticipationRepartitionReportings;
