const { FETCH_PROVINCES_REQUEST, FETCH_PROVINCES_SUCCESS, FETCH_PROVINCES_FAILURE } = require('./provincesActions');

const initialState = {
    provinces: [],
    loading: false,
    error: null
};

const provinceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROVINCES_REQUEST:
            return { ...state, loading: true };
        case FETCH_PROVINCES_SUCCESS:
            return { ...state, loading: false, provinces: action.payload };
        case FETCH_PROVINCES_FAILURE:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default provinceReducer;
