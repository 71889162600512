import fetchApi from './api';

// Action Types
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const MARK_NOTIFICATION_READ_REQUEST = 'MARK_NOTIFICATION_READ_REQUEST';
export const MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_FAILURE = 'MARK_NOTIFICATION_READ_FAILURE';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_READ_SUCCESS';

// Action to fetch notifications with pagination support
export const fetchNotifications =
    (page = 1) =>
    async (dispatch) => {
        dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });
        try {
            const response = await fetchApi(`/notifications?page=${page}`);
            if (response.status === 'success') {
                dispatch({
                    type: FETCH_NOTIFICATIONS_SUCCESS,
                    payload: response.data
                });
            } else {
                dispatch({
                    type: FETCH_NOTIFICATIONS_FAILURE,
                    error: response.message || 'Failed to fetch notifications'
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_NOTIFICATIONS_FAILURE,
                error: error.message || 'An error occurred while fetching notifications'
            });
        }
    };

// Action to mark a single notification as read
export const markNotificationAsRead = (notificationId) => async (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_READ_REQUEST });
    try {
        const response = await fetchApi(`/notifications/${notificationId}/read`, {
            method: 'PATCH'
        });
        if (response.status === 'success') {
            dispatch({
                type: MARK_NOTIFICATION_READ_SUCCESS,
                notificationId
            });
        } else {
            dispatch({
                type: MARK_NOTIFICATION_READ_FAILURE,
                error: response.message || 'Failed to mark notification as read'
            });
        }
    } catch (error) {
        dispatch({
            type: MARK_NOTIFICATION_READ_FAILURE,
            error: error.message || 'An error occurred while marking notification as read'
        });
    }
};

// Action to mark all notifications as read
export const markAllNotificationsAsRead = () => async (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_READ_REQUEST });
    try {
        const response = await fetchApi('/notifications', {
            method: 'PATCH'
        });
        if (response.status === 'success') {
            dispatch({
                type: MARK_ALL_NOTIFICATIONS_READ_SUCCESS
            });
        } else {
            dispatch({
                type: MARK_NOTIFICATION_READ_FAILURE,
                error: response.message || 'Failed to mark all notifications as read'
            });
        }
    } catch (error) {
        dispatch({
            type: MARK_NOTIFICATION_READ_FAILURE,
            error: error.message || 'An error occurred while marking all notifications as read'
        });
    }
};
