import fetchApi from './api';

// Action Types
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

// Action Creators
export const fetchUserRequest = () => ({
    type: FETCH_USER_REQUEST
});

export const fetchUserSuccess = (data) => ({
    type: FETCH_USER_SUCCESS,
    payload: data
});

export const fetchUserFailure = (error) => ({
    type: FETCH_USER_FAILURE,
    payload: error
});

export const fetchUser = () => async (dispatch) => {
    dispatch(fetchUserRequest());

    try {
        const data = await fetchApi('/users/me');

        if (data?.status === 'success') {
            dispatch(fetchUserSuccess(data.data));
        } else {
            localStorage.removeItem('authToken');
            window.location.href = '/login';
            dispatch(fetchUserFailure(data.message || 'An error occurred'));
        }
    } catch (error) {
        // If there's a network error or other issue, log out and redirect
        localStorage.removeItem('authToken');
        window.location.href = '/login';
        dispatch(fetchUserFailure(error.toString()));
    }
};
