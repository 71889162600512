import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import { fr } from 'date-fns/locale'; // Import French locale
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useTheme } from '@emotion/react';

const DateRangePicker = ({ onDateChange }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        calendarWrapper: {
            padding: '16px'
        },
        textField: {
            maxWidth: '250px',
            borderBottom: 'none',
            '& .MuiInputBase-input': {
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: theme.palette.grey[700],
                opacity: 1
            },
            '& .MuiOutlinedInput-input': {
                borderBottom: 'none'
            }
        },
        select: {
            height: 29,
            '& .MuiSelect-root': {
                color: theme.palette.text.dark,
                border: 'none',
                backgroundColor: theme.palette.background.paper
            },
            '& .MuiSelect-select::placeholder': {
                color: theme.palette.text.disabled
            }
        },
        label: {
            fontWeight: '600',
            color: theme.palette.text.dark,
            borderBottom: 'none'
        }
    });
    const classes = useStyles();
    const dateFormat = 'DD/MM/YYYY';

    const [displayCalendar, setDisplayCalendar] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);

    const onAdornmentClick = (e) => {
        setDisplayCalendar(true);
        setAnchorEl(e.currentTarget);
    };

    const processInputValue = (value) => {
        let [fromDate, toDate] = value.split('-').map((elm) => elm.trim());

        fromDate = moment(fromDate, dateFormat).isValid() ? moment(fromDate, dateFormat).toDate() : undefined;
        toDate = moment(toDate, dateFormat).isValid() ? moment(toDate, dateFormat).toDate() : undefined;

        return { fromDate, toDate };
    };

    const onInputChange = (e) => {
        const inputValue = e.target.value;
        const { fromDate, toDate } = processInputValue(inputValue);

        setInputValue(inputValue);
        setFromDate(fromDate);
        setToDate(toDate);
    };

    const onPopoverClose = () => {
        setDisplayCalendar(false);
        setAnchorEl(null);
    };

    const onSelectDateRanges = ({ selection }) => {
        const { startDate, endDate } = selection;

        const newFromDate = moment(startDate).isValid() ? moment(startDate).toDate() : undefined;
        const newToDate = moment(endDate).isValid() ? moment(endDate).toDate() : undefined;

        setFromDate(newFromDate);
        setToDate(newToDate);

        // Update input value only if both dates are valid
        if (newFromDate && newToDate) {
            setInputValue(`${moment(newFromDate).format(dateFormat)} - ${moment(newToDate).format(dateFormat)}`);

            // Convert to timestamps
            if (onDateChange) {
                onDateChange({
                    minDate: Math.floor(newFromDate.getTime() / 1000),
                    maxDate: Math.floor(newToDate.getTime() / 1000)
                });
            }
        } else {
            // Reset input if either date is invalid
            setInputValue('');

            // Default values if onDateChange is not provided or if dates are invalid
            if (onDateChange) {
                onDateChange({
                    minDate: 0,
                    maxDate: Math.floor(new Date().getTime() / 1000)
                });
            }
        }
    };

    return (
        <div>
            <TextField
                placeholder="Date"
                variant="outlined"
                className={classes.textField}
                value={inputValue}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onAdornmentClick}>
                                <DateRangeIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                onChange={onInputChange}
            />
            <Popover
                open={displayCalendar}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={onPopoverClose}
            >
                <div className={classes.calendarWrapper}>
                    <DateRange
                        ranges={[
                            {
                                startDate: fromDate,
                                endDate: toDate,
                                key: 'selection'
                            }
                        ]}
                        onChange={onSelectDateRanges}
                        maxDate={new Date()}
                        locale={fr}
                        showMonthAndYearPickers
                        ariaLabels={{
                            monthPicker: 'month picker',
                            yearPicker: 'year picker',
                            prevButton: 'previous month button',
                            nextButton: 'next month button'
                        }}
                        showSelectionPreview
                        months={2}
                        direction="horizontal"
                        moveRangeOnFirstSelection={false}
                        showDateDisplay={false}
                        scroll={{ enabled: true }}
                    />
                </div>
            </Popover>
        </div>
    );
};

export default DateRangePicker;
