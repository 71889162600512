const wilaya = {
    Adrar: { name: 'Adrar', value: 120, code: 1, color: 'red' },
    Alger: { name: 'Alger', value: 60, code: 16 },
    Annaba: { name: 'Annaba', value: 22, code: 23, color: '#58B19F' },
    'Aïn Defla': { name: 'Aïn Defla', value: 43, code: 44, color: '#daa520' },
    'Aïn Témouchent': { name: 'Aïn Témouchent', value: 45, code: 46, color: '#daa520' },
    Batna: { name: 'Batna', value: 4, code: 5, color: '#58B19F' },
    Biskra: { name: 'Biskra', value: 6, code: 7, color: '#58B19F' },
    Blida: { name: 'Blida', value: 8, code: 9, color: '#58B19F' },
    'Bordj Badji Mokhtar': { name: 'Bordj Badji Mokhtar', value: 49, code: 54, color: '#f5d76e' },
    'Bordj Bou Arreridj': { name: 'Bordj Bou Arreridj', value: 33, code: 34, color: '#58B19F' },
    Bouira: { name: 'Bouira', value: 9, code: 10, color: '#58B19F' },
    Boumerdès: { name: 'Boumerdès', value: 34, code: 35, color: '#58B19F' },
    Béchar: { name: 'Béchar', value: 7, code: 8, color: '#f5d76e' },
    Béjaïa: { name: 'Béjaïa', value: 5, code: 6, color: '#58B19F' },
    'Béni Abbès': { name: 'Béni Abbès', value: 51, code: 49, color: '#f5d76e' },
    Chlef: { name: 'Chlef', value: 1, code: 2, color: '#daa520' },
    Constantine: { name: 'Constantine', value: 24, code: 25, color: '#58B19F' },
    Djanet: { name: 'Djanet', value: 55, code: 54, color: '#f5d76e' },
    Djelfa: { name: 'Djelfa', value: 16, code: 17, color: '#58B19F' },
    'El Bayadh': { name: 'El Bayadh', value: 31, code: 32, color: '#daa520' },
    'El Meghaier': { name: 'El Meghaier', value: 56, code: 55, color: '#f5d76e' },
    'El Menia': { name: 'El Menia', value: 57, code: 56, color: '#f5d76e' },
    'El Oued': { name: 'El Oued', value: 38, code: 39, color: '#f5d76e' },
    'El Tarf': { name: 'El Tarf', value: 35, code: 36, color: '#58B19F' },
    Ghardaïa: { name: 'Ghardaïa', value: 46, code: 45, color: '#f5d76e' },
    Guelma: { name: 'Guelma', value: 23, code: 24, color: '#58B19F' },
    Illizi: { name: 'Illizi', value: 32, code: 33, color: '#f5d76e' },
    'In Guezzam': { name: 'In Guezzam', value: 53, code: 55, color: '#f5d76e' },
    'In Salah': { name: 'In Salah', value: 52, code: 54, color: '#f5d76e' },
    Jijel: { name: 'Jijel', value: 17, code: 18, color: '#58B19F' },
    Khenchela: { name: 'Khenchela', value: 39, code: 40, color: '#58B19F' },
    Laghouat: { name: 'Laghouat', value: 2, code: 3, color: '#daa520' },
    "M'Sila": { name: "M'Sila", value: 27, code: 28, color: '#58B19F' },
    Mascara: { name: 'Mascara', value: 28, code: 29, color: '#daa520' },
    Mila: { name: 'Mila', value: 42, code: 43, color: '#58B19F' },
    Mostaganem: { name: 'Mostaganem', value: 26, code: 27, color: '#daa520' },
    Médéa: { name: 'Médéa', value: 25, code: 26, color: '#daa520' },
    Naâma: { name: 'Naâma', value: 44, code: 42, color: '#f5d76e' },
    Oran: { name: 'Oran', value: 30, code: 31, color: '#daa520' },
    Ouargla: { name: 'Ouargla', value: 29, code: 30, color: '#f5d76e' },
    'Ouled Djellal': { name: 'Ouled Djellal', value: 50, code: 49, color: '#f5d76e' },
    'Oum El Bouaghi': { name: 'Oum El Bouaghi', value: 3, code: 4, color: '#58B19F' },
    Relizane: { name: 'Relizane', value: 47, code: 52, color: '#daa520' },
    Saïda: { name: 'Saïda', value: 19, code: 20, color: '#daa520' },
    'Sidi Bel Abbès': { name: 'Sidi Bel Abbès', value: 21, code: 22, color: '#daa520' },
    Skikda: { name: 'Skikda', value: 20, code: 21, color: '#58B19F' },
    'Souk Ahras': { name: 'Souk Ahras', value: 40, code: 41, color: '#58B19F' },
    Sétif: { name: 'Sétif', value: 18, code: 19, color: '#58B19F' },
    Tamanrasset: { name: 'Tamanrasset', value: 59, code: 58, color: '#f5d76e' },
    Tiaret: { name: 'Tiaret', value: 13, code: 14, color: '#daa520' },
    Timimoun: { name: 'Timimoun', value: 48, code: 57, color: '#f5d76e' },
    Tindouf: { name: 'Tindouf', value: 36, code: 37, color: '#f5d76e' },
    Tipaza: { name: 'Tipaza', value: 41, code: 42, color: '#58B19F' },
    Tissemsilt: { name: 'Tissemsilt', value: 37, code: 38, color: '#daa520' },
    'Tizi Ouzou': { name: 'Tizi Ouzou', value: 14, code: 15, color: '#58B19F' },
    Tlemcen: { name: 'Tlemcen', value: 12, code: 13, color: '#daa520' },
    Touggourt: { name: 'Touggourt', value: 54, code: 53, color: '#f5d76e' },
    Tébessa: { name: 'Tébessa', value: 11, code: 12 }
};

export default wilaya;
