import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import { ReactComponent as IconLent } from '../../assets/images/icons/lent.svg';
import { ReactComponent as IconAvance } from '../../assets/images/icons/avancer.svg';
import { gridSpacing } from 'store/constant';
import { useSelector } from 'react-redux';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    height: 138,
    backgroundColor: theme.palette.background.paper,
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        borderRadius: '50%',
        top: -20,
        right: -180
        // backgroundColor: theme.palette.primary.light
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        borderRadius: '50%',
        top: -160,
        right: -130
        // backgroundColor: theme.palette.grey[200]
    }
}));

const StatsCard = ({ isLoading, title, counter, type, percentage, svgIcon }) => {
    const theme = useTheme();
    const Icon = type === 'lent' ? IconLent : IconAvance;
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper
                    border={false}
                    content={false}
                    sx={{
                        marginInlineEnd: 1,
                        // marginInlineStart: leftDrawerOpened ? 0 : gridSpacing,
                        marginBlockEnd: 1
                    }}
                >
                    <Box sx={{ p: 1, justifyContent: 'space-between', height: '100%' }}>
                        <List>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemText
                                    sx={{ py: 0, ml: 2 }}
                                    primary={
                                        <Typography sx={{ color: theme.palette.text.primary, opacity: '75%' }} variant="h6">
                                            {title}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography sx={{ mt: 0.5, color: theme.palette.text.primary }} variant="h3">
                                            {counter || 0}
                                        </Typography>
                                    }
                                />
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            position: 'absolute',
                                            top: 5,
                                            right: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            backgroundColor: 'transparent',
                                            color: type === 'lent' ? theme.palette.warning.dark : theme.palette.success.dark
                                        }}
                                        // src={svgIcon}
                                    >
                                        <img src={svgIcon} alt="Icon" style={{ width: 25, height: 25 }} />
                                    </Avatar>
                                </ListItemAvatar>
                            </ListItem>
                        </List>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 10,
                                left: 16,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start'
                            }}
                        >
                            <Icon fontSize="inherit" />
                            <Typography sx={{ color: type === 'lent' ? theme.palette.warning.dark : theme.palette.success.dark, mx: 1 }}>
                                {percentage}%
                            </Typography>
                            <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, mx: 1 }}>
                                {type === 'lent' ? 'Lent' : 'Avancé'}
                            </Typography>
                        </Box>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

StatsCard.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    counter: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['lent', 'avance']).isRequired,
    percentage: PropTypes.number.isRequired,
    svgIcon: PropTypes.string.isRequired
};

export default StatsCard;
