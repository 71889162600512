import { throttle } from 'lodash';

// Request queue to manage requests in sequence
const requestQueue = [];
let isRequestInProgress = false;

// Actual API request logic (this is what gets throttled and queued)
const fetchApiWithoutQueue = async (url, options = {}) => {
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('authToken');
    const headers = {
        Accept: 'application/json',
        'Accept-Language': 'fr',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await fetch(`${BASE_URL}${url}`, {
            ...options,
            headers: {
                ...headers,
                ...options.headers
            }
        });

        // Handle 204 No Content response
        if (response.status === 204) {
            return {};
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};
// Function to process the next request in the queue
const processQueue = async () => {
    if (isRequestInProgress || requestQueue.length === 0) return;

    isRequestInProgress = true;
    const { url, options, resolve, reject } = requestQueue.shift();

    try {
        const result = await fetchApiWithoutQueue(url, options);
        resolve(result);
    } catch (error) {
        reject(error);
    } finally {
        isRequestInProgress = false;
        setTimeout(processQueue, 0);
    }
};

// Throttled function to add requests to the queue
const throttledFetchApi = throttle((url, options, resolve, reject) => {
    requestQueue.push({ url, options, resolve, reject });
    processQueue();
}, 1000); // Throttle: 1.5 request per second

// Main API function that returns a promise
const fetchApi = async (url, options = {}, isDownload = false) =>
    // eslint-disable-next-line consistent-return
    new Promise((resolve, reject) => {
        if (isDownload) {
            console.log('download');
            // Directly process download requests without throttling
            return fetchApiWithoutQueue(url, options).then(resolve).catch(reject);
        }
        throttledFetchApi(url, options, resolve, reject);
    });

export default fetchApi;
