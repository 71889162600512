import React, { useState, useEffect, useCallback } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

const DynamicPieChart = ({ data }) => {
    const [chartDimensions, setChartDimensions] = useState({
        width: window?.innerWidth * 0.2,
        height: window?.innerHeight * 0.2
    });
    const theme = useTheme();

    const updateChartDimensions = useCallback(() => {
        const container = document.querySelector('.charts-container');
        const width = container?.offsetWidth || window?.innerWidth * 0.2;
        const height = width;
        setChartDimensions({ width, height });
    }, []);

    useEffect(() => {
        window?.addEventListener('resize', updateChartDimensions);
        updateChartDimensions();

        return () => {
            window.removeEventListener('resize', updateChartDimensions);
        };
    }, [updateChartDimensions]);

    const { labels = [], series = [], title = 'Diagramme circulaire' } = data || {};

    const chartOptions = {
        chart: {
            type: 'donut',
            width: 200,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: false,
                    customIcons: []
                }
            }
        },
        labels,
        colors: [theme.palette.success.dark, theme.palette.primary.main, theme.palette.warning.dark],
        title: {
            text: title,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: '600'
                // color: theme.palette.text.dark
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: true,
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            fontSize: '16px',
                            color: theme.palette.text.dark,
                            fontWeight: '600'
                        }
                    }
                }
                // startAngle: -90,
                // endAngle: 90,
                // offsetY: 10
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontWeight: 500
        },
        dataLabels: {
            enabled: true
        },
        fill: {
            type: 'gradient'
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                    // title: {
                    //     text: title,
                    //     align: 'start',
                    //     // offsetX: 20,
                    //     overflowX: 'scroll',
                    //     style: {
                    //         fontSize: '16px',
                    //         textAlign: 'start',
                    //         fontWeight: '600',
                    //         color: theme.palette.text.dark,
                    //         whiteSpace: 'normal',
                    //         overflow: 'hidden',
                    //         textOverflow: 'ellipsis',
                    //         maxWidth: '100px'
                    //     }
                    // }
                }
            }
        ]
    };

    if (!data) {
        return <div className="charts-container">Loading chart data...</div>;
    }

    return (
        <div className="charts-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Chart options={chartOptions} series={series} type="donut" width={400} height={400} sx={{ p: 1 }} />
        </div>
    );
};

DynamicPieChart.propTypes = {
    data: PropTypes?.shape({
        title: PropTypes?.string,
        series: PropTypes?.arrayOf(PropTypes?.number)?.isRequired,
        labels: PropTypes?.arrayOf(PropTypes?.string)?.isRequired
    })?.isRequired
};

export default DynamicPieChart;
