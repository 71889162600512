import {
    FETCH_SITES_REQUEST,
    FETCH_SITES_SUCCESS,
    FETCH_SITES_FAILURE,
    REGISTER_SITE_REQUEST,
    REGISTER_SITE_SUCCESS,
    REGISTER_SITE_FAILURE,
    UPDATE_SITE_REQUEST,
    UPDATE_SITE_SUCCESS,
    UPDATE_SITE_FAILURE,
    DELETE_SITE_REQUEST,
    DELETE_SITE_SUCCESS,
    DELETE_SITE_FAILURE,
    RESTORE_SITE_REQUEST,
    RESTORE_SITE_SUCCESS,
    RESTORE_SITE_FAILURE
} from './sitesActions';

const initialState = {
    sites: {
        list: [],
        pagination: {}
    },
    loading: false,
    hasMore: false,
    fetchSuccess: false,
    registerSuccess: false,
    updateSuccess: false,
    deleteSuccess: false,
    restoreSuccess: false,
    error: null
};

const sitesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SITES_REQUEST:
        case REGISTER_SITE_REQUEST:
        case UPDATE_SITE_REQUEST:
        case DELETE_SITE_REQUEST:
        case RESTORE_SITE_REQUEST:
            return { ...state, loading: true, error: null };

        case FETCH_SITES_SUCCESS:
            return {
                ...state,
                loading: false,
                sites: {
                    list: [...state.sites.list, ...action.payload.list],
                    pagination: action.payload.pagination,
                    hasMore: action.payload.pagination.currentPage < action.payload.pagination.lastPage
                },
                fetchSuccess: true
            };

        case REGISTER_SITE_SUCCESS:
            return {
                ...state,
                loading: false,
                sites: {
                    ...state.sites,
                    list: [action.payload, ...state.sites.list] // Add new site at the top
                },
                registerSuccess: true
            };

        case UPDATE_SITE_SUCCESS:
            return {
                ...state,
                loading: false,
                sites: {
                    ...state.sites,
                    list: state.sites.list.map((site) => (site.id === action.payload.id ? action.payload : site))
                },
                updateSuccess: true
            };

        case DELETE_SITE_SUCCESS:
            return {
                ...state,
                loading: false,
                sites: {
                    ...state.sites,
                    list: state.sites.list.filter((site) => site.id !== action.payload)
                },
                deleteSuccess: true
            };

        case RESTORE_SITE_SUCCESS:
            return {
                ...state,
                loading: false,
                sites: {
                    ...state.sites,
                    list: [action.payload, ...state.sites.list]
                },
                restoreSuccess: true
            };

        case FETCH_SITES_FAILURE:
        case REGISTER_SITE_FAILURE:
        case UPDATE_SITE_FAILURE:
        case DELETE_SITE_FAILURE:
        case RESTORE_SITE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                fetchSuccess: false,
                registerSuccess: false,
                updateSuccess: false,
                deleteSuccess: false,
                restoreSuccess: false
            };

        default:
            return state;
    }
};

export default sitesReducer;
