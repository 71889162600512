import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Box,
    Skeleton,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    Typography,
    AvatarGroup,
    Avatar
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSites } from 'store/sitesActions';
import MainCard from 'ui-component/cards/MainCard';
import { fetchRegions } from 'store/regionsActions';
import AddSite from '../create-site/addSite';

const SiteList = () => {
    const [filter, setFilter] = useState({
        name: '',
        region: '',
        province: '',
        manager: ''
    });
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const loaderRef = useRef(null);

    const dispatch = useDispatch();
    const { sites, loading, error } = useSelector((state) => state.sites);
    const regionsList = useSelector((state) => state.regions);
    const { regionList: regions, loading: regionsLoading } = regionsList;

    const loadSites = useCallback(async () => {
        // if (hasMore && loading) {
        //     await dispatch(
        //         fetchSites({
        //             page: 1,
        //             perPage: 10,
        //             sortDir: 'desc',
        //             sortBy: 'id',
        //             filters: filter // Pass filters
        //         })
        //     );
        // }
        await dispatch(
            fetchSites({
                page: 1,
                perPage: 15,
                sortDir: 'desc',
                sortBy: 'id',
                filters: filter // Pass filters
            })
        );
        dispatch(fetchRegions());
        console.log(regions);
    }, [dispatch, page, filter, hasMore, loading]);

    useEffect(() => {
        loadSites();
    }, [page, filter]);

    // Function to handle filter changes
    const handleFilterChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
        setPage(1); // Reset pagination when filter changes
        // setHasMore(true); // Reset hasMore on filter change
    };

    // Infinite scroll observer
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                    setPage((prevPage) => prevPage + 1);
                }
            },
            { threshold: 1.0 } // Adjusted threshold to trigger when fully visible
        );
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) observer.unobserve(loaderRef.current);
        };
    }, [hasMore, loading]);

    // Detect end of data to stop pagination
    useEffect(() => {
        if (sites?.list?.length % 15 !== 0 || sites?.list?.length === 0) {
            setHasMore(false); // Stop pagination when no more data
        }
    }, [sites]);

    // Filtering data locally (optional, if you need to further filter)
    const filteredData = sites?.list?.filter((site) =>
        Object.keys(filter).every((key) => {
            if (!filter[key]) return true;
            return site[key]?.toString().toLowerCase().includes(filter[key].toLowerCase());
        })
    );

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleOpen = () => setDrawerOpen(true);
    const handleClose = () => setDrawerOpen(false);

    return (
        <MainCard buttonLink="/gestion-des-sites/sites/create">
            <AddSite isOpen={drawerOpen} onClose={handleClose} />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Nom"
                                name="name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={handleFilterChange}
                                value={filter.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel htmlFor="region-select">Région</InputLabel>
                                <Select
                                    label="Région"
                                    name="region"
                                    value={filter.region}
                                    onChange={handleFilterChange}
                                    disabled={regionsLoading}
                                >
                                    <MenuItem value="">
                                        <em>Toutes les régions</em>
                                    </MenuItem>
                                    {regions?.regions?.map((region) => (
                                        <MenuItem key={region.id} value={region.name}>
                                            {region.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Département"
                                name="province"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={handleFilterChange}
                                value={filter.province}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Responsable"
                                name="manager"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={handleFilterChange}
                                value={filter.manager}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        sx={{
                            mb: 2,
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '150px' }}>
                            <Typography variant="subtitle2">Résultat</Typography>
                            <Typography variant="subtitle1">{sites.list.length} Site</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleOpen()}
                                sx={{
                                    borderRadius: '20px',
                                    mx: 1,
                                    padding: '6px 16px',
                                    backgroundColor: '#2962FF'
                                }}
                            >
                                Ajouter un site
                            </Button>
                        </Box>
                    </Grid>
                    <Box sx={{ height: '500px', overflowY: 'auto' }}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nom de Site</TableCell>
                                        <TableCell>Région</TableCell>
                                        <TableCell>Wilaya</TableCell>
                                        <TableCell>Désignation</TableCell>
                                        <TableCell>Abréviation</TableCell>
                                        <TableCell>Responsable</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData?.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                Aucune donnée disponible
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        filteredData?.map((site, index) => (
                                            <TableRow key={site.id}>
                                                <TableCell>{site.name}</TableCell>
                                                <TableCell>{site.region?.name}</TableCell>
                                                <TableCell>{site.province?.name}</TableCell>
                                                <TableCell>{site.designation}</TableCell>
                                                <TableCell>{site.abbreviation}</TableCell>
                                                <TableCell style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    {site?.managers && site.managers.length > 0 ? (
                                                        <AvatarGroup max={4}>
                                                            {site.managers.map((manager, index) => (
                                                                <Avatar key={index} alt={manager.name} src={manager.profilePictureUrl} />
                                                            ))}
                                                        </AvatarGroup>
                                                    ) : (
                                                        <AvatarGroup max={3}>
                                                            <Avatar>N/A</Avatar>
                                                        </AvatarGroup>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Show error if exists */}
                        {error && <div style={{ color: 'red' }}>{error}</div>}

                        {/* Loader for infinite scroll */}
                        {loading && <Skeleton variant="rectangular" width="100%" height={48} />}
                        <div ref={loaderRef} style={{ height: '20px' }} />
                    </Box>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default SiteList;
