/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const fileTypes = {
    IMAGE: 'image',
    VIDEO: 'video'
};

const MediaNavigator = ({ isLoading, currentHazard }) => {
    const sliderRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    if (isLoading) {
        return (
            <Box sx={{ width: '100%', height: '400px' }}>
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </Box>
        );
    }

    const files = currentHazard?.files || [];
    const fileCount = files.length;

    const handleVideoPlay = () => {
        setIsVideoPlaying(true);
    };

    const handleVideoEnded = () => {
        setIsVideoPlaying(false);
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const renderMedia = (file) => {
        const mediaStyle = {
            width: '100%',
            height: '100%',
            objectFit: 'contain' // Ensure media fits within the container without cropping
        };

        if (file?.mimeType?.startsWith(fileTypes.VIDEO)) {
            return (
                <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <video src={file.url} autoPlay muted controls style={mediaStyle} onPlay={handleVideoPlay} onEnded={handleVideoEnded}>
                        <track src={file.url} kind="subtitles" label="English" />
                    </video>
                </Box>
            );
        }

        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={file.url} alt="Content" style={mediaStyle} />
            </Box>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: !isVideoPlaying,
        speed: 1000,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        arrows: false // Hide arrows if you want to control slider programmatically
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 400, height: 'auto', position: 'relative' }}>
            {fileCount === 1 ? (
                renderMedia(files[0])
            ) : (
                <Slider {...settings} ref={sliderRef}>
                    {files.map((file, index) => (
                        <Box key={index} sx={{ width: '100%', height: 'auto' }}>
                            {renderMedia(file)}
                        </Box>
                    ))}
                </Slider>
            )}
        </Box>
    );
};

export default MediaNavigator;
