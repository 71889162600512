import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    Skeleton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import { fetchRolesPermissions } from 'store/roleActions';
import { ExtensionOutlined, WorkspacePremiumOutlined } from '@mui/icons-material';

const RolesPermissionsEdit = () => {
    const dispatch = useDispatch();
    const { roles, permissions, loading } = useSelector((state) => state.roles);
    const [searchQuery, setSearchQuery] = useState('');
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    useEffect(() => {
        // Fetch roles and permissions from API when the component mounts
        dispatch(fetchRolesPermissions());
    }, [dispatch]);

    const handlePermissionChange = (roleId, permissionName) => {
        // Modify permissions for a specific role
        // Handle permission change logic here
    };

    const handleSave = () => {
        setOpenConfirmModal(true); // Open confirmation modal
    };

    const handleConfirmSave = () => {
        setOpenConfirmModal(false); // Close confirmation modal
        // Simulate saving
        console.log('Updated Roles: ', roles);
        setOpenSuccessModal(true); // Show success modal
    };

    const filteredRoles = roles.filter((role) => role.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <MainCard>
            <SubCard>
                <Grid container spacing={gridSpacing} sx={{ padding: '20px' }}>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            Gérer les rôles et permissions
                        </Typography>
                    </Grid>
                    {/* Search Bar */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Rechercher un rôle..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    {loading ? (
                        <Grid item xs={12}>
                            {[1, 2, 3].map((_, index) => (
                                <Skeleton key={index} variant="rectangular" height={150} sx={{ mb: 2 }} />
                            ))}
                        </Grid>
                    ) : (
                        filteredRoles.map((role) => (
                            <Grid item xs={12} key={role.id}>
                                <SubCard>
                                    <Grid container spacing={gridSpacing} sx={{ p: 2 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center', // Center the content horizontally
                                                flexDirection: 'row' // Ensure items are in a row
                                            }}
                                        >
                                            {role.permissions.length === permissions.length ? (
                                                <WorkspacePremiumOutlined color="primary" />
                                            ) : (
                                                <ExtensionOutlined color="primary" />
                                            )}
                                            <Typography variant="h4" sx={{ marginLeft: 1 }}>
                                                {role.name}
                                            </Typography>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {permissions.map((permission) => (
                                                    <Grid item key={permission.id} xs={12} sm={6} md={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={role.permissions.some((perm) => perm.name === permission.name)}
                                                                    onChange={() => handlePermissionChange(role.id, permission.name)}
                                                                />
                                                            }
                                                            label={permission.label}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        ))
                    )}

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginTop: '20px' }}>
                            Sauvegarder les modifications
                        </Button>
                    </Grid>
                </Grid>
            </SubCard>

            {/* Confirmation Modal */}
            <Dialog open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
                <DialogTitle>Confirmer les modifications</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir sauvegarder les modifications pour les rôles et les permissions ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmModal(false)} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmSave} color="primary" variant="contained">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Modal */}
            <Dialog open={openSuccessModal} onClose={() => setOpenSuccessModal(false)}>
                <DialogTitle>Succès</DialogTitle>
                <DialogContent>
                    <DialogContentText>Les modifications ont été sauvegardées avec succès.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSuccessModal(false)} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default RolesPermissionsEdit;
