import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Chip,
    MenuItem,
    Menu,
    TextField,
    InputAdornment
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import AddRole from 'ui-component/extended/AddRoleDrawer';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchRolesPermissions } from 'store/roleActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SearchIcon from '@mui/icons-material/Search';
import { ExtensionOutlined, WorkspacePremiumOutlined } from '@mui/icons-material';

const RolesPermissions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize useNavigate
    const roles = useSelector((state) => state.roles);
    const allPermissions = roles.permissions;

    const [selectedRole, setSelectedRole] = useState(null);
    const [open, setOpen] = useState(false);
    const [roleData, setRoleData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [availablePermissions, setAvailablePermissions] = useState([]);
    const [currentPermissions, setCurrentPermissions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [permissionToDelete, setPermissionToDelete] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        dispatch(fetchRolesPermissions());
    }, [dispatch]);

    const handleClickOpen = (role) => {
        setSelectedRole(role);
        setAvailablePermissions(allPermissions.filter((perm) => !role.permissions.some((p) => p.id === perm.id)));
        setCurrentPermissions(role.permissions);
        setOpen(true);
    };

    const handleClose = () => {
        setRoleData({});
        setIsEdit(false);
        setOpen(false);
    };

    const handleRemovePermission = (permission) => {
        setPermissionToDelete(permission);
        setConfirmDeleteOpen(true);
    };

    const handleConfirmDelete = () => {
        setCurrentPermissions((prev) => prev.filter((perm) => perm.id !== permissionToDelete.id));
        setAvailablePermissions((prev) => [...prev, permissionToDelete]);
        setConfirmDeleteOpen(false);
    };

    const handleCancelDelete = () => setConfirmDeleteOpen(false);

    const handleClickActions = (event, role) => {
        setAnchorEl({ element: event.currentTarget, role });
    };

    const handleCloseActions = () => {
        setAnchorEl(null);
    };

    const handleEditRole = () => {
        const selectedRole = anchorEl?.role; // Access the selected role from anchorEl
        setRoleData(selectedRole);
        setIsEdit(true);
        setOpen(true);
        handleCloseActions();
    };

    const actionsOpen = Boolean(anchorEl?.element);

    const filteredRoles = roles?.roles?.filter((role) => role?.name?.toLowerCase().includes(searchQuery.toLowerCase()));
    return (
        <MainCard>
            <SubCard>
                <Box sx={{ p: 2 }}>
                    <Grid
                        container
                        sx={{
                            mb: 2,
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box>
                            <Typography variant="h3" sx={{ mb: 1 }}>
                                Gestion des Rôles et Permissions
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(true)}
                            sx={{ borderRadius: '20px', padding: '6px 16px', backgroundColor: '#2962FF' }}
                        >
                            Ajouter un rôle
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Rechercher un rôle..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    {filteredRoles.map((role) => (
                        <Grid item xs={12} sx={{ mb: 1 }} key={role.id}>
                            <SubCard>
                                <Box sx={{ p: 2 }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            mb: 2
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'row'
                                            }}
                                        >
                                            {role?.permissions?.length === allPermissions?.length ? (
                                                <WorkspacePremiumOutlined color="primary" />
                                            ) : (
                                                <ExtensionOutlined color="primary" />
                                            )}
                                            <Typography variant="h4" sx={{ marginLeft: 1 }}>
                                                {role.name}
                                            </Typography>
                                        </Box>

                                        {/* Three dots button */}
                                        <IconButton
                                            aria-controls={actionsOpen ? 'menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={actionsOpen ? 'true' : undefined}
                                            onClick={(event) => handleClickActions(event, role)} // Pass the event and role here
                                        >
                                            <MoreVertIcon />
                                        </IconButton>

                                        <Menu
                                            id="menu"
                                            anchorEl={anchorEl?.element}
                                            open={actionsOpen}
                                            onClose={handleCloseActions}
                                            PaperProps={{
                                                sx: {
                                                    boxShadow: '0px 0.1px 0.3px rgba(0, 0, 0, 0.1)'
                                                }
                                            }}
                                        >
                                            <MenuItem onClick={handleEditRole}>
                                                <EditIcon fontSize="small" sx={{ mr: 1 }} />
                                                Modifier
                                            </MenuItem>
                                            <MenuItem onClick={handleCloseActions}>
                                                <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                                                Supprimer
                                            </MenuItem>
                                        </Menu>
                                    </Grid>

                                    {/* Permissions Tags */}
                                    <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
                                        {role?.permissions?.length > 0 ? (
                                            role?.permissions?.map((permission) => (
                                                <Chip
                                                    key={permission.id}
                                                    label={permission.label}
                                                    sx={{ margin: 0.5, fontWeight: '500' }}
                                                />
                                            ))
                                        ) : (
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                Aucun permission associée.
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </SubCard>
                        </Grid>
                    ))}

                    {/* Confirmation modal for delete permissions */}
                    <Dialog open={confirmDeleteOpen} onClose={handleCancelDelete}>
                        <DialogTitle variant="h4">Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography variant="h5">
                                Êtes-vous sûr de vouloir supprimer la permission {permissionToDelete?.name} ?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelDelete}>Annuler</Button>
                            <Button onClick={handleConfirmDelete} color="error">
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </SubCard>

            {/* AddRole Drawer */}
            <AddRole isOpen={open} onClose={handleClose} roleData={roleData} isEdit={isEdit} />
        </MainCard>
    );
};

export default RolesPermissions;
