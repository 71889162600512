/* eslint-disable consistent-return */
import * as React from 'react';
import _ from 'lodash';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Button,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    Grid,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as CloturedIcon } from 'assets/images/icons/cloture.svg';
import { ReactComponent as NotCloturedIcon } from 'assets/images/icons/notCloture.svg';
import { ReactComponent as CloturedNIcon } from 'assets/images/icons/clotureN.svg';
import { ReactComponent as OpenIcon } from 'assets/images/icons/open.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/images/icons/priority.svg';
import { ReactComponent as InProgressIcon } from 'assets/images/icons/cours.svg';
import { ReactComponent as AnonymeIcon } from 'assets/images/icons/anonyme.svg';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from 'store/hazardsActions';
import { useNavigate } from 'react-router';
import ACPListSkeleton from 'ui-component/cards/Skeleton/ACPList';
import { makeStyles } from '@mui/styles';
import { fetchSites } from 'store/sitesActions';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import DateRangePicker from 'ui-component/extended/DateRangePicker';
import SearchIcon from '@mui/icons-material/Search';
import { fetchActions } from 'store/acpActions';
import InfiniteScroll from 'react-infinite-scroll-component';

function Row(props) {
    const { row } = props;
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [isAdding, setIsAdding] = React.useState(false);
    const [newAction, setNewAction] = React.useState({
        id: '',
        acpReference: '',
        type: '',
        status: ''
    });
    const navigate = useNavigate();

    const handleAddNewAction = () => {
        navigate(`/gestion-des-acp/create`, { state: { relatedHazard: row } });
    };

    const handleCancelAdding = () => {
        setIsAdding(false);
    };

    const generateRandomReference = () => {
        const randomNum = Math.floor(Math.random() * 10000000000);
        setNewAction({ ...newAction, acpReference: `ACP-${randomNum}` });
    };

    const handleSaveAction = () => {
        // Dispatch action to save new action
        // dispatch(addAction(row?.id, { ...newAction, id: row?.actions.length + 1 }));
        setIsAdding(false);
        setNewAction({ id: '', acpReference: '', type: '', status: '' });
    };

    const handleActionClick = (action) => {
        navigate(`/gestion-des-acp/details/${action.id}`, { state: { acpData: action } });
    };

    const statusOptions = [
        { value: 'validated', label: 'Validé' },
        { value: 'in-progress', label: 'En Cours' },
        { value: 'resolved', label: 'Résolu' },
        { value: 'canceled', label: 'Annulé' }
    ];

    const statusActionsOptions = [
        { value: 'open', label: 'Ouvert' },
        { value: 'in-progress', label: 'En Cours' },
        { value: 'completed', label: 'Complété' },
        { value: 'overdue', label: 'En retard' }
    ];

    const getLabel = (value, options) => {
        const item = options.find((option) => option.value === value);
        return item ? item.label : 'Inconnu';
    };

    const getStatusColor = (status) => {
        const colorMapping = {
            validated: theme.palette.info.main,
            'in-progress': theme.palette.warning.dark,
            resolved: theme.palette.success.main,
            canceled: theme.palette.error.dark
        };
        return colorMapping[status] || theme.palette.text.primary;
    };

    const getStatusActionsColor = (status) => {
        const colorMapping = {
            open: theme.palette.info.main,
            'in-progress': theme.palette.warning.dark,
            completed: theme.palette.success.dark,
            overdue: theme.palette.error.dark
        };
        return colorMapping[status] || theme.palette.text.primary;
    };

    const renderActionStatusIcon = (status) => {
        switch (status) {
            case 'completed':
                return <CloturedNIcon />;
            case 'closed':
                return <CloturedIcon />;
            case 'open':
                return <OpenIcon />;
            case 'in-progress':
                return <InProgressIcon />;
            default:
                return null;
        }
    };

    return (
        <>
            <TableRow hover onClick={() => setOpen(!open)}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: '500', color: '#202224', lineHeight: '11px', whiteSpace: 'nowrap' }}>
                    {row?.reference}
                </TableCell>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={row.category.iconUrl} alt={row.category.name.fr} style={{ marginRight: '8px', width: '23px' }} />
                        <Typography sx={{ textAlign: 'left', color: '#202224', lineHeight: '11px', whiteSpace: 'nowrap' }}>
                            {row.category.name.fr}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'left', fontWeight: '500', color: '#202224' }}>{row?.type?.name?.fr || ''}</TableCell>
                <TableCell sx={{ textAlign: 'left', fontWeight: '500', color: '#202224' }}>{row?.site?.name || ''}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: '500', color: '#202224', whiteSpace: 'nowrap', lineHeight: '11px' }}>
                    {!row.isAnonymous ? (
                        `${row.user.firstName} ${row.user.lastName}`
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AnonymeIcon style={{ marginRight: '8px' }} />
                            <Typography>Inconnu</Typography>
                        </Box>
                    )}
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: '500', color: '#202224', lineHeight: '11px' }}>
                    {row?.createdAt || ''}
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: '500', color: '#202224', lineHeight: '11px' }}>
                    {`${row?.actions?.filter((action) => action.status === 'in-progress').length}/${row?.actions?.length}`}
                </TableCell>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: getStatusColor(row?.status) }}>
                        <ArrowUpIcon style={{ marginRight: '8px' }} />
                        {getLabel(row?.status, statusOptions)}
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={9} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2, padding: 2, backgroundColor: '#f5f5f5', borderRadius: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Actions
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Référence d’ACP</TableCell>
                                        <TableCell>Type d’ACP</TableCell>
                                        <TableCell>Statut</TableCell>
                                        <TableCell>Due</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                sx={{ borderRadius: '50px' }}
                                                size="small"
                                                onClick={handleAddNewAction}
                                            >
                                                Ajouter ACP
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.actions?.map((action) => (
                                        <TableRow key={action?.id} onClick={() => handleActionClick(action)}>
                                            <TableCell>{action?.reference}</TableCell>
                                            <TableCell sx={{ fontWeight: '500', textTransform: 'capitalize' }}>{action?.type}</TableCell>
                                            <TableCell sx={{ color: getStatusActionsColor(action?.status), fontWeight: '500' }}>
                                                {renderActionStatusIcon(action?.status)} {getLabel(action?.status, statusActionsOptions)}
                                            </TableCell>
                                            <TableCell>{action?.dueDate}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    ))}
                                    {isAdding && (
                                        <TableRow>
                                            <TableCell>{row?.actions?.length + 1}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={newAction?.acpReference}
                                                    onChange={(e) => setNewAction({ ...newAction, acpReference: e.target.value })}
                                                    label="Référence d’ACP"
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={generateRandomReference}>
                                                                    <AutorenewIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={newAction?.type}
                                                    onChange={(e) => setNewAction({ ...newAction, type: e.target.value })}
                                                    label="Type d’ACP"
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <MenuItem value="corrective">Correctif</MenuItem>
                                                    <MenuItem value="preventive">Préventif</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={newAction.status}
                                                    onChange={(e) => setNewAction({ ...newAction, status: e.target.value })}
                                                    label="Statut"
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <MenuItem value="open">Ouvert</MenuItem>
                                                    <MenuItem value="in-progress">En cours</MenuItem>
                                                    <MenuItem value="completed">Complet</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button variant="contained" size="small" onClick={handleSaveAction}>
                                                    Sauvegarder
                                                </Button>
                                                <IconButton size="small" sx={{ color: 'red' }} onClick={handleCancelAdding}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function ACPList() {
    const dispatch = useDispatch();
    const { actions, loading, error, hasMore } = useSelector((state) => state.acp);
    const { sites } = useSelector((state) => state.sites);
    const [page, setPage] = React.useState(1);
    const [filter, setFilter] = React.useState({
        search: '',
        type: [],
        site: [],
        status: []
    });

    React.useEffect(() => {
        // console.clear();
        const fetchInitialData = async () => {
            const formattedFilter = {
                search: filter.search,
                site: filter.site.join(', '), // Convert site array to string
                status: filter.status.join(', '),
                type: filter.type.join(', ')
            };
            try {
                dispatch(
                    fetchActions(page, 15, formattedFilter.search, formattedFilter.site, formattedFilter.status, formattedFilter.type)
                );
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchInitialData();
    }, [filter, dispatch]);

    const fetchMoreData = () => {
        const formattedFilter = {
            search: filter.search,
            site: filter.site.join(','),
            status: filter.status.join(','),
            type: filter.type.join(',')
        };
        dispatch(fetchActions(page + 1, 15, formattedFilter.search, formattedFilter.site, formattedFilter.status, formattedFilter.type));
        setPage((prevPage) => prevPage + 1); // Move this after fetching more data
    };

    const fetchInitialSites = () => {
        if (!sites.list.length) {
            dispatch(fetchSites(1, 15));
        }
    };

    const fetchMoreSites = () => {
        if (!sites.hasMore) return;
        dispatch(fetchSites(sites.pagination.currentPage + 1, 15)).then((newSites) => {});
    };

    const severities = [
        { value: 'minor', label: 'Mineur', color: '#1565C0' },
        { value: 'low', label: 'Faible', color: '#1976D2' },
        { value: 'significant', label: 'Significatif', color: '#FBC02D' },
        { value: 'catastrophic', label: 'Catastrophique', color: '#D32F2F' }
    ];

    const statusOptions = [
        { value: 'open', label: 'Ouvert' },
        { value: 'in-progress', label: 'En Cours' },
        { value: 'completed', label: 'Complété' },
        { value: 'overdue', label: 'En retard' }
    ];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setPage(1);
        setFilter((prev) => ({
            ...prev,
            [name]: value.includes('Tous') ? [] : value
        }));
    };

    const clearFilters = () => {
        setPage(1);
        setFilter({
            search: '',
            type: [],
            site: [],
            status: []
        });
    };

    const useStyles = makeStyles((theme) => ({
        textField: {
            maxWidth: '250px',
            borderBottom: 'none',
            '& .MuiInputBase-input': {
                margin: 0,
                backgroundColor: theme.palette.background.paper,
                width: '100%',
                borderRadius: `${theme.customization?.borderRadius || 4}px`,
                color: theme.palette.text.dark,
                paddingLeft: '10px',
                fontWeight: '600'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: theme.palette.grey[700],
                opacity: 1
            },
            '& .MuiOutlinedInput-input': {
                borderBottom: 'none'
            }
        },
        submitButton: {
            marginTop: theme.spacing(2),
            backgroundColor: '#1F41BB',
            height: '50px'
        }
    }));

    const classes = useStyles();

    if (error) return <p>Error: {error}</p>;

    return (
        <MainCard title="Liste des ACPs">
            <Grid container spacing={2} sx={{ mb: 3, alignItems: 'center', display: 'flex', justifyContent: 'flex-start', mx: 0, px: 1 }}>
                <Grid item lg={2.5} md={2.5} xs={2.5}>
                    <TextField
                        name="search"
                        variant="outlined"
                        fullWidth
                        placeholder="Rechercher ..."
                        onChange={handleFilterChange}
                        value={filter.search}
                        className={classes.textField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item lg={1.6} md={1.6} xs={4}>
                    <InfiniteScroll
                        dataLength={sites?.list?.length || 0} // Length of current list
                        next={fetchMoreSites} // Function to fetch more sites
                        hasMore={sites?.hasMore || false} // Boolean to check if there are more sites
                        loader={<MenuItem disabled>Chargement...</MenuItem>} // Loader while fetching more
                        scrollableTarget="site-dropdown" // Set the scrollable target to the dropdown
                    >
                        <FormControl fullWidth>
                            <InputLabel className={classes.label}>Site</InputLabel>
                            <Select
                                multiple
                                name="site"
                                variant="outlined"
                                value={filter.site} // Bind to the filter state
                                onChange={handleFilterChange} // Handle multiple selection
                                className={classes.select}
                                onOpen={() => fetchInitialSites()} // Fetch initial sites when dropdown opens
                                renderValue={(selected) =>
                                    selected
                                        .map((siteId) => {
                                            const site = sites?.list?.find((site) => site.id === siteId);
                                            return site ? `${site.name}` : '';
                                        })
                                        .join(', ')
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: { maxHeight: 300, overflow: 'auto' } // Control max height of dropdown
                                    }
                                }}
                            >
                                {/* Scrollable target for infinite scroll */}
                                {sites?.list?.map((site) => (
                                    <MenuItem key={site.id} id="site-dropdown" value={site.id}>
                                        <Checkbox checked={filter.site.indexOf(site.id) > -1} />
                                        <ListItemText primary={`${site?.name}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </InfiniteScroll>
                </Grid>
                <Grid item lg={2.5} md={2.5} xs={2.5}>
                    <FormControl fullWidth>
                        <InputLabel className={classes.label}>Type</InputLabel>
                        <Select
                            multiple
                            name="type"
                            variant="outlined"
                            value={filter.type}
                            onChange={handleFilterChange}
                            renderValue={(selected) => selected.map((val) => (val === 'corrective' ? 'Correctif' : 'Preventif')).join(', ')}
                        >
                            <MenuItem value="corrective">
                                <Checkbox checked={filter.type.indexOf('corrective') > -1} />
                                <ListItemText primary="Corrective" />
                            </MenuItem>
                            <MenuItem value="preventive">
                                <Checkbox checked={filter.type.indexOf('preventive') > -1} />
                                <ListItemText primary="Preventive" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item lg={2.5} md={2.5} xs={2.5}>
                    <FormControl fullWidth>
                        <InputLabel className={classes.label}>Statut</InputLabel>
                        <Select
                            multiple
                            name="status"
                            variant="outlined"
                            value={filter.status}
                            onChange={handleFilterChange}
                            renderValue={(selected) => {
                                if (selected.length === 0) return 'Tous';
                                const uniqueValues = [...new Set(selected)];

                                const isOpenSelected = uniqueValues.includes('open');
                                const isInProgressSelected = uniqueValues.includes('in-progress');
                                const isCompletedSelected = uniqueValues.includes('completed');
                                const isOverdueSelected = uniqueValues.includes('overdue');
                                const isTousSelected = uniqueValues.includes('');

                                if (isOpenSelected && !isInProgressSelected && !isCompletedSelected && !isOverdueSelected) {
                                    return 'Ouvert';
                                }
                                if (isInProgressSelected && !isOpenSelected && !isCompletedSelected && !isOverdueSelected) {
                                    return 'En cours';
                                }
                                if (isCompletedSelected && !isOpenSelected && !isInProgressSelected && !isOverdueSelected) {
                                    return 'Complété';
                                }
                                if (isOverdueSelected && !isOpenSelected && !isInProgressSelected && !isCompletedSelected) {
                                    return 'En retard';
                                }
                                return 'Tous';
                            }}
                        >
                            <MenuItem value="">
                                <Checkbox checked={filter.status.indexOf('') > -1} />
                                <ListItemText primary="Tous" />
                            </MenuItem>
                            <MenuItem value="open">
                                <Checkbox checked={filter.status.indexOf('open') > -1} />
                                <ListItemText primary="Ouvert" />
                            </MenuItem>
                            <MenuItem value="in-progress">
                                <Checkbox checked={filter.status.indexOf('in-progress') > -1} />
                                <ListItemText primary="En cours" />
                            </MenuItem>
                            <MenuItem value="completed">
                                <Checkbox checked={filter.status.indexOf('completed') > -1} />
                                <ListItemText primary="Complété" />
                            </MenuItem>
                            <MenuItem value="overdue">
                                <Checkbox checked={filter.status.indexOf('overdue') > -1} />
                                <ListItemText primary="En retard" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item lg={1.5} md={1.5} xs={2.5}>
                    <TrashIcon onClick={clearFilters} style={{ cursor: 'pointer' }} />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '150px' }}>
                <Typography variant="subtitle2">Résultat</Typography>
                <Typography variant="subtitle1">{actions?.pagination?.total} Action</Typography>
            </Box>

            <Box sx={{ position: 'relative', overflow: 'auto' }}>
                <InfiniteScroll
                    dataLength={actions?.list?.length || 0}
                    next={fetchMoreData}
                    hasMore={hasMore} // Ensure this reflects actual availability of more data
                    loader={<ACPListSkeleton />} // Show loader when fetching more
                    endMessage={
                        <Typography variant="body2" align="center" color="textSecondary">
                            Vous avez vu toutes les actions.
                        </Typography>
                    }
                >
                    <TableContainer component={Paper} sx={{ top: 0, left: 0, right: 0, bottom: 50 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }} />
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        Référence
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        Catégorie
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        Type
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        Site
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        Rapporteur
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        Date
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        ACP en cours
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap', fontWeight: '900', color: '#202224', lineHeight: '11px' }}>
                                        Statut
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {actions?.list?.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            Aucune donnée disponible
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    actions?.list?.map((row) => <Row key={row?.id} row={row} />)
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>
            </Box>
        </MainCard>
    );
}
