import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

// styles for the card
const CardWrapper = styled(MainCard)(({ theme, cardType }) => ({
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: cardType === 'blue' ? theme.palette.primary.dark : theme.palette.background.paper,
    color: cardType === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${
            cardType === 'light' ? theme.palette.warning.dark : theme.palette.primary[200]
        } -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${
            cardType === 'light' ? theme.palette.warning.dark : theme.palette.primary[200]
        } -14.02%, rgba(144, 202, 249, 0) ${cardType === 'light' ? '70.50%' : '77.58%'})`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// Dynamic hazard card component
const TotalHazardsCard = ({ isLoading, count, name, type }) => {
    const theme = useTheme();
    const isLightType = type === 'light';

    const cardType = type.toLowerCase(); // blue or light
    const isBlue = cardType === 'blue';

    const avatarBgColor = isBlue ? theme.palette.warning[800] : theme.palette.primary[800];
    const primaryTextColor = isBlue ? theme.palette.primary.light : theme.palette.grey[800];
    const secondaryTextColor = isBlue ? theme.palette.primary.light : theme.palette.grey[600];

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false} cardType={cardType}>
                    <Box sx={{ p: 2 }}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: avatarBgColor,
                                            color: '#fff'
                                        }}
                                    >
                                        <TableChartOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={
                                        <Typography variant="h4" sx={{ color: primaryTextColor }}>
                                            {count}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: secondaryTextColor,
                                                fontWeight: '500',
                                                mt: 0.25
                                            }}
                                        >
                                            {name}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalHazardsCard.propTypes = {
    isLoading: PropTypes.bool,
    count: PropTypes.number.isRequired, // number of hazards
    name: PropTypes.string.isRequired, // card title
    type: PropTypes.oneOf(['blue', 'light']).isRequired // card styling type
};

export default TotalHazardsCard;
